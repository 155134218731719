import LayoutArrayLargeLeft from "../Layout/LayoutArrayLargeLeft";

const SectionArray = (props) => {
  return (
    <LayoutArrayLargeLeft
      header={props.section}
      showMore={`/news/showmore/${props.section}`}
      links={props.articles}
      {...props}
    />
  );
};

export default SectionArray;