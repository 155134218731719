import LayoutArrayLargeCenter from "../Layout/LayoutArrayLargeCenter";

const SectionArrayLargeCenter = (props) => {
  return (
    <LayoutArrayLargeCenter
      {...props}
      header={props.caption || props.section}
      showMore={`/news/showmore/${props.section}`}
      links={props.articles}
    />
  );
};

export default SectionArrayLargeCenter;