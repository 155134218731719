import React from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import ManageSubscriptionButton from "./ManageSubscriptionButton";

const SubscriptionModal = (props) => (
  <Modal show={props.show} onHide={props.onHide}>
    <Modal.Header closeButton={!props?.subscription?.id}>
      <Modal.Title>Our Fight</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {!props?.subscription?.id && (
        <div className="mb-3 fs-5">By subscribing:</div>
      )}
      {!!props?.subscription?.id && (
        <div className="mb-3 fs-5">By continuing your subscription:</div>
      )}
      <ListGroup>
        <ListGroup.Item>
          You understand <b>Our Fight will charge your account $1.50</b> for
          each email determined to be acquired by Our Fight and new to the
          entire Our Fight list.
        </ListGroup.Item>
        <ListGroup.Item>
          You understand you can set a daily budget. This is the maximum amount
          you want to be charged daily.
        </ListGroup.Item>
        <ListGroup.Item>
          You understand you will be billed weekly.
        </ListGroup.Item>
        <ListGroup.Item>
          You understand you can cancel your subscription at any time. To do so,
          click <ManageSubscriptionButton link={true} />.
        </ListGroup.Item>
        <ListGroup.Item>
          You understand you are responsible for the payment of any charges
          prior to you canceling your subscription.
        </ListGroup.Item>
        <ListGroup.Item>
          You understand all data (including emails) acquired are owned by Our
          Fight and that you will have access to emails used to sign your
          petitions.
        </ListGroup.Item>
        <ListGroup.Item>
          You agree to and accept Our Fight's&nbsp;
          <a href="/userAgreement" target="_blank">
            User Agreement
          </a>
          &nbsp; and &nbsp;
          <a href="/privacyPolicy" target="_blank">
            Privacy Policy
          </a>
          .
        </ListGroup.Item>
      </ListGroup>
    </Modal.Body>
    <Modal.Footer>
      {!props?.subscription?.id && (
        <Button variant="danger" onClick={props.onHide}>
          <XCircle /> Cancel
        </Button>
      )}

      {!!props?.subscription?.id && <ManageSubscriptionButton />}

      {!props?.subscription?.id && (
        <Button variant="success" onClick={props.onAgree}>
          <Check2Circle /> I understand & agree
        </Button>
      )}

      {!!props?.subscription?.id && (
        <Button variant="success" onClick={props.onHide}>
          <Check2Circle /> I understand & agree
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

export default SubscriptionModal;