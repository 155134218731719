import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Badge } from "react-bootstrap";
import LayoutItem from "./News/Layout/LayoutItem";

const PetitionList = (props) => {
  const ref = useRef(null);

  const badgeColor = (signatures) => {
    signatures = parseInt(signatures, 10);

    if (signatures >= 250) return "success";
    else if (signatures >= 100) return "info";
    else return "primary";
  };

  useEffect(() => {
    if (!!props?.selectedForm) {
      for (const child of ref.current.children) {
        if (child.getAttribute("data-id") === props.selectedForm.id)
          child.scrollIntoView(true);
      }
    }
  }, [props?.selectedForm]);

  return (
    <div
      className="news"
      style={{
        display: "inline-block",
        maxHeight: "calc(100vh - 200px)",
        overflow: "auto",
        width: "100%",
      }}
    >
      <div
        ref={ref}
        style={{
          display: "inline-block",
          width: "100%",
        }}
      >
        {Object.values(props.forms).map((form, index) => (
          <LayoutItem
            highlight={form.id === props?.selectedForm?.id}
            article={{
              id: form.id,
              domain: "https://ourfight.online",
              url: `https://ourfight.online/${form.id}${
                !!props.fbCampaignId ? `?utm_source=${props.fbCampaignId}` : ""
              }`,
              imageURL: "https://static.ourfight.online/upload/" + form.image,
              badge: form.signatures >= 25 && (
                <Badge pill bg={badgeColor(form.signatures)}>
                  {form.signatures} Signed!
                </Badge>
              ),
              ...form,
            }}
            className="cardSmall"
          />
        ))}
      </div>
    </div>
  );
};

export default PetitionList;