import React from "react";
import { Image } from "react-bootstrap";

function UserAgreement(props) {
  return (
    <div>
      <div className="text-center">
        <Image className="image-fluid w-50 my-3" src="ourfight.png" />
      </div>
      <p className="text-center">
        <b>Our Fight - User Agreement</b>
      </p>

      <ol>
        <li>
          <b>Introduction.</b>
        </li>{" "}
        <p>
          This agreement applies to the website http://www.ourfight.com (the
          “Website”) which is owned and controlled by Our Fight Inc (“Our
          Fight”, “we”, “our”, “us”). By registering for, accessing, and using
          the Website, you understand and agree to accept and adhere to the
          following terms and conditions as stated in this policy, hereafter
          referred to as (the “User Agreement”), along with the terms and
          conditions as stated in our Privacy Policy (please refer to the
          Privacy Policy section below for more information).{" "}
        </p>
        <p>This User Agreement is in effect as of: August 3, 2020. </p>
        <p>
          We reserve the right to change this User Agreement from time to time
          without notice. You acknowledge and agree that it is your
          responsibility to review this User Agreement periodically to
          familiarize yourself with any modifications. Your continued use of
          this site after such modifications will constitute acknowledgment and
          agreement of the modified terms and conditions.{" "}
        </p>
        <p>
          You may not use the Website or create an account if you are a person
          barred under the laws of the United States or other countries
          including the country in which you are resident or from which you use
          the Website. You affirm that you are over the age of 16, as the
          Website is not intended for children under 16. If it is discovered
          that an individual under the age of 16 has created an account, the
          account will be terminated.{" "}
        </p>
        <p>
          If these terms are accepted by a person that represents a company or
          organization, when you accept the terms, the terms are accepted by all
          members of the organization, employees of the company and/or its
          affiliates.{" "}
        </p>
        <p>
          Contact: You can contact us regarding anything related to this User
          Agreement by using the information in the “How to Contact Us” section,
          below.{" "}
        </p>
        <p>
          <li>
            <b>Website.</b>
          </li>{" "}
          Our fight provides a platform for users to gather support for their
          cause and instate change via online petitions. You may use the Website
          for your personal and business purposes. You may connect to the
          Website using any Internet browser supported by the Website. You are
          responsible for obtaining access to the Internet and the equipment
          necessary to use the Website.{" "}
        </p>
        <p>
          You must sign up for a user account by providing all required
          information in order to access or use the Website. You agree to: a)
          provide true, accurate, current and complete information about
          yourself as prompted by the sign-up process; and b) maintain and
          promptly update the information provided during sign up to keep it
          true, current, and complete. If you provide any information that is
          untrue, outdated, or incomplete, or if Our Fight has reasonable
          grounds to suspect that such information is untrue, outdated, or
          incomplete, Our Fight may terminate your user account and refuse
          current or future use of the Website.{" "}
        </p>
        <p>
          <li>
            <b>Privacy.</b>
          </li>{" "}
          Your privacy is important to us, which is why we've created a separate
          Privacy Policy in order to explain in detail how we collect, manage,
          process, secure, and store your personal information. Our privacy
          policy is included under the scope of this User Agreement. To read our
          privacy policy in its entirety, click here.{" "}
        </p>
        <p>
          Our Fight may contact you with important information, such as service
          announcements, administrative messages, and newsletters. You
          understand that these communications shall be considered part of using
          the Website. As part of our policy to respect your privacy, we also
          provide you the option of opting out from receiving newsletters from
          us.{" "}
        </p>
        <p>
          <li>
            <b>Responsible Use and Conduct.</b>
          </li>{" "}
          By accessing and using the Website, you agree to use the Website only
          for the purposes intended as permitted by (a) the terms of this User
          Agreement, and (b) applicable laws, regulations, and generally
          accepted online practices or guidelines.
        </p>
        <p>Wherein, you understand that:</p>
        <p>
          a. You must provide accurate and complete registration information any
          time you register to use the Website. You are responsible for the
          security of your passwords and for any use of your account.{" "}
        </p>
        <p>
          b. You not to engage in any activity that interferes with or disrupts
          the Website (or the servers and networks which are connected to the
          Website).{" "}
        </p>
        <p>
          While Our Fight aims to provide an open platform for people of all
          views to fight for the causes they are passionate about, we do have
          some rules in order to keep the community safe. These are posted
          herein below.{" "}
        </p>
        <p>
          With that said, Our Fight takes no responsibility for the content or
          actions of the users using the Website. Our Fight is not responsible
          for monitoring the online petitions posted on the Website for
          accuracy, legality, safety, or anything else. Further, Our Fight does
          not endorse any campaign, idea, opinion, or political candidate posted
          by its users. Petitions are not reviewed for compliance with laws and
          regulations, both foreign and domestic, and users agree to access and
          rely on the content of the Website AT THEIR OWN RISK.{" "}
        </p>
        <p>
          If you find any petition or other user-generated content that you
          believe violates this User Agreement, please contact us by using the
          information in the “How to Contact Us” section, below.{" "}
        </p>
        <p>
          Prohibited Content: The content posted by users on the Website shall
          not contain any of the following: (a) content that infringes a third
          party's rights (e.g., copyright) according to applicable law; (b)
          pornographic, obscene or excessively profane content; (c) hate-related
          or violent content; (d) content advocating racial or ethnic
          intolerance; (e) content intended to advocate or advance computer
          hacking or cracking; (f) gambling; (g) other illegal activity,
          including without limitation illegal export of controlled substances
          or illegal software; (h) drug paraphernalia; (i) phishing; (j)
          malicious content; (k) other material, products or services that
          violate or encourage conduct that would violate any criminal laws, any
          other applicable laws, or any third-party rights.{" "}
        </p>
        <p>
          Prohibited Actions: In addition to (and/or as some examples of) the
          violations described in this User Agreement, you may not and may not
          allow any third party to:{" "}
        </p>
        <p>
          a. Generate or facilitate unsolicited commercial email (“spam”). Such
          activity includes, but is not limited to: (a) sending email in
          violation of the CAN-SPAM Act or any other applicable anti-spam law;
          (b) imitating or impersonating another person or his, her or its email
          address, or creating false accounts for the purpose of sending spam;
          (c) data mining any web property to find email addresses or other user
          account information; (d) sending unauthorized mail via open,
          third-party servers; (e) sending emails to users who have requested to
          be removed from a mailing list; (f) selling, exchanging or
          distributing to a third party the email addresses of any person
          without such person's knowing and continued consent to such
          disclosure; and (g) sending unsolicited emails to significant numbers
          of email addresses belonging to individuals and/or entities with whom
          you have no preexisting relationship.{" "}
        </p>
        <p>
          b. Send, upload, distribute or disseminate or offer to do the same
          with respect to any unlawful, defamatory, harassing, abusive,
          fraudulent, infringing, obscene, or otherwise objectionable content{" "}
        </p>
        <p>
          c. Intentionally distribute viruses, worms, defects, Trojan horses,
          corrupted files, hoaxes, or any other items of a destructive or
          deceptive nature;{" "}
        </p>
        <p>d. Conduct or forward pyramid schemes and the like; </p>
        <p>e. Transmit content that may be harmful to minors; </p>
        <p>
          f. Impersonate another person (via the use of an email address or
          otherwise) or otherwise misrepresent yourself or the source of any
          email;{" "}
        </p>
        <p>
          g. Illegally transmit another's intellectual property or other
          proprietary information without such owner's or licensor's permission;{" "}
        </p>
        <p>
          h. Use the Website to violate the legal rights (such as rights of
          privacy and publicity) of others;{" "}
        </p>
        <p>i. Promote or encourage illegal activity; </p>
        <p>j. Interfere with other users' enjoyment of the Website; </p>
        <p>
          k. Sell, trade, resell or otherwise exploit the Website for any
          unauthorized commercial purpose;{" "}
        </p>
        <p>
          l. Modify, adapt, translate, or reverse engineer any portion of the
          Website;{" "}
        </p>
        <p>
          m. Remove any copyright, trademark or other proprietary rights notices
          contained in or on the Website;{" "}
        </p>
        <p>n. Reformat or frame any portion of the Website; </p>
        <p>
          o. Use the Website in connection with illegal peer-to-peer file
          sharing;{" "}
        </p>
        <p>
          p. Display any content on the Website that contains any pornographic,
          hate-related or violent content or contain any other material,
          products or services that violate or encourage conduct that would
          violate any criminal laws, any other applicable laws, or any
          third-party rights; or{" "}
        </p>
        <p>q. Modify any logos or brand features without permission. </p>
        <p>
          r. Use the Website, or any interfaces provided with the Website, to
          access any product or service in a manner that violates this User
          Agreement or other terms and conditions for use of such product or
          service.{" "}
        </p>
        <p>
          You are solely responsible for any consequences, losses, or damages
          that we may directly or indirectly incur or suffer due to any
          unauthorized activities conducted by you, as explained above, and may
          incur criminal or civil liability.
          <br />
          We have the right at our sole discretion to remove any content that,
          we feel in our judgment does not comply with this User Agreement,
          along with any content that we feel is otherwise offensive, harmful,
          objectionable, inaccurate, or violates any third-party copyrights or
          trademarks. We are not responsible for any delay or failure in
          removing such content. If you post content that we choose to remove,
          you hereby consent to such removal, and consent to waive any claim
          against us.{" "}
          <p>
            <li>
              <b>Data Ownership.</b>
            </li>
            You acknowledge and agree that Our Fight owns all legal right, title
            and interest in and to the Website, including any intellectual
            property rights which subsist in the Website (whether those rights
            happen to be registered or not, and wherever in the world those
            rights may exist). Nothing in this User Agreement gives you a right
            to use any of Our Fight's trade names, trademarks, service marks,
            logos, domain names, and other distinctive brand features. All
            content and materials available on the Website, including but not
            limited to text, graphics, website name, code, images and logos are
            the intellectual property of Our Fight, and are protected by
            applicable copyright and trademark law. Any inappropriate use,
            including but not limited to the reproduction, distribution, display
            or transmission of any content on this site is strictly prohibited.{" "}
          </p>
          <p>
            Our Fight acknowledges and agrees that it obtains no right, title or
            interest from to any content that you create, submit, post, transmit
            or display on, or through, the Website, including any intellectual
            property rights which subsist in that content (whether those rights
            happen to be registered or not, and wherever in the world those
            rights may exist). You agree that you are responsible for protecting
            and enforcing those rights and that Our Fight has no obligation to
            do so on your behalf. However, all content submitted or posted on
            the Website, including your name and likeness, is publicly available
            and may be seen by other users. Further, by submitting and posting
            content on the Website, you agree to grant Our Fight a
            non-exclusive, royalty-free license to use, copy, reproduce,
            process, adapt, modify, publish, transmit, display, and distribute
            your content in any and all forms. This license shall be world-wide
            and include the right to sublicense. You represent and warrant that
            you have all the rights and authority to authorize us to use any
            content that you submit. You also agree to all such uses of your
            content with no compensation paid to you.{" "}
          </p>
          <p>
            Our fight makes no representations or promises regarding the storage
            of user posted content. Content and data may be erased at any time.
            You are responsible for keeping backups of anything posted on the
            Website.{" "}
          </p>
          <p>
            <li>
              <b>Termination and Inactive User Accounts Policy.</b>
            </li>{" "}
            We may suspend your user account or temporarily disable access to
            the Website in the event of any suspected illegal activity,
            continued period of inactivity for 365 days, and requests by law
            enforcement or other government agencies. Objections to suspension
            or disabling of user accounts should be made to
            emailaddress@OurFight.com within thirty days of being notified about
            the suspension. We may terminate a suspended or disabled user
            account after thirty days. We will also terminate your user account
            on your request. In addition, we reserve the right to terminate your
            user account and deny access to the website upon reasonable belief
            that you have violated the User Agreement. Termination of user
            account will include denial of access to the Website, deletion of
            information in your user account such as your email address and
            password and deletion of all data in your user account.{" "}
          </p>
          <p>
            <li>
              <b>Disclaimer of Warranties.</b>
            </li>{" "}
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE WEBSITE IS AT
            YOUR SOLE RISK. THE WEBSITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE
            BASIS. OUR FIGHT EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE
            OF PERFORMANCE OR COURSE OF DEALING. OUR FIGHT MAKES NO WARRANTY
            THAT THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR FREE,
            OR THAT THE WEBSITE ARE OR WILL BE MAINTAINED FREE OF VIRUSES OR
            OTHER HARMFUL CODE. IT IS UP TO YOU TO TAKE ANY AND ALL PRECAUTIONS
            TO ENSURE THAT THE INFORMATION YOU ACCESS AND USE FROM THE SITE IS
            FREE OF SUCH ITEMS AS VIRUSES, WORMS, TROJAN HORSES AND OTHER ITEMS
            OF A DESTRUCTIVE NATURE. USE OF ANY MATERIAL DOWNLOADED OR OBTAINED
            THROUGH THE USE OF THE WEBSITE SHALL BE AT YOUR OWN DISCRETION AND
            RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
            COMPUTER SYSTEM, MOBILE TELEPHONE, WIRELESS DEVICE OR DATA THAT
            RESULTS FROM THE USE OF THE WEBSITE OR THE DOWNLOAD OF ANY SUCH
            MATERIAL. CHANGES MAY BE MADE TO THE CONTENTS AND SOFTWARE ON THE
            SITE, AND THE WEBSITE DESCRIBED WITHIN OR OFFERED ON THE WEBSITE, AT
            ANY TIME WITHOUT NOTICE. OUR FIGHT USES REASONABLE EFFORTS TO
            INCLUDE ACCURATE AND UP TO DATE INFORMATION ON THE SITE. OUR FIGHT
            IS NOT RESPONSIBLE FOR TECHNICAL, HARDWARE OR SOFTWARE FAILURES OF
            ANY KIND; LOST OR UNAVAILABLE NETWORK CONNECTIONS; OR INCOMPLETE,
            GARBLED OR DELAYED COMPUTER TRANSMISSIONS. NO ADVICE OR INFORMATION,
            WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM OUR FIGHT, ITS
            EMPLOYEES OR REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT EXPRESSLY
            STATED IN THE TERMS.{" "}
          </p>
          <p>
            <li>
              <b>Limitation of Liability.</b>
            </li>{" "}
            YOU AGREE THAT OUR FIGHT WILL NOT FOR ANY REASON OR CIRCUMSTANCE,
            AND REGARDLESS OF LEGAL THEORY, WHETHER IN TORT, CONTRACT, OR
            OTHERWISE, BE LIABLE TO YOU OR ANY OTHER PARTY FOR ANY DIRECT,
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR ANY OTHER DAMAGES,
            HOWEVER CAUSED, AND REGARDLESS OF CHARACTER, INCLUDING WITHOUT
            LIMITATION LOST PROFITS, LOST SAVINGS, LOSS OF PROGRAMS OR OTHER
            DATA ON YOUR INFORMATION HANDLING SYSTEM OR OTHERWISE, EVEN IF OUR
            FIGHT HAS BEEN ADVISED OF THE POSSIBILITY OF ANY SUCH DAMAGES. THE
            FOREGOING LIMITATION OF LIABILITY APPLIES, BY WAY OF EXAMPLE BUT
            WITHOUT LIMITATION, TO ANY DAMAGES ARISING OUT OF OR IN CONNECTION
            WITH (I) YOUR ACCESS TO, USE OF, OR INABILITY TO USE, THE WEBSITE,
            OR (II) ANY ERRORS OR OMISSIONS IN THE CONTENT OF, OR PERFORMANCE OF
            THE WEBSITE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
            CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO SUCH EXCLUSIONS MAY NOT
            APPLY TO YOU. HOWEVER, SUCH EXCLUSIONS SHALL BE ENFORCEABLE TO THE
            MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. IN NO EVENT SHALL OUR
            FIGHT 'S ENTIRE LIABILITY TO YOU IN RESPECT OF ITS WEBSITE, WHETHER
            DIRECT OR INDIRECT, EXCEED THE GREATER OF ONE HUNDRED DOLLARS ($100)
            OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE (12) MONTHS.{" "}
          </p>
          <p>
            <li>
              <b>Indemnification.</b>
            </li>{" "}
            You agree to indemnify and hold harmless Our Fight and its
            affiliates, and their directors, officers, managers, employees,
            donors, agents, and licensors, from and against all losses,
            expenses, damages and costs, including reasonable attorneys' fees,
            resulting from or related to your (i) use of the Website, (ii)
            violation of any part of this User Agreement, or (iii) the failure
            to fulfill any obligations relating to your account incurred by you
            or any other person using your account. We reserve the right to take
            over the exclusive defense of any claim for which we are entitled to
            indemnification under this User Agreement. In such event, you shall
            provide us with such cooperation as is reasonably requested by us.{" "}
          </p>
          <p>
            <li>
              <b>Remedies.</b>
            </li>{" "}
            In the event that Our Fight determines, in its sole discretion, that
            you have breached any of the terms of this User Agreement, or have
            otherwise engaged in inappropriate conduct, Our Fight may, in
            addition to the arbitration process set forth below and any other
            remedies that may be available, (i) warn you of the violation via
            e-mail; (ii) delete any content provided by you or your agent(s);
            (iii) terminate your use of the Site; (iv) notify and fully
            cooperate with the proper law enforcement authorities for further
            action; or (v) take any other action which Our Fight deems to be
            appropriate.{" "}
          </p>
          <p>
            Any controversy or claim arising out of or relating to the User
            Agreement shall be settled by binding arbitration in accordance with
            the commercial arbitration rules of the American Arbitration
            Association, or for any non-United States of America based
            organizations, the Association for International Arbitration. Any
            such controversy or claim shall be arbitrated on an individual
            basis, and shall not be consolidated in any arbitration with any
            claim or controversy of any other party. The decision of the
            arbitrator shall be final and unappealable. The arbitration shall be
            conducted in the State of Virginia, United States of America, and
            judgment on the arbitration award may be entered into any court
            having jurisdiction thereof. The prevailing party shall be entitled
            to attorney's fees. Notwithstanding anything to the contrary, Our
            Fight may at any time seek injunctions or other forms of equitable
            relief from any court of competent jurisdiction.{" "}
          </p>
          <p>
            <li>
              <b>Governing Law.</b>
            </li>{" "}
            This website is controlled by Our Fight from our offices located in
            the state of Virginia, United States. It can be accessed by most
            countries around the world. As each country has laws that may differ
            from those of Virginia, by accessing our website, you agree that the
            statutes and laws of Virginia, without regard to the conflict of
            laws.{" "}
          </p>
          <p>
            Furthermore, any action to enforce this User Agreement outside the
            scope of mandatory arbitration as set forth above shall be brought
            in the federal or state courts located in United States, Virginia.
            You hereby agree to personal jurisdiction by such courts, and waive
            any jurisdictional, venue, or inconvenient forum objections to such
            courts.{" "}
          </p>
          <p>
            <li>
              <b>Severability.</b>
            </li>{" "}
            If any provision of this User Agreement is found to be unenforceable
            or invalid under any applicable law, such unenforceability or
            invalidity shall not render the User Agreement unenforceable or
            invalid as a whole, and such provisions shall be deleted without
            affecting the remaining provisions herein.{" "}
          </p>
          <p>
            <li>
              <b>Entire Agreement.</b>
            </li>{" "}
            This User Agreement constitutes the complete and exclusive statement
            of the mutual understanding of the parties and supersedes and
            cancels all previous written and oral agreements and communications
            relating to the subject matter hereof. No failure or delay in
            exercising, on the part of either party, any privilege, power or
            right hereunder will operate as a waiver thereof.{" "}
          </p>
          <li>
            <b>Special Notice For New Jersey Users.</b>
          </li>{" "}
          BY USING THIS WEBSITE, YOU: (A) ASSUME ALL RISKS OF LOSSES OR DAMAGES
          RESULTING FROM YOUR USE OF OR INABILITY TO USE THIS WEBSITE; (B)
          IRREVOCABLY WAIVE ALL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL,
          PUNITIVE OR INCIDENTAL DAMAGES (INCLUDING, WITHOUT LIMITATION, THOSE
          RESULTING FROM LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION) THAT
          MAY OCCUR AS A RESULT OF YOUR USE OF THIS WEBSITE; AND (C) EXPRESSLY
          AGREE TO RELEASE AND DISCHARGE FACTS, AND ITS AFFILIATES, EMPLOYEES,
          AGENTS, REPRESENTATIVES, SUCCESSORS, OR ASSIGNS FROM ANY AND ALL
          CLAIMS OR CAUSES OF ACTION RESULTING, DIRECTLY OR INDIRECTLY, FROM
          YOUR USE OF THIS WEBSITE; AND (D) YOU VOLUNTARILY GIVE UP OR WAIVE ANY
          RIGHT THAT YOU MAY OTHERWISE HAVE TO BRING A LEGAL ACTION AGAINST
          FACTS FOR LOSSES OR DAMAGES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
          OR OTHER LEGAL THEORY, INCLUDING ANY CLAIM BASED ON ALLEGED NEGLIGENCE
          ON THE PART OF FACTS AND ITS AGENTS AND EMPLOYEES. YOU ACKNOWLEDGE
          THAT YOU HAVE CAREFULLY READ THIS “WAIVER AND RELEASE” AND FULLY
          UNDERSTAND THAT IT IS A RELEASE OF LIABILITY.{" "}
        </p>
      </ol>

      <p>
        <b>How to Contact Us</b>
      </p>

      <p>
        If you have any questions or comments about the User Agreement as set
        forth above, you can contact us at:{" "}
      </p>

      <p>
        Our Fight Inc <br />
        10 Crovo Ln <br />
        Fredericksburg, VA 22405{" "}
      </p>

      <p>
        Email: <br />
        contact@ourfight.online.com{" "}
      </p>
    </div>
  );
}

export default UserAgreement;