import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Carousel, Col, Row } from "react-bootstrap";
import FormImage from "./Form/FormImage";
import VideoList from "./VideoList";

function Latest(props) {
  const [data, setData] = useState(null);

  const pair = (array) => {
    const set = [];

    while (array.length) {
      set.push(array.slice(0, 2));
      array = array.slice(2);
    }

    return set;
  };

  const getData = useCallback(async () => {
    await axios
      .get("https://api.ourfight.online/latest")
      .then((response) => setData(response.data));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return false;

  //console.log(data);

  return (
    <div className="container-fluid text-center">
      <Row>
        <Col>
          <Carousel className="mb-2">
            {data.slice(0, 4).map((form, index) => (
              <Carousel.Item key={"latestSliderItem" + index}>
                <a href={"/" + form.id}>
                  <FormImage
                    src={"https://static.ourfight.online/upload/" + form.image}
                    reduce={true}
                  />
                  <Carousel.Caption>
                    <div className="fs-5">{form.title}</div>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Row>
        <VideoList />
      </Row>
      <Row className="g-4">
        {pair(data.slice(4)).map((set, index) => (
          <Row key={"latestItem" + index} className="g-3">
            <Col lg="6" className="my-3">
              <a href={"/" + set[0].id}>
                <FormImage
                  src={"https://static.ourfight.online/upload/" + set[0].image}
                  label={set[0].title}
                  reduce={true}
                  small={true}
                />
              </a>
            </Col>
            <Col lg="6" className="my-3">
              {set[1] && (
                <a href={"/" + set[1].id}>
                  <FormImage
                    src={
                      "https://static.ourfight.online/upload/" + set[1].image
                    }
                    label={set[1].title}
                    reduce={true}
                    small={true}
                  />
                </a>
              )}
            </Col>
          </Row>
        ))}
      </Row>
    </div>
  );
}
export default Latest;