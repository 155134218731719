import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import ProgressBar from './ProgressBar';

function FormProgress(props) {
    const [data, setData] = useState(null);

    const getData = useCallback(async () => {
        await axios
            .get(`https://api.ourfight.online/formProgress/id/${props.formId}`)
            .then((response) => setData(response.data));
    }, [props.formId]);

    useEffect(() => {
        getData();
    }, [getData]);

    if (!data) return null;
    else {
        const tally = parseInt(data.tally);
        const goal =
            tally < 50
                ? 50
                : tally < 100
                ? 100
                : tally < 250
                ? 250
                : tally < 500
                ? 500
                : tally < 1000
                ? 1000
                : Math.floor(tally / 1000) * 1000 + 1000;

        return tally >= 25 ? (
            <ProgressBar
                className={`mb-3 ${props?.className}`}
                percent={(tally / goal) * 100}
                label={
                    tally.toLocaleString('en-us') +
                    ' have already signed. Help us get to ' +
                    goal.toLocaleString('en-us') +
                    '!'
                }
            />
        ) : null;
    }
}

export default FormProgress;
