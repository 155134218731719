import React from "react";
import FormFieldsDefault from "./FormFieldsDefault";
import FormFieldsMinimal from "./FormFieldsMinimal";
import FormFieldsPromote from "./FormFieldsPromote";
import { handleRelay } from "./HandleRelay";

function FormFields(props) {
  if (false && props.promoteForm && !!props.signerState) {
    if (!!props.form.startEndDates || !!props.form.relayURL) {
      handleRelay(props);
    } else return <FormFieldsPromote {...props} />;
  } else if (props.form.inputType === 1)
    return <FormFieldsMinimal {...props} />;
  else return <FormFieldsDefault {...props} />;
}

export default FormFields;