import { Button, Card, Col, Row } from "react-bootstrap";

const SportsMatchScore = (props) => {
  const scoreAway = parseInt(
    props.getAttr(props.getTag(props.match, "awayteam"), "totalscore"),
    10
  );
  const scoreHome = parseInt(
    props.getAttr(props.getTag(props.match, "hometeam"), "totalscore"),
    10
  );

  const dateTimeFields = props.getAttr(props.match, "datetime_utc").split(" ");
  const dateFields = dateTimeFields[0].split(".");
  const timeFields = dateTimeFields[1].split(":");
  const date = new Date(
    parseInt(dateFields[2], 10),
    parseInt(dateFields[1], 10) - 1,
    parseInt(dateFields[0], 10),
    parseInt(timeFields[0], 10),
    parseInt(timeFields[1], 10)
  );

  const offset = date.getTimezoneOffset() / 60;
  const hours = (date.getHours() ? date.getHours() : 12) - offset;
  const minutes = date.getMinutes();
  const time = `${(hours < 0 ? 12 + hours : hours > 12 ? hours - 12 : hours)
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${
    !date.getHours() || hours < 0 || hours >= 12 ? "PM" : "AM"
  }`;

  return (
    <Button
      variant="link"
      className="w-100 text-left text-decoration-none text-dark p-1"
      onClick={() => {
        if (props?.scroll)
          window.location = `/sports?mid=${props.getAttr(props.match, [
            "contestID",
            "id",
          ])}`;
        else props.setMatch(props.match);
      }}
    >
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <small>{time}</small>
            </Col>
          </Row>
          <Row>
            <Col className={scoreAway > scoreHome ? "fw-bold" : ""}>
              {props.getAttr(props.getTag(props.match, "awayteam"), "name")}
            </Col>
            <Col xs={2} className={scoreAway > scoreHome ? "fw-bold" : ""}>
              {scoreAway}
            </Col>
          </Row>
          <Row>
            <Col className={scoreHome > scoreAway ? "fw-bold" : ""}>
              {props.getAttr(props.getTag(props.match, "hometeam"), "name")}
            </Col>
            <Col xs={2} className={scoreHome > scoreAway ? "fw-bold" : ""}>
              {scoreHome}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Button>
  );
};

export default SportsMatchScore;