import React, { useCallback, useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import FormFields from "../FormFields";
import FormFooter from "../FormFooter";
import FormImage from "../FormImage";
import FormPledge from "../FormPledge";
import FormProgress from "../FormProgress";
import FormText from "../FormText";
import FormTitle from "../FormTitle";
import FormThankYou from "../FormThankYou";
import useDeepCompareEffect from "use-deep-compare-effect";
import { toBoolean } from "../../Shared";

function FormEmbedFull(props) {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);
  const [pollAnswer, setPollAnswer] = useState(null);
  const [height, setHeight] = useState(0);
  const [interactionId, setInteractionId] = useState(null);
  const [formData, setFormData] = useState({
    firstName: props?.firstName,
    lastName: props?.lastName,
    zip: props?.zip,
    email: props?.email,
    pollQuestions: {},
  });

  const updateIFrameHeight = useCallback(() => {
    let ourFightForm = document.getElementById("ourFightForm");

    if (ourFightForm) {
      let newHeight = Math.max(
        ourFightForm.scrollHeight,
        ourFightForm.offsetHeight
      );

      if (newHeight !== height) {
        setHeight(newHeight);
        window.top.postMessage(
          {
            ourfight: true,
            message: "resize",
            id: data.id,
            height: newHeight,
          },
          "*"
        );
      }
    }
  }, [height, data?.id]);

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/form/id/${props.id}`)
      .then((response) => {
        setData(response.data);
        setFormData({
          ...props.formData,
          zip: response.data?.closestZip?.ZipCode,
        });
      });
  }, [props.id, props.formData]);

  useDeepCompareEffect(() => {
    getData();
    const interval = setInterval(updateIFrameHeight, 100);
    return () => clearInterval(interval);
  }, [getData, updateIFrameHeight]);

  if (!data) return false;

  if (
    !data.priority &&
    data.archivedDateTime &&
    data.archivedDateTime !== "0000-00-00 00:00:00"
  ) {
    return (
      <div>
        <div className="display-2 text-center mb-3">
          This petition is no longer available
        </div>
      </div>
    );
  } else
    switch (step) {
      case 3:
        return (
          <div id="ourFightForm">
            <FormTitle title={data.title} />
            {!toBoolean(data.hideProgressBar) && (
              <FormProgress formId={data.id} />
            )}
            <FormImage
              src={"https://static.ourfight.online/upload/" + data.image}
              reduce={true}
              expand={true}
            />
            <FormThankYou
              formId={data.id}
              title={data.title}
              poll={data.polls}
              pollAnswer={pollAnswer}
              showReps={data.showReps}
              zip={formData?.zip}
            />
            <FormFooter text={data.footerText} url={data.footerURL} />
          </div>
        );
      case 2:
        return (
          <div className="px-3" id="ourFightForm">
            <FormTitle title={data.title} />
            <FormFooter text={data.footerText} url={data.footerURL} />
            {!toBoolean(data.hideProgressBar) && (
              <FormProgress formId={data.id} />
            )}
            <FormImage
              src={"https://static.ourfight.online/upload/" + data.image}
              reduce={true}
              expand={true}
            />
            <FormText text={data.contentPledge} />
            <FormPledge
              form={data}
              formData={formData}
              setFormData={(p) => {
                setFormData(p);
              }}
              setCustomHTML={(p) => {
                props.setCustomHTML(p);
              }}
              setStep={(p) => {
                setStep(p);
              }}
              poll={data.polls}
              pollAnswer={pollAnswer}
              interactionId={interactionId}
            />
          </div>
        );
      default:
        return (
          <div id="ourFightForm">
            <FormTitle title={data.title} />
            <FormFooter text={data.footerText} url={data.footerURL} />
            {!toBoolean(data.hideProgressBar) && (
              <FormProgress formId={data.id} />
            )}
            <FormImage
              src={"https://static.ourfight.online/upload/" + data.image}
              reduce={true}
              expand={true}
            />
            <FormText text={data.content} />
            <Form>
              <FormFields
                form={data}
                formData={formData}
                setFormData={(p) => {
                  setFormData(p);
                }}
                stack={true}
                setStep={(p) => {
                  setStep(p);
                }}
                setPollAnswer={(p) => {
                  setPollAnswer(p);
                }}
                setInteractionId={(p) => {
                  setInteractionId(p);
                }}
                formId={data.id}
                poll={data.polls}
                emailIntegration={data.emailIntegration}
                merchantIntegration={data.merchantIntegration}
              />
            </Form>
          </div>
        );
    }
}

export default FormEmbedFull;