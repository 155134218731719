import { Button } from "react-bootstrap";
import React from "react";
import { Arrow90degRight } from "react-bootstrap-icons";

const ManageSubscriptionButton = (props) => {
  const href = "https://billing.stripe.com/p/login/test_00g4hM74jgdXbew5kk";

  if (props?.link)
    return (
      <Button variant="link" className="p-0" target="_self" href={href}>
        here
      </Button>
    );

  return (
    <Button variant="dark" target="_self" href={href}>
      <Arrow90degRight /> Manage Subscription
    </Button>
  );
};

export default ManageSubscriptionButton;