import React from "react";
import { Card } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {
  ChatDotsFill,
  Facebook,
  Pinterest,
  Twitter,
} from "react-bootstrap-icons";
import { Textfit } from "react-textfit";
import TooltipOverlay from "../../TooltipOverlay";
//import LayoutItemBallot from "./LayoutItemBallot";

const LayoutItem = (props) => {
  if (!props.article) return null;
  return (
    <div
      className={`sectionItem ${props.highlight ? "highlight" : ""}`}
      data-id={props?.article?.id}
    >
      {props.article.badge}

      {/* !props.hideBallot && <LayoutItemBallot {...props} /> */}

      {!!props?.article?.LATESTCOMMENT_TIMESTAMP && (
        <div className="ballot">
          <a rel="noreferrer" href={props.article.url} target="_blank">
            <TooltipOverlay tip="Join the conversation!">
              <ChatDotsFill className="chat fs-4 text-light" />
            </TooltipOverlay>
          </a>
        </div>
      )}

      <a rel="noreferrer" href={props.article.url} target="_blank">
        <Card
          key={props.article.id}
          bg="dark"
          text="light"
          className={props.className}
          style={{
            backgroundPosition: "center",
            backgroundImage: `url("${ReactHtmlParser(
              props.article.imageURL
            )}")`,
          }}
        >
          <Card.ImgOverlay>
            <div className="cardInfo p-2">
              <Card.Title>
                <Textfit
                  style={{ height: "100%" }}
                  mode="multi"
                  forceSingleModeWidth={false}
                  min={10}
                  max={64}
                >
                  {ReactHtmlParser(props.article.title)}
                </Textfit>
              </Card.Title>
              <Card.Footer className="text-start">
                {props?.article?.domain?.replace(/^[^:]+:\/\/(www\.)*/, "")}
                <a
                  className="float-end text-light text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`http://pinterest.com/pin/create/button/?url=${encodeURI(
                    props.article.url
                  )}`}
                >
                  <Pinterest />
                </a>
                <a
                  className="float-end text-light text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`http://twitter.com/share?url=${encodeURI(
                    props.article.url
                  )}`}
                >
                  <Twitter />
                </a>
                <a
                  className="float-end text-light text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                    props.article.url
                  )}`}
                >
                  <Facebook />
                </a>
              </Card.Footer>
            </div>
          </Card.ImgOverlay>
        </Card>
      </a>
    </div>
  );
};

export default LayoutItem;