import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { FilePdf } from "react-bootstrap-icons";

function FormPDF(props) {
  const [data, setData] = useState(null);
  const [scaleToFit, setScaleToFit] = useState(false);
  const [clearFontSizes, setClearFontSizes] = useState(true);
  const [includeImage, setIncludeImage] = useState(true);
  const [includeTitle, setIncludeTitle] = useState(true);
  const [includePolls, setIncludePolls] = useState(true);
  const [includeZip, setIncludeZip] = useState(true);
  const [current, setCurrent] = useState(0);
  const [total, setTotal] = useState(0);

  const splitIntoBatches = (data, size) => {
    let batch = [];

    while (data.length) batch.push(data.splice(0, size));

    return batch;
  };

  const clearElementFontSize = (elements) => {
    if (Symbol.iterator in Object(elements)) {
      for (let element of [...elements]) {
        element.style.fontSize = "inherit";

        for (let child of [...element.children]) clearElementFontSize(child);
      }
    } else {
      elements.style.fontSize = "inherit";

      for (let child of [...elements.children]) clearElementFontSize(child);
    }
  };

  const pageDiv = () => {
    let page = document.createElement("div");
    page.style =
      "box-sizing: border-box; display: inline-block; font-size: 12px; min-width: 595px; padding: 0px; width: 595px;";

    return page;
  };

  const generatePage = (pageData) => {
    let page = pageDiv();

    const imageWidth = data.form.imageWidth > 595 ? 595 : data.form.imageWidth;
    const imageHeight = imageWidth / data.form.imageAspect;

    if (includeImage) {
      let image = document.createElement("img");
      image.style = `max-width: 100%; height: ${imageHeight}px;`;
      image.src = "https://static.ourfight.online/upload/" + data.form.image;
      page.append(image);
    }

    if (includeTitle) {
      let title = document.createElement("div");
      title.style = "font-size: 24px; margin: 15px; text-align: center;";
      title.innerHTML = data.form.title;
      page.append(title);
    }

    if (clearFontSizes) {
      let content = document.createElement("div");
      content.innerHTML = data.form.content;
      clearElementFontSize(content.children);
      page.innerHTML += content.innerHTML;
    } else page.innerHTML += data.form.content;

    if (includePolls) {
      let poll = document.createElement("div");
      let pollQA = pageData.polls.replaceAll("\r", "").split("\n\n");
      pollQA.forEach(function(qa) {
        qa = qa.split("\n");

        if (qa.length > 1) {
          let q = document.createElement("div");
          q.style = "font-size: 12px; font-weight: bold;";
          q.innerHTML = qa[0];
          poll.append(q);

          let a = document.createElement("div");
          a.style.marginBottom = "1em";
          a.innerHTML = qa[1].replace(/\{[^}]*\}/g, "");
          poll.append(a);
        }
      });

      page.append(poll);
    }

    let name = document.createElement("div");
    name.style = "font-size: 12px; margin-right: 1em; text-align: right;";
    name.innerHTML =
      (!!pageData.firstName
        ? pageData.firstName + " " + pageData.lastName.substr(0, 1) + "."
        : "Signer") +
      (includeZip
        ? " of zipcode " +
          (pageData.zip.toString().length < 5
            ? "0".repeat(5 - pageData.zip.toString().length)
            : "") +
          pageData.zip.toString()
        : "");
    page.append(name);

    let signature = document.createElement("div");
    signature.style =
      "font-family: 'Herr Von Muellerhoff', cursive; font-size: 36px; margin-right: 1em; text-align: right;";
    signature.innerHTML =
      pageData.firstName + " " + pageData.lastName.substr(0, 1);
    page.append(signature);

    if (!scaleToFit) {
      document.body.appendChild(page);
      const rect = page.getBoundingClientRect();

      let offset = 0;
      let child = page.children;
      let html = [{ rect: rect, page: pageDiv() }];

      for (var c = 0; c < child.length; c++) {
        const rect = child[c].getBoundingClientRect();
        if (!c) offset = rect.top;
        else if (rect.bottom - offset >= 842) {
          offset = rect.top;
          html.push({ rect: rect, page: pageDiv() });
        }
        html[html.length - 1].page.innerHTML += child[c].outerHTML;
      }

      document.body.removeChild(page);

      return html;
    } else {
      document.body.appendChild(page);
      const rect = page.getBoundingClientRect();
      document.body.removeChild(page);
      return [{ rect: rect, page: page }];
    }
  };

  const saveBatch = (batches, index) => {
    let worker = null;
    const batch = batches[index];

    batch.forEach((page, index) => {
      if (!index)
        worker = window
          .html2pdf()
          .set({
            image: { type: "png" },
            margin: 52,
            html2canvas: {
              ...{
                useCORS: true,
                logging: false,
                width: page.rect.width,
                windowWidth: page.rect.width,
              },
              ...(scaleToFit && {
                height: page.rect.height,
                windowHeight: page.rect.height,
              }),
            },
            jsPDF: scaleToFit
              ? {
                  unit: "px",
                  format: [page.rect.width, page.rect.height],
                }
              : {
                  unit: "px",
                  format: [page.rect.width, 842],
                },
          })
          .from(page.page)
          .then((pdf) => {
            setCurrent(page.index + 1);
          });
      else {
        if (index === 1) worker = worker.toPdf();

        worker = worker
          .get("pdf")
          .then((pdf) => {
            pdf.addPage();
          })
          .from(page.page)
          .toContainer()
          .toCanvas()
          .toPdf()
          .then((pdf) => {
            setCurrent(page.index + 1);
          });
      }
    });

    worker.save().then((pdf) => {
      if (index < batches.length - 1) saveBatch(batches, index + 1);
    });
  };

  const getData = useCallback(async () => {
    await axios
      .get(
        "https://api.ourfight.online/formInteractions?formId=" +
          props.formId +
          "&sort=" +
          props.orderBy +
          "%20" +
          props.sort +
          "&dedupe=" +
          props.dedupe +
          "&search=" +
          props.search +
          "&weight=" +
          props.weight
      )
      .then((response) => setData(response.data));
  }, [
    props.formId,
    props.orderBy,
    props.sort,
    props.dedupe,
    props.search,
    props.weight,
  ]);

  const generatePDF = (onlyFirst) => {
    let selectedSignatures = onlyFirst ? new Array(data.rows[0]) : data.rows;

    let pages = [];

    selectedSignatures.forEach(function(data, index) {
      pages = [...pages, ...generatePage(data)];
    });

    pages.forEach((page, index) => (page.index = index));

    setTotal(pages.length);

    setCurrent(0);

    const batches = splitIntoBatches(pages, 100);

    saveBatch(batches, 0);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return null;
  else
    return (
      <Modal centered size="md" show={props.show}>
        <Modal.Header className="bg-dark text-light h5">
          <FilePdf />
          &nbsp;Generate PDF
          <Button
            className="btn-close btn-close-white"
            onClick={() => props.onClick()}
          />
        </Modal.Header>
        <ModalBody className="text-left">
          <Row>
            <Col xs={12} lg={7}>
              <Form.Check
                className="h5 mt-2"
                type="switch"
                label="1 Page Per Signature"
                id="scaleToFit"
                checked={scaleToFit}
                onChange={(e) => setScaleToFit(e.target.checked)}
              />
              <Form.Check
                className="h5 mt-2"
                type="switch"
                label="Clear Font Sizes"
                id="clearFontSizes"
                checked={clearFontSizes}
                onChange={(e) => setClearFontSizes(e.target.checked)}
              />
              <Form.Check
                className="h5 mt-2"
                type="switch"
                label="Include Image"
                id="includeImage"
                checked={includeImage}
                onChange={(e) => setIncludeImage(e.target.checked)}
              />
              <Form.Check
                className="h5 mt-2"
                type="switch"
                label="Include Title"
                id="includeTitle"
                checked={includeTitle}
                onChange={(e) => setIncludeTitle(e.target.checked)}
              />
              <Form.Check
                className="h5 mt-2"
                type="switch"
                label="Include Polls"
                id="includePolls"
                checked={includePolls}
                onChange={(e) => setIncludePolls(e.target.checked)}
              />
              <Form.Check
                className="h5 mt-2"
                type="switch"
                label="Include Zip"
                id="includeZip"
                checked={includeZip}
                onChange={(e) => setIncludeZip(e.target.checked)}
              />
            </Col>
            <Col xs={12} lg={5}>
              <Button className="mb-3 w-100" onClick={() => generatePDF(true)}>
                Generate Sample
              </Button>
              <Button className="w-100" onClick={() => generatePDF(false)}>
                Generate All
              </Button>
            </Col>
          </Row>
        </ModalBody>
        <Modal.Footer>
          {total > 0 && (
            <ProgressBar
              className="me-auto resetFontSize h5 w-100"
              striped
              animated
              now={total ? (current / total) * 100 : 0}
              label={total ? `${((current / total) * 100).toFixed(2)}%` : ""}
            />
          )}
        </Modal.Footer>
      </Modal>
    );
}

export default FormPDF;