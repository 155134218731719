import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

const Sandbox = (props) => {
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/sandbox`)
      .then((response) => setData(response.data));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return false;

  return <div>{data}</div>;
};

export default Sandbox;