import React, { useState } from "react";
import { Button, FormGroup } from "react-bootstrap";

function FormPollQuestion(props) {
  const [question] = useState(props.question.split("\n")[0]);

  const selectAnswer = (answer) => {
    props.setFormData({
      ...props.formData,
      pollQuestions: {
        ...props.formData?.pollQuestions,
        [question]: answer,
      },
    });
  };

  const currentAnswer = props.formData.pollQuestions?.[question];

  return (
    <FormGroup className="pollQuestion">
      {props.question.split("\n").map((line, index) =>
        !index ? (
          <h5 key={"pollAnswer" + index}>{" " + line.trim()}</h5>
        ) : (
          <Button
            key={"pollAnswer" + index}
            variant={currentAnswer === line ? "primary" : "light"}
            className={"w-100 mb-3 p-3 "}
            onClick={() => selectAnswer(line)}
          >
            {line.replace(/\{[^}]*\}/g, "")}
          </Button>
        )
      )}
    </FormGroup>
  );
}

export default FormPollQuestion;