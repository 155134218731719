import React from "react";
import { Col, Row } from "react-bootstrap";
import { BarChartLine, ListUl, PencilSquare } from "react-bootstrap-icons";
import { useCookies } from "react-cookie";
import TooltipOverlay from "../TooltipOverlay";

const FormOwnerOptions = (props) => {
  const [cookies] = useCookies(["user_id", "tier"]);

  if (cookies.user_id !== props.data.fk_accountId) return null;

  return (
    <Row>
      <Col className="text-end">
        <TooltipOverlay tip="Edit petition">
          <a
            className="text-decoration-none text-dark me-3"
            href={"/formEdit?id=" + props.data.id}
          >
            <PencilSquare size="1.5em" />
          </a>
        </TooltipOverlay>
        <TooltipOverlay tip="View petition stats">
          <a
            className="text-decoration-none text-dark me-3"
            href={"/stats?formId=" + props.data.id}
          >
            <BarChartLine size="1.5em" />
          </a>
        </TooltipOverlay>
        <TooltipOverlay tip="View petition raw data">
          <a
            className="text-decoration-none text-dark me-3"
            href={"/interactions?formId=" + props.data.id}
          >
            <ListUl size="1.5em" />
          </a>
        </TooltipOverlay>
        <div>
          <small>
            These shortcuts are visible because you are the petition owner.
          </small>
        </div>
      </Col>
    </Row>
  );
};

export default FormOwnerOptions;