import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import LayoutItem from "./Layout/LayoutItem";

const NewsPetitionAd = () => {
  const [data, setData] = useState(null);

  const getData = useCallback(() => {
    try {
      axios
        .get(`https://api.ourfight.online/formGetRandomPriority`)
        .then((response) => {
          setData(response.data);
        });
    } catch (e) {
      console.error("[JSON Error]", e);
    }
  }, []);

  useEffect(() => getData(), [getData]);

  if (!data) return null;

  return (
    <div className="news my-3">
      <LayoutItem
        className="cardLarge"
        article={{
          domain: "https://ourfight.online",
          url: `/${data.id}`,
          imageURL: `https://static.ourfight.online/upload/${data.image}`,
          title: data.title,
          badge: null,
        }}
      />
    </div>
  );
};

export default NewsPetitionAd;