import React from "react";
import { Image } from "react-bootstrap";

function PrivacyPolicy(props) {
  return (
    <div>
      <div className="text-center">
        <Image className="image-fluid w-50 my-3" src="ourfight.png" />
      </div>
      <p className="text-center">
        <b>Privacy Policy of Our Fight</b>
      </p>

      <p className="text-center">Effective Date: August 19, 2020</p>

      <p>
        Our Fight Inc (“Our Fight”, “we”, “our”, or “us”) is committed to
        protecting your privacy. This privacy policy (“Privacy Policy”) provides
        you with information regarding our information gathering and usage
        practices for our website (the “Website”). By using the Website in any
        manner, you acknowledge that you accept the practices and policies
        outlined in this Privacy Policy, and you hereby consent that we will
        collect, use, and share your information in the following ways. This
        Privacy Policy applies to users who are located in the U.S. when using
        the Website.
      </p>

      <p>
        Our Fight's Website are at all times subject to the User Agreement,
        which incorporates this Privacy Policy. Any undefined terms used in this
        Privacy Policy have the definitions given to them in the User Agreement.
      </p>

      <p>
        Our Fight reserves the right to change or modify this Privacy Policy in
        our sole discretion at any time. If any such changes or modifications
        are made, we will update the “Effective Date” appearing at the top of
        this web page. Such changes or modifications shall be effective
        immediately upon posting, and supersede any prior versions of this
        policy.{" "}
      </p>

      <p>
        The Website is not directed to children under 16. We do not knowingly
        collect, maintain, or use personally identifiable information from
        children under age 16. If a parent or guardian becomes aware that his or
        her child has provided us personally identifiable information without
        their consent, he or she should contact us using the information in the
        “How to Contact Us” section, below. If we confirm we collected such
        information, we will take all reasonable measures to delete that
        information from our system as soon as possible.{" "}
      </p>

      <p>
        This Privacy Policy covers our treatment of personal information
        (“Personal Information”) that we gather when you are accessing or using
        our Website, but not to the practices of companies we don't own or
        control, or people that we don't manage. We gather various types of
        Personal Information from our users, as explained in more detail below,
        and we use this Personal Information internally in connection with our
        Website, including to personalize, provide, and improve our Website, to
        allow you to set up a user account and profile, to contact you and allow
        other users to contact you, and to analyze how you use the Website. In
        certain cases, we may also share some Personal Information with third
        parties, but only as described below.{" "}
      </p>

      <p className="text-center">
        <b>Information We Collect</b>
      </p>

      <p>
        <u>Non-Identifying Personal Information</u>
      </p>

      <p>
        We may collect information from your visit or as part of the
        registration and administration of your account in order to personalize
        and improve upon your experience with us, such as, without limitation,
        age and individual preferences.{" "}
      </p>

      <p>
        <i>Cookies</i>
      </p>

      <p>
        Cookies are small text files. Our Cookies save anonymized data about
        individual visitors, allowing our Website to recognize information about
        a visitor and help deliver personalized content and other services and
        functions. Cookies make your Internet experience quicker and more
        convenient. The Website use both session cookies, which terminate when a
        user closes his or her browser, and persistent cookies, which remain on
        the user's device until they expire or are manually deleted. The Website
        also contain coding from our business and technology partners which
        generates third party cookies. Third party cookies allow our business
        and technology partners to store non-Identifying Information they can
        access when you visit this or other websites. Accepting a cookie from a
        web or device browser does not give us access to any Personal
        Information. You may be able to change the preferences on your browser
        or device to prevent or limit your device's acceptance of cookies, but
        this may prevent you from taking advantage of some of our features.{" "}
      </p>

      <p>
        Through cookies we place on your browser or device, we may collect
        information about your online activity after you leave our Website. Just
        like any other usage information we collect, this information allows us
        to improve the Website and customize your online experience, and
        otherwise as described in this Privacy Policy. Your browser may offer
        you a “Do Not Track” option, which allows you to signal to operators of
        websites and web applications and services (including behavioral
        advertising services) that you do not wish such operators to track
        certain of your online activities over time and across different
        websites. Our Website do not support Do Not Track requests at this time,
        which means that we may collect information about your online activity
        both while you are using the Website and after you leave our Website.{" "}
      </p>

      <p>
        We may use this data to customize content for you that we think you
        might like, based on your usage patterns. We may also use it to improve
        the Website - for example, this data can tell us how often users use a
        particular feature of the Website, and we can use that knowledge to make
        the Website interesting to as many users as possible.{" "}
      </p>

      <p>
        <i>Device and Connection Information</i>
      </p>

      <p>
        When you use our Website, we will identify the Internet Protocol (IP)
        address of your computer or handheld device. The IP address does not
        identify you personally, but it lets us identify the device you are
        using. We store IP addresses in case we need to track a connection for
        security purposes. We may also collect other device-specific information
        about the device you are using, including what type of device it is and
        hardware model, what operating system you are using, device settings,
        unique device identifies, geo-location data, and crash data. Whether we
        collect some or all of this information often depends on what type of
        device you are using and its settings. Check the policies of your device
        manufacturer or software provider to learn more about what information
        your device makes available to us.{" "}
      </p>

      <p>
        <i>Web Beacons (1x1 Pixels or GIFS)</i>
      </p>

      <p>
        Our Website may contain coding known as Web Beacons that use 1x1-pixel
        images to capture and transmit the online activity of users on our
        Website. These Web Beacons typically transmit information about form
        completions and other activities in order to measure advertising
        effectiveness or store analytics information. In addition, we may use
        Web Beacons in HTML-based emails. This allows us to evaluate the
        effectiveness of our email communications and our marketing campaigns by
        showing how many emails recipients have opened.{" "}
      </p>

      <p>
        <i>Log Files</i>
      </p>

      <p>
        Like most websites, our servers utilize log files. Log files store
        information including internet protocol (IP) addresses, browser type,
        internet service provider (ISP), referring/exit pages, platform type,
        date/time stamp, and number of clicks. Log files are used to analyze
        trends, administer the Website, track Users' movement in the aggregate,
        and gather broad demographic information for aggregate use. We use log
        file information at times to help identify you as you browse and to
        gather broad technical and demographic information on who uses our
        website.{" "}
      </p>

      <p>
        <i>Google Analytics Feature</i>
      </p>

      <p>
        We use Google Analytics to collect information about the use of the
        Website. Google Analytics collects information such as how often users
        visit the Website, what pages they visit when they do so, and what other
        sites they used prior to coming to the Website. We use the information
        we get from Google Analytics only to improve the Website. Google
        Analytics collects only the IP address assigned to you on the date you
        visit the Website, rather than your name or other identifying
        information. We do not combine the information collected through the use
        of Google Analytics with Personally Identifiable Information. Although
        Google Analytics plants a permanent cookie on your web browser to
        identify you as a unique user the next time you visit this site, the
        cookie cannot be used by anyone but Google. Google's ability to use and
        share information collected by Google Analytics about your visits to
        this site is restricted by the Google Analytics Terms of Use and the
        Google Privacy Policy. You can prevent Google Analytics from recognizing
        you on return visits to this Website by disabling the Google Analytics
        cookie on your browser. You can also view and change the currently
        available opt-outs for Google Ads.{" "}
      </p>

      <p>
        <i>Identifying Personal Information</i>
      </p>

      <p>
        We may collect Identifying Personal information that you knowingly
        provide to us through your registration process and account settings for
        the Website as well as information from your transactions with us or
        others. This information may include: Your first and last name, home
        address, telephone number (home, work, or mobile), birthdate, and email
        address.{" "}
      </p>

      <p>
        We may communicate with you with the information provided. For example,
        if you've given us your email address, we may send you promotional email
        offers on behalf of other businesses, or email you about your use of the
        Website. Also, we may receive a confirmation when you open an email from
        us. This confirmation helps us make our communications with you more
        interesting and improve our services. If you do not want to receive
        communications from us, please indicate your preference by choosing the
        “unsubscribe” option at the bottom of any email received from us.{" "}
      </p>

      <p>
        When you sign a petition, you will have the option to share your email
        address with the petition creator. If you consent to this, the petition
        creator will be able to communicate with you directly to keep you
        informed. If this is abused, we may restrict a petition creator's
        ability to access this option in the future. However, Our Fight is not
        affiliated with any petition creator and cannot control nor takes
        responsibility for any of their actions.{" "}
      </p>

      <p className="text-center">
        <b>Information We Do Not Collect</b>
      </p>

      <p>
        <u>Credit Card Information</u>
      </p>

      <p>
        The Website allows for individual users to contribute money to
        organizations/causes if they so choose. Additionally, organizations will
        have the option to sign up to receive donations. When doing this, the
        user or organization will be required to provide payment information
        including credit card or bank number, expiration date, billing address
        and transaction amount. This is collected by a third-party payment
        processor and is subject to the policies of the third-party payment
        processor. Our Fight Inc neither receives nor stores any information in
        regards to these payments and all transactions take place outside of the
        servers of the Website. Any problems, breaches, or otherwise must be
        addressed with the third-party payment processor and are not the
        responsibility of Our Fight Inc. By using this service, you acknowledge
        and accept these risks and agree to indemnify and hold harmless Our
        Fight INC, its officers, agents, and employees for any damages or
        losses.
      </p>

      <p className="text-center">
        <b>How We Use Your Information?</b>
      </p>

      <p>
        We do not sell your Personal Information in personally identifiable form
        to anyone, provided that certain Personal Information may be transferred
        in connection with business transfers, as described below. We may share
        your Personal Information with third parties as described in this
        section:{" "}
      </p>

      <p>
        <i>Aggregate Data</i>
      </p>

      <p>
        We may anonymize your Personal Information so that you are not
        individually identified, and provide that information to our partners.
        We may also provide aggregate usage information to our partners, who may
        use such information to understand how often and in what ways people use
        our Website, so that they, too, can provide you with an optimal online
        experience. However, we never disclose aggregate usage information to a
        partner in a manner that would identify you personally, as an
        individual.
      </p>

      <p>
        <i>Affiliated Businesses</i>
      </p>

      <p>
        In certain situations, businesses or third-party websites we're
        affiliated with may sell or provide products or services to you through
        or in connection with the Website (either alone or jointly with us). You
        can recognize when an affiliated business is associated with such a
        transaction or service, and we will share your Personal Information with
        that affiliated business only to the extent that it is related to such
        transaction or service.
      </p>

      <p>
        <i>User Profiles and Submissions</i>
      </p>

      <p>
        Certain user profile information, including your name, location, and any
        video or image content that such user has uploaded to the Website, may
        be displayed to other users to facilitate user interaction within the
        Website or address your request for our services. Your account privacy
        settings may allow you to limit the other users who can see the Personal
        Information in your user profile and/or what information in your user
        profile is visible to others. Please remember that any content you
        upload to your public user profile, along with any Personal Information
        or content that you voluntarily disclose online in a manner other users
        can view (on discussion boards, in messages and chat areas, etc.)
        becomes publicly available, and can be collected and used by anyone.
        Your user name may also be displayed to other users if and when you send
        messages or comments or upload images or videos through the Website and
        other users can contact you through messages and comments.{" "}
      </p>

      <p>
        <i>Business Transfers</i>
      </p>

      <p>
        We may choose to buy or sell assets, and may share and/or transfer
        customer information in connection with the evaluation of and entry into
        such transactions. Also, if we (or our assets) are acquired, or if we go
        out of business, enter bankruptcy, or go through some other change of
        control, Personal Information could be one of the assets transferred to
        or acquired by a third party.{" "}
      </p>

      <p>
        Protection of Company and Others: We reserve the right to access, read,
        preserve, and disclose any information that we [reasonably] believe is
        necessary to comply with law or court order; enforce or apply our Terms
        of Use and other agreements; or protect the rights, property, or safety
        of Company, our employees, our users, or others.{" "}
      </p>

      <p>
        <i>VERMONT RESIDENTS</i>
      </p>

      <p>
        We will not disclose nonpublic personal financial information about you
        other than as permitted by law unless you authorize us to make that
        disclosure. Your authorization must be in writing or, if you agree, in
        electronic form.{" "}
      </p>

      <p className="text-center">
        <b>Information Security</b>
      </p>

      <p>
        We are committed to providing you a useful and enjoyable online
        experience. We implement reasonable and appropriate physical,
        procedural, and electronic safeguards to protect your information. Your
        account is protected by a password for your privacy and security. You
        are responsible for preventing unauthorized access to your account and
        Personal Information by selecting and protecting your password
        appropriately.{" "}
      </p>

      <p>
        We take careful steps to safeguard customer information, your account,
        and other Personal Information we hold in our records, but
        unfortunately, we cannot guarantee complete security. Unauthorized entry
        or use, hardware or software failure, and other factors, may compromise
        the security of user information at any time.{" "}
      </p>

      <p>
        We are not responsible for the collection and use of information by
        companies or individuals unaffiliated with us whose websites may contain
        links to the Website. Please remember that when you use a link to go
        from our Website to another website, our Privacy Policy does not apply
        to third party websites or services. Your browsing and interaction on
        any third-party website or service are subject to that third party's own
        rules and policies. If you are using a third-party website or service,
        and you allow such a third-party access to your user content, you do so
        at your own risk. This Privacy Policy does not apply to information we
        collect by other means (including offline) or from other sources other
        than through the Website.{" "}
      </p>

      <p className="text-center">
        <b>How to Contact Us</b>
      </p>

      <p>
        If you have any questions about this Policy, please contact us by email
        or regular mail at the following address:{" "}
      </p>

      <p>
        Our Fight INC <br />
        10 Crovo Ln <br />
        Fredericksburg, VA 22405{" "}
      </p>

      <p>
        Email: <br />
        contact@ourfight.online.com{" "}
      </p>
    </div>
  );
}

export default PrivacyPolicy;