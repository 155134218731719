import { useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const Toasts = (props) => {
  const [messages, setMessages] = useState([]);

  const removeMessage = (message) => {
    setMessages((current) => {
      const index = current.indexOf(message);
      current.splice(index, 1);
      return [...current];
    });
  };

  useEffect(() => {
    setMessages((current) => {
      const newToast = {
        time: Date.now(),
        text: props.message,
      };

      setTimeout(() => {
        removeMessage(newToast);
      }, 3000);

      return [...current, newToast];
    });
  }, [props.message]);

  return (
    <ToastContainer
      className="position-fixed"
      position="top-center"
      style={{ zIndex: 100 }}
    >
      {messages.map(
        (message, index) =>
          message.text && (
            <Toast bg="light">
              <Toast.Body>{message.text}</Toast.Body>
            </Toast>
          )
      )}
    </ToastContainer>
  );
};

export default Toasts;