import React from "react";
import FormPollQuestion from "./FormPollQuestion";

function FormPoll(props) {
  return (
    <div>
      {props.poll &&
        props.poll
          .replaceAll("\r", "")
          .split("\n\n")
          .map((question, index) => (
            <FormPollQuestion
              formData={props.formData}
              setFormData={(p) => {
                props.setFormData(p);
              }}
              key={"pollQuestion" + index}
              question={question}
            ></FormPollQuestion>
          ))}
    </div>
  );
}

export default FormPoll;