import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { ArrowRightCircleFill } from "react-bootstrap-icons";

const NewsAddFooter = (props) => {
  const [show, setShow] = useState(null);
  const [link, setLink] = useState(null);

  const onScroll = useCallback(() => {
    const links = [
      { url: "https://sovrn.co/13k1ff6", caption: "Guns.com" },
      { url: "https://sovrn.co/1q56fo6", caption: "IdeaUSA.com" },
      { url: "https://sovrn.co/qaedtn7", caption: "OzonicsHunting.com" },
      { url: "https://sovrn.co/10053qr", caption: "SAFishing.com" },
    ];

    const max = document?.documentElement?.scrollHeight;
    const current = window.scrollY;
    const percent = max && current ? current / max : 0;

    if (percent >= 0.33) {
      setShow(true);
      setLink(links[Math.round(Math.random() * (links.length - 1))]);
      window.removeEventListener("scroll", onScroll);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, [onScroll]);

  if (!show) return null;
  if (show) return null;

  return (
    <a
      className="ad p-3 text-center text-decoration-none fs-3"
      target="_blank"
      rel="noreferrer"
      href={link?.url}
    >
      {link?.caption}&nbsp;
      <ArrowRightCircleFill />
    </a>
  );
};

export default NewsAddFooter;