import NewsSignleTopic from "../News/Section/NewsSingleTopic";
import SportsMLB from "./SportsMLB";
import SportsNFL from "./SportsNFL";

const Sports = (props) => {
  return (
    <>
      <SportsMLB {...props} />
      <SportsNFL {...props} />
      <NewsSignleTopic section="Sports" caption="Sports News" />
    </>
  );
};

export default Sports;