import { Table } from "react-bootstrap";
import SportsURL from "./SportsURL";

const SportsNFL = (props) => {
  const quarterName = (quarter) => {
    switch (quarter) {
      case "firstquarter":
        return "1st";

      case "secondquarter":
        return "2nd";

      case "thirdquarter":
        return "3rd";

      case "fourthquarter":
        return "4th";

      default:
        return "OT";
    }
  };
  const displayEvents = (match, getTag, getAttr) => {
    const teamAway = getAttr(getTag(match, ["awayteam"]), "name");
    const teamHome = getAttr(getTag(match, ["hometeam"]), "name");

    return (
      <Table striped hover bordered responsive>
        <thead>
          <tr>
            <th>Quarter</th>
            <th>Time</th>
            <th>Team</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {getTag(match, ["events", "event"]).map((event) => (
            <tr>
              <td>{quarterName(event.parentNode.nodeName)}</td>
              <td>{getAttr(event, "min")}</td>
              <td>
                {getAttr(event, "team") === "awayteam" ? teamAway : teamHome}
              </td>
              <td>{getAttr(event, "player")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const displayScores = (match, team, getTag, getAttr) => {
    const scores = getTag(match, team);
    const periodCount = Math.max(4, getTag(match, ["events"]).length);
    const periods = [];

    for (let pi = 1; pi <= periodCount; pi++)
      periods.push(getAttr(scores, pi <= 4 ? `q${pi}` : `ot`));

    return (
      <>
        {periods.map((score, index) => {
          return <td key={`${index}-${score}`}>{score}</td>;
        })}

        <td>{getAttr(scores, "totalscore")}</td>
      </>
    );
  };

  const getPeriods = (match, getTag, getAttr) => {
    const periodCount = Math.max(4, getTag(match, ["events"]).length);

    const periods = [];
    for (let pi = 0; pi <= periodCount; pi++) periods.push(pi + 1);

    return periods;
  };

  return (
    <SportsURL
      league="NFL"
      caption="NFL"
      displayEvents={displayEvents}
      displayScores={displayScores}
      getPeriods={getPeriods}
      {...props}
    />
  );
};

export default SportsNFL;