import React from "react";

function Footer(props) {
  return (
    <div className="text-center my-3 pb-5">
      &copy;{new Date().getFullYear()}
      &nbsp;OurFight.online
      <br />
      <div className="d-print-none">
        <a href="/about" target="_blank">
          About
        </a>
        &nbsp;/&nbsp;
        <a href="/userAgreement" target="_blank">
          User Agreement
        </a>
        &nbsp;/&nbsp;
        <a href="/privacyPolicy" target="_blank">
          Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default Footer;