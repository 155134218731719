import { Button, Form } from "react-bootstrap";
import {
  CaretLeftFill,
  CaretRightFill,
  PlusCircle,
  XCircleFill,
} from "react-bootstrap-icons";

const FormPledgeAmounts = (props) => {
  const addAmount = () =>
    props.setData({
      ...props.data,
      pledgeAmounts: props.pledgeAmounts + " 1.0",
    });

  const moveAmountLeft = (index) => {
    const amounts = `${props.pledgeAmounts || ""}`
      .trim()
      .split(" ")
      .filter((value) => value);

    const buffer = amounts[index - 1];
    amounts[index - 1] = amounts[index];
    amounts[index] = buffer;

    props.setData({
      ...props.data,
      pledgeAmounts: amounts.join(" "),
    });
  };

  const moveAmountRight = (index) => {
    const amounts = `${props.pledgeAmounts || ""}`
      .trim()
      .split(" ")
      .filter((value) => value);

    const buffer = amounts[index + 1];
    amounts[index + 1] = amounts[index];
    amounts[index] = buffer;

    props.setData({
      ...props.data,
      pledgeAmounts: amounts.join(" "),
    });
  };

  const removeAmount = (index) => {
    const amounts = `${props.pledgeAmounts || ""}`
      .trim()
      .split(" ")
      .filter((value) => value);

    amounts.splice(index, 1);

    props.setData({
      ...props.data,
      pledgeAmounts: amounts.join(" "),
    });
  };

  const updateAmount = (index, value) => {
    const amounts = `${props.pledgeAmounts || ""}`
      .trim()
      .split(" ")
      .filter((value) => value);

    amounts[index] = value.replace(/0/g, "") ? value.replace(/^0/g, "") : "0";

    props.setData({
      ...props.data,
      pledgeAmounts: amounts.join(" "),
    });
  };

  return (
    <div className="mt-2">
      {`${props.pledgeAmounts || ""}`
        .trim()
        .split(" ")
        .filter((value) => value)
        .map((value, index, array) => {
          const isFirst = !index;
          const isLast = index === array.length - 1;

          return (
            <div
              key={`amount-${index}`}
              className="d-inline-block mx-1 mb-3 position-relative"
            >
              <span
                className="position-absolute"
                style={{ left: "0.5rem", top: "-1rem" }}
              >
                {!isFirst && (
                  <CaretLeftFill
                    className="pointer"
                    onClick={() => moveAmountLeft(index)}
                  />
                )}
                {!isLast && (
                  <CaretRightFill
                    className="pointer"
                    onClick={() => moveAmountRight(index)}
                  />
                )}
              </span>
              <span
                className="float-end position-absolute"
                style={{ right: "0.5rem", top: "-1rem" }}
              >
                <XCircleFill
                  className="pointer"
                  onClick={() => removeAmount(index)}
                />
              </span>
              <Form.Control
                type="number"
                style={{ height: "auto" }}
                value={value}
                onChange={(e) => updateAmount(index, e.target.value)}
              />
            </div>
          );
        })}
      <Button className="align-top" onClick={addAmount}>
        <PlusCircle />
        &nbsp;Add Amount
      </Button>
    </div>
  );
};

export default FormPledgeAmounts;