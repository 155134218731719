import React, { useEffect, useState } from "react";
import axios from "axios";
import { Badge, Col, Container, Row, Table } from "react-bootstrap";
import MarketLineGraph from "./MarketLineGraph";
import MarketSearch from "./MarketSearch";
import MarketHeader from "../News/MarketHeader";
import MarketMoversCard from "./MarketMoversCard";
import MarketPlaceholder from "./MarketPlaceHolder";
import NewsAddFooter from "../News/NewsAdFooter";
import NewsSingleTopic from "../News/Section/NewsSingleTopic";

const Market = (props) => {
  const [symbol] = useState(props.symbol);
  const [time, setTime] = useState(new Date().getTime());
  const [json, setJSON] = useState(null);
  const [end, setEnd] = useState(null);
  const [start, setStart] = useState(null);
  const [diff, setDiff] = useState(null);

  const formatDate = (time) => {
    const date = new Date(time);

    return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const formatName = (name) => name.split(" ")[0];

  const formatNumber = (number) =>
    parseFloat(number)
      .toLocaleString()
      .replace(".00", "");

  const formatTime = (time) => {
    const date = new Date(time);

    return `${(date.getHours() - (date.getHours() > 12 ? 12 : 0))
      .toString()
      .padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${date.getHours() > 12 ? "PM" : "AM"}`;
  };

  useEffect(() => {
    if (symbol) {
      const getJSON = async () =>
        axios
          .get(`https://api.mobubby.com/Market?class=rt&symbol=${symbol}`)
          .then((response) => {
            const newJson = JSON.parse(response.data);
            const newEnd = parseFloat(newJson.values[0].close);
            const newStart = parseFloat(
              newJson.values[newJson.values.length - 1].previous_close
            );

            setJSON(newJson);
            setEnd(newEnd);
            setStart(newStart);
            setDiff(newEnd - newStart);

            setTimeout(() => setTime(new Date().getTime()), 60000);
          });

      getJSON();
    }
  }, [symbol, time]);

  if (!symbol)
    return (
      <Container className="market">
        <MarketHeader />
        <MarketMoversCard full className="mt-3" />
        <NewsSingleTopic section="Stocks" caption="Stock News" />
        <NewsAddFooter />
      </Container>
    );

  if (!!symbol && !json) return <MarketPlaceholder />;

  return (
    <Container className="market">
      <Row>
        <Col>{formatDate(json.values[0].datetime)}</Col>
      </Row>

      <Row>
        <Col md={10}>
          <span className="fs-1 align-middle d-none d-md-inline-block">
            {json.symbol.instrument_name}
          </span>
          <span className="fs-1 align-middle d-inline-block d-md-none">
            {formatName(json.symbol.instrument_name)}
          </span>
          <span className="fs-2 align-middle ms-3">{formatNumber(end)}</span>
          <Badge
            pill
            className="ms-3 align-middle float-end float-md-none"
            bg={diff >= 0 ? "success" : "danger"}
          >
            {formatNumber(diff)}
          </Badge>
        </Col>
        <Col>
          <MarketSearch className="py-3" />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div className="chartLarge">
            <MarketLineGraph
              values={json.values}
              xAxis
              yAxis
              radius={2}
              aspect={false}
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-3 data">
        <Col>
          <Table variant="dark" striped bordered hover responsive>
            <thead>
              <tr>
                <th>Time</th>
                <th>Value</th>
                <th>Delta</th>
                <th>Delta Open</th>
              </tr>
            </thead>
            <tbody>
              {json.values.map((value) => (
                <tr>
                  <td>{formatTime(value.datetime)}</td>
                  <td>{formatNumber(value.close)}</td>
                  <td
                    className={
                      value.close - value.previous_close >= 0
                        ? "text-info"
                        : "text-danger"
                    }
                  >
                    {formatNumber(value.close - value.previous_close)}
                  </td>
                  <td
                    className={
                      value.close - start >= 0 ? "text-info" : "text-danger"
                    }
                  >
                    {formatNumber(value.close - start)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col>
          <NewsSingleTopic section="Stocks" caption="Stock News" />
        </Col>
      </Row>
      <NewsAddFooter />
    </Container>
  );
};

export default Market;