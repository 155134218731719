import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Col, Row } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import FormPollResults from '../Form/FormPollResults';
import { Form, InputGroup } from 'react-bootstrap';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart'
        }
    }
};

function FormStats(props) {
    const [cookies] = useCookies(['user_id']);
    const [data, setData] = useState(null);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');

    const getData = useCallback(async () => {
        await axios
            .get(
                `https://api.ourfight.online/formStats/formId/${props.formId}/dateFrom/${dateFrom}"/dateTo/${dateTo}`
            )
            .then((response) => {
                setData({
                    fk_accountId: response.data.fk_accountId,
                    signatures: response.data.signatures,
                    unique: response.data.unique,
                    poll: response.data.poll,
                    pollAnswer: response.data.pollAnswer,
                    dates: response.data.dateList.sort(),
                    chart: {
                        labels: response.data.stats.map(
                            (day) => day.createdDateTimeDay
                        ),
                        datasets: [
                            {
                                label: 'Signature',
                                data: response.data.stats.map((day) =>
                                    parseInt(day.tally)
                                ),
                                backgroundColor: 'rgba(255, 99, 132, 0.5)'
                            },
                            {
                                label: 'Pledges',
                                data: response.data.stats.map((day) =>
                                    parseInt(day.tallyPledge)
                                ),
                                backgroundColor: 'rgba(53, 162, 235, 0.5)'
                            },
                            {
                                label: 'Dontations',
                                data: response.data.stats.map((day) =>
                                    parseInt(day.tallyDonation)
                                ),
                                backgroundColor: 'rgb(75, 192, 192)'
                            },
                            {
                                label: 'Pledge Amount',
                                data: response.data.stats.map((day) =>
                                    parseInt(day.pledgeAmount)
                                ),
                                backgroundColor: 'rgba(235, 162, 235, 0.5)'
                            },
                            {
                                label: 'Dontation Amount',
                                data: response.data.stats.map((day) =>
                                    parseInt(day.donationAmount)
                                ),
                                backgroundColor: 'rgb(75, 75, 192)'
                            }
                        ]
                    }
                });
            });
    }, [props.formId, dateFrom, dateTo]);

    useEffect(() => {
        getData();
    }, [getData]);

    if (!data || !data.fk_accountId) return null;
    else if (data.fk_accountId !== cookies.user_id) return <Navigate to="/" />;
    else
        return (
            <div>
                <Row className="my-3 align-items-center">
                    <Col xs={12} lg={6} className="text-muted">
                        Total of {data.signatures} signature(s) including{' '}
                        {data.unique} unique email(s)
                    </Col>
                    <Col xs={12} lg={6}>
                        <InputGroup>
                            <Form.Select
                                onChange={(e) => setDateFrom(e.target.value)}
                                value={dateFrom}
                            >
                                {data.dates.map((date, index) => (
                                    <option key={`date${index}-1`}>
                                        {date}
                                    </option>
                                ))}
                            </Form.Select>
                            <InputGroup.Text>-</InputGroup.Text>

                            <Form.Select
                                onChange={(e) => setDateTo(e.target.value)}
                                value={
                                    dateTo || data.dates[data.dates.length - 1]
                                }
                            >
                                {data.dates.map((date, index) => (
                                    <option key={`date${index}-1`}>
                                        {date}
                                    </option>
                                ))}
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
                <Bar className="mb-5" options={options} data={data.chart} />
                <FormPollResults
                    formId={props.formId}
                    poll={data.poll}
                    pollAnswer={data.pollAnswer}
                />
            </div>
        );
}

export default FormStats;
