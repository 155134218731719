import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import MarketCard from "../Market/MarketCard";
import MarketSearch from "../Market/MarketSearch";
import MarketMoversCard from "../Market/MarketMoversCard";

const MarketHeader = (props) => {
  const [height, setHeight] = useState(null);

  return (
    <Row className="mt-3">
      <Col md={3} xl={2} className="mb-3 mb-md-0">
        <MarketCard symbol="DJI2MN" setHeight={setHeight} />
      </Col>
      <Col md={3} xl={2} className="mb-3 mb-md-0">
        <MarketCard symbol="GSPC" alias="S\u0026P 500" setHeight={setHeight} />
      </Col>
      <Col md={3} xl={2} className="mb-3 mb-md-0">
        <MarketCard symbol="IXIC" alias="NASDAQ" setHeight={setHeight} />
      </Col>
      {props?.movers && (
        <Col md={3} xl={4} className="d-none d-xl-block mb-3 mb-md-0">
          <MarketMoversCard height={height} />
        </Col>
      )}
      <Col md={2}>
        <MarketSearch {...props} />
      </Col>
    </Row>
  );
};

export default MarketHeader;