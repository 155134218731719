import { useState } from "react";
import { Card, Form, InputGroup } from "react-bootstrap";

const InputTime = (props) => {
  const propsDate = props?.date ? new Date(props.date) : new Date();
  const [selectedHour, setSelectedHour] = useState(
    propsDate.getHours() > 12 ? propsDate.getHours() - 12 : propsDate.getHours()
  );
  const [selectedMinute, setSelectedMinute] = useState(propsDate.getMinutes());
  const [selectedPeriod, setSelectedPeriod] = useState(
    propsDate.getHours() >= 12 ? "PM" : "AM"
  );

  const minutes = [];
  for (var h = 0; h <= 59; h++) minutes.push(h);

  const updateHour = (hour) => {
    setSelectedHour(hour);
    if (props.onChange)
      props.onChange(`${hour}:${selectedMinute} ${selectedPeriod}`);
  };

  const updateMinute = (minute) => {
    setSelectedMinute(minute);
    if (props.onChange)
      props.onChange(`${selectedHour}:${minute} ${selectedPeriod}`);
  };

  const updatePeriod = (period) => {
    setSelectedPeriod(period);
    if (props.onChange)
      props.onChange(`${selectedHour}:${selectedMinute} ${period}`);
  };

  return (
    <div className={props.className}>
      <Card>
        {props.label && (
          <small className="text-secondary pl-2 pt-2">{props.label}</small>
        )}
        <Card.Body className="p-0">
          <InputGroup>
            <Form.Select
              className="border-0"
              value={selectedHour}
              onChange={(e) => updateHour(e.target.value)}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((hour, index) => (
                <option key={`hour-${index}`} value={hour}>
                  {hour}
                </option>
              ))}
            </Form.Select>
            &nbsp;<span className="mt-1">:</span>&nbsp;
            <Form.Select
              className="border-0"
              value={selectedMinute}
              onChange={(e) => updateMinute(e.target.value)}
            >
              {minutes.map((minute, index) => (
                <option key={`day-${index}`} value={minute}>
                  {minute}
                </option>
              ))}
            </Form.Select>
            &nbsp;<span className="mt-1">:</span>&nbsp;
            <Form.Select
              className="border-0"
              value={selectedPeriod}
              onChange={(e) => updatePeriod(e.target.value)}
            >
              {["AM", "PM"].map((period, index) => (
                <option key={`period-${index}`} value={period}>
                  {period}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        </Card.Body>
      </Card>
      {props.label && <Form.Text>{props.instructions}</Form.Text>}
    </div>
  );
};

export default InputTime;