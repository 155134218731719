import React, { useEffect, useState } from "react";
import axios from "axios";
import { Badge, Card, Col, Row, Table } from "react-bootstrap";
import MarketPlaceholder from "./MarketPlaceHolder";

const MarketMoversCard = (props) => {
  const [time, setTime] = useState(new Date().getTime());
  const [json, setJSON] = useState(null);

  const formatNumber = (number) =>
    parseFloat(number)
      .toLocaleString()
      .replace(".00", "");

  useEffect(() => {
    const getJSON = async () =>
      axios
        .get(`https://api.mobubby.com/Market?class=mover`)
        .then((response) => {
          const newJson = JSON.parse(response.data);

          setJSON(newJson);

          setTimeout(() => setTime(new Date().getTime()), 60000);
        });

    getJSON();
  }, [time]);

  if (!json) return <MarketPlaceholder />;

  if (props?.full)
    return (
      <Table
        className={`market ${props.className}`}
        striped
        bordered
        hover
        responsive
      >
        <thead>
          <th>Symbol</th>
          <th>Name</th>
          <th>High</th>
          <th>Low</th>
          <th>Value</th>
        </thead>

        <tbody>
          {json.values.map((symbol) => (
            <tr>
              <td>
                <a
                  key={symbol.symbol}
                  className="market"
                  href={`/market?symbol=${symbol.symbol}`}
                >
                  {symbol.symbol}
                </a>
              </td>
              <td>
                <a
                  key={symbol.symbol}
                  className="market"
                  href={`/market?symbol=${symbol.symbol}`}
                >
                  {symbol.name}
                </a>
              </td>
              <td>
                <a
                  key={symbol.symbol}
                  className="market"
                  href={`/market?symbol=${symbol.symbol}`}
                >
                  {formatNumber(symbol.high)}
                </a>
              </td>
              <td>
                <a
                  key={symbol.symbol}
                  className="market"
                  href={`/market?symbol=${symbol.symbol}`}
                >
                  {formatNumber(symbol.low)}
                </a>
              </td>
              <td>
                <a
                  key={symbol.symbol}
                  className="market"
                  href={`/market?symbol=${symbol.symbol}`}
                >
                  {formatNumber(symbol.last)}
                  <Badge
                    pill
                    bg={symbol.change >= 0 ? "success" : "danger"}
                    className="float-end"
                  >
                    {formatNumber(symbol.change)}
                  </Badge>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

  return (
    <Card className={`market ${props.className}`}>
      <Card.Body
        className="movers w-100 p-0"
        style={{ height: `${props.height || 50}px` }}
      >
        {json.values.map((symbol) => (
          <a
            key={symbol.symbol}
            className="market"
            href={`/market?symbol=${symbol.symbol}`}
          >
            <Row className="p-1 border-bottom bg-dark text-light">
              <Col xs={3}>
                <strong>{symbol.symbol}</strong>&nbsp;
              </Col>
              <Col>
                {formatNumber(symbol.last)}&nbsp;
                <Badge
                  pill
                  bg={symbol.change >= 0 ? "success" : "danger"}
                  className="float-end"
                >
                  {formatNumber(symbol.change)}
                </Badge>
              </Col>
            </Row>
          </a>
        ))}
      </Card.Body>
    </Card>
  );
};

export default MarketMoversCard;