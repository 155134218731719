import React from "react";
import { Carousel } from "react-bootstrap";
import FormPollQuestion from "./FormPollQuestion";

function FormPollSlider(props) {
  const questions = props.poll.replaceAll("\r", "").split("\n\n");
  return (
    <div>
      {props.poll && (
        <Carousel
          className="pollCarousel"
          variant="dark"
          interval={null}
          controls={questions.length > 1}
          indicators={questions.length > 1}
        >
          {props.poll &&
            props.poll
              .replaceAll("\r", "")
              .split("\n\n")
              .map((question, index) => (
                <Carousel.Item key={"pollQuestion" + index}>
                  <FormPollQuestion
                    pollSlider={true}
                    formData={props.formData}
                    setFormData={(p) => {
                      props.setFormData(p);
                    }}
                    question={question}
                  ></FormPollQuestion>
                </Carousel.Item>
              ))}
        </Carousel>
      )}
    </div>
  );
}

export default FormPollSlider;