import { useState } from "react";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { CaretRightFill, Search } from "react-bootstrap-icons";

const MarketSearch = (props) => {
  const [symbol, setSymbol] = useState(null);

  return (
    <Form className={props?.className} method="GET" action="/market">
      <InputGroup>
        <FormControl
          placeholder="symbol"
          name="symbol"
          id="symbol"
          onChange={(e) => setSymbol(e.target.value)}
        />
        <Button type="submit" disabled={!symbol}>
          <Search />
        </Button>
      </InputGroup>
      {props?.link && (
        <Button
          variant="link"
          className="w-100 text-end text-decoration-none"
          href="/market"
        >
          Market <CaretRightFill />
        </Button>
      )}
    </Form>
  );
};

export default MarketSearch;