import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionArrayLargeCenter from "./Section/SectionArrayLargeCenter";
import SectionArrayLargeLeft from "./Section/SectionArrayLargeLeft";
import SectionArrayLargeRight from "./Section/SectionArrayLargeRight";
import { Col, Nav, Row } from "react-bootstrap";
import Toasts from "../Toasts";
import NewsLatestPetitions from "./NewsLatestPetitions";
import NewsVideos from "./NewsVideos";
import NewsIndex from "./NewsIndex";
import NewsThanks from "./NewsThanks/NewsThanks";
import { Helmet } from "react-helmet";

const NewsHome = (props) => {
  const [json, setJSON] = useState(null);
  const [jsonKeys, setJSONKeys] = useState(null);
  const [votes, setVotes] = useState(null);
  const [dialog, setDialog] = useState(null);

  const hideDialog = () => setDialog(null);

  const toast = (message) => {
    setDialog(message);
  };

  useEffect(() => {
    const getJSON = async () =>
      axios
        .get("https://api.mobubby.com/data?file=_overview")
        .then((response) => {
          const newJSON = JSON.parse(response.data.content);
          const newJSONKeys = Object.keys(newJSON).sort();
          const indexTrending = newJSONKeys.indexOf("Trending");
          newJSONKeys.splice(indexTrending, 1);
          newJSONKeys.unshift("Trending");

          setJSON(newJSON);
          setJSONKeys(newJSONKeys);
          setVotes(response.data.votes);
        });

    getJSON();
  }, []);

  if (!json || !jsonKeys) return null;
  else
    return (
      <>
        <Row>
          <Col>
            <Helmet>
              {/*prettier-ignore*/}
              <script type="text/javascript" src="//classic.avantlink.com/ppb.php?ppbid=7857&amp;ctc=&amp;open="></script>
            </Helmet>
          </Col>
        </Row>

        <Toasts
          show={!!dialog}
          title="Vote"
          message={dialog}
          onClick={() => hideDialog()}
        />

        <Row>
          <Col className="text-end">
            <Nav className="justify-content-end">
              <Nav.Item>
                <Nav.Link href="/market">Market</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/sports">Sports</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="news">
              <NewsIndex jsonKeys={jsonKeys} />
              {props.thanks && <NewsThanks {...props} />}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="news">
              <div className="mt-3">
                {jsonKeys?.map((section, index) => {
                  const articles = json[section]?.[0]
                    ?.map((article) => {
                      return {
                        ...article,
                        url: `https://ourfight.online/news/link/${article.id}`,
                      };
                    })
                    .reduce((a, b) => [...a, b], []);

                  return !articles ? null : (
                    <div key={section}>
                      {index % 3 === 0 && (
                        <SectionArrayLargeCenter
                          section={section}
                          articles={articles}
                          showMore={true}
                          votes={votes}
                          toast={toast}
                          {...props}
                        />
                      )}
                      {index % 3 !== 0 && index % 2 === 0 && (
                        <SectionArrayLargeLeft
                          section={section}
                          articles={articles}
                          showMore={true}
                          votes={votes}
                          toast={toast}
                          {...props}
                        />
                      )}
                      {index % 3 !== 0 && index % 2 !== 0 && (
                        <SectionArrayLargeRight
                          section={section}
                          articles={articles}
                          showMore={true}
                          votes={votes}
                          toast={toast}
                          {...props}
                        />
                      )}
                      {index === 0 && <NewsLatestPetitions />}
                      {index === 3 && <NewsVideos header="Watch" />}
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
};

export default NewsHome;