import React from "react";
import { Card, Col, Row } from "react-bootstrap";

function Help(props) {
  const rumbleVideo = (id, title) => (
    <Card className="my-3">
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className="ratio ratio-16x9">
          <iframe
            title={title}
            className="rumble"
            width="100%"
            height="100%"
            src={`https://rumble.com/embed/${id}/?pub=107jef`}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <>
      <Card className="my-3">
        <Card.Body>
          <Card.Title className="display-6">
            Need help? Have questions, comments, or suggestions?
          </Card.Title>
          <a
            className="display-6 m3-3 text-decoration-none"
            href="mailto:help@ourfight.online"
          >
            Click here to send an email to help@ourfight.online
          </a>
        </Card.Body>
      </Card>
      <Row>
        <Col>{rumbleVideo("vvm9y7", "Create a basic petition")}</Col>
        <Col>{rumbleVideo("vvmct7", "Add a poll to a petition")}</Col>
        <Col>{rumbleVideo("vvmdmd", "Make a petition private")}</Col>
      </Row>
      <Row>
        <Col>{rumbleVideo("vvmi69", "Show your other petitions")}</Col>
        <Col>{rumbleVideo("vvmkkd", "Redirect user after signing")}</Col>
        <Col>{rumbleVideo("vvpx7p", "Create integration from scratch")}</Col>
      </Row>
      <Row>
        <Col>{rumbleVideo("vvpvxd", "Create integration using a guide")}</Col>
        <Col>{rumbleVideo("vvq1qr", "Set petition's email integration")}</Col>
        <Col>
          {rumbleVideo("vvtijl", "Set petition's merchant integration")}
        </Col>
      </Row>
      <Row>
        <Col>{rumbleVideo("vvtt08", "View petition stats")}</Col>
        <Col>{rumbleVideo("vw7w0f", "View petition's raw data")}</Col>
        <Col>{rumbleVideo("vw8218", "Export petition data to CSV")}</Col>
      </Row>
      <Row>
        <Col>{rumbleVideo("vvu1vy", "Embed petition")}</Col>
        <Col>{rumbleVideo("vw850z", "Generate PDF of signed petitions")}</Col>
        <Col>
          {rumbleVideo("vwb2re", "Update profile email and/or password")}
        </Col>
      </Row>
    </>
  );
}

export default Help;
