import React from "react";
import { Col, Placeholder, Row } from "react-bootstrap";

export const FormLoad = () => {
  return (
    <>
      <Row className="mt-5">
        <Col xs={12}>
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} className="py-5" />
          </Placeholder>

          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} className="py-1" />
          </Placeholder>
        </Col>
      </Row>

      <Row>
        <Col xs={8}>
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} className="py-5" />
          </Placeholder>

          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} className="py-3" />
          </Placeholder>

          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} className="py-3" />
          </Placeholder>

          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} className="py-4" />
          </Placeholder>
        </Col>

        <Col xs={4}>
          <Row>
            <Col>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} className="py-4" />
              </Placeholder>
            </Col>
            <Col>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} className="py-4" />
              </Placeholder>
            </Col>
          </Row>
          <Row>
            <Col>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} className="py-4" />
              </Placeholder>
            </Col>
          </Row>
          <Row>
            <Col>
              <Placeholder as="p" animation="glow">
                <Placeholder xs={12} className="py-4" />
              </Placeholder>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};