import React, { useCallback, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Steps from "../Steps";
import { Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import Dialog from "../Doalog";
import FormImage from "../Form/FormImage";
import FormPoll from "./FormPoll";
import FormLegalese from "../Form/FormLegalese";
import ReactParseHTML from "react-html-parser";
import { toBoolean } from "../Shared";
import FormPledgeAmounts from "./FormPledgeAmounts";
import ReactDatePicker from "react-datepicker";

const formatDate = (value) => {
  const date = new Date(value);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;
};

const StepTopicAndTitle = (props) => {
  return (
    <div>
      <Form.Group className="mb-3" controlId="fk_topicId">
        <Form.Label>Topic</Form.Label>
        <Form.Select
          value={props.data.fk_topicId || undefined}
          onChange={(event) => {
            props.setData({ ...props.data, fk_topicId: event.target.value });
          }}
        >
          <option value="">Select a topic...</option>
          <option value="1a0b92b3-0025-11eb-ae7c-002590aaf58e">
            Constitution
          </option>
          <option value="b4806283-c7dc-11ec-ab0f-002590aaf58e">
            Current Events
          </option>
          <option value="4730f42d-60ae-11ea-a991-002590aaf58e">
            Education
          </option>
          <option value="afdcaace-fa8b-11ea-b718-002590aaf58e">Election</option>
          <option value="612c9a0a-01dc-11eb-ae7c-002590aaf58e">
            Government Spending
          </option>
          <option value="4730f562-60ae-11ea-a991-002590aaf58e">Guns</option>
          <option value="9ab05fba-fa92-11ea-b718-002590aaf58e">Health</option>
          <option value="4730f5be-60ae-11ea-a991-002590aaf58e">
            Immigration
          </option>
          <option value="4dbbaf9a-0025-11eb-ae7c-002590aaf58e">Other</option>
          <option value="4730f611-60ae-11ea-a991-002590aaf58e">Pro-Life</option>
          <option value="4eab5997-893c-4d7e-896e-5aa739309cfe">
            Public Corruption
          </option>
          <option value="4730f654-60ae-11ea-a991-002590aaf58e">Religion</option>
          <option value="4730f696-60ae-11ea-a991-002590aaf58e">Taxes</option>
        </Form.Select>
        <Form.Text>Select the topic that best describes your fight.</Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="title">
        <Form.Label>Title</Form.Label>
        <Form.Control
          value={props.data.title || ""}
          onChange={(event) => {
            props.setData({ ...props.data, title: event.target.value });
          }}
        />
        <Form.Text>Enter a captivating title that engages viewers.</Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="alias">
        <Form.Label>URL Alias</Form.Label>
        <Form.Control
          value={props.data.alias || ""}
          onChange={(event) => {
            props.setData({
              ...props.data,
              alias: (event.target.value || "").trim(),
            });
          }}
        />
        <Form.Text>
          Enter an alias (without spaces) that can be used within the URL to
          identify this petition. If no alias is provided, the petition's unique
          ID will be used. For instance, if your petition is about the rights of
          the unborn, your alias may be "SaveTheUnborn" and your petition's URL
          would then be {`"${window.location.origin}/SaveTheUnborn`}.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="footer">
        <Form.Label>Organization / Agency</Form.Label>
        <Form.Control
          className="mb-3"
          placeholder="Organization / Agency Name"
          value={props.data.footerText || ""}
          onChange={(event) => {
            props.setData({ ...props.data, footerText: event.target.value });
          }}
        />
        <Form.Control
          placeholder="Organization / Agency URL"
          value={props.data.footerURL || ""}
          onChange={(event) => {
            props.setData({ ...props.data, footerURL: event.target.value });
          }}
        />
        <Form.Text>
          Optionally, enter an organization/agency name and URL. If provided, a
          link (displaying the given name and directed to the given URL) will
          display below the petition title.
        </Form.Text>
      </Form.Group>
    </div>
  );
};

const StepImageAndText = (props) => {
  return (
    <div>
      <Form.Group className="mb-3" controlId="upload">
        <Form.Label>Header</Form.Label>
        <Form.Control
          type="file"
          onChange={(event) => {
            props.setData({ ...props.data, upload: event.target.files[0] });
          }}
        />
        <Form.Text>
          Upload a header image that visually portrays your fight and captivates
          viewers. &nbsp;
          <strong>
            The aspect ratio should be 3:1. Any other aspect ratio may not
            display properly. Petitions with an image width less than 800 will
            not display on the home page slider.
          </strong>
        </Form.Text>
        {props.data.image && (
          <div className="mt-3">
            <FormImage
              src={"https://static.ourfight.online/upload/" + props.data.image}
              reduce={true}
              expand={true}
            />
          </div>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="content">
        <Form.Label className="mb-0">Content</Form.Label>
        <Form.Text className="mb-2">
          Explain your fight so that readers are enticed and convinced to join.
        </Form.Text>
        <Editor
          tinymceScriptSrc="https://static.ourfight.online/tinymce/js/tinymce/tinymce.min.js"
          value={ReactDOMServer.renderToString(
            ReactParseHTML(props.data.content || "")
          )}
          onEditorChange={(newValue) => {
            props.setData({
              ...props.data,
              content: newValue,
            });
          }}
          init={{
            height: 500,
            branding: false,
            menubar: false,
            plugins: "link,lists",
            toolbar:
              "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat link",
          }}
        />
      </Form.Group>
    </div>
  );
};

const StepPolls = (props) => {
  return (
    <div>
      <Form.Group className="mb-3" controlId="fk_topicId">
        <Form.Label className="mb-0">Polls</Form.Label>
        <FormPoll
          poll={props.data.polls}
          setPoll={(p) => {
            props.setData({ ...props.data, polls: p });
          }}
        />
        <Form.Text>
          Optionally, create polls you would like users to complete. Users will
          still be able to submit the form without completing the poll.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="fk_topicId">
        <Form.Label className="mb-0">Email Subject Template</Form.Label>
        <Form.Text>
          Optionally, provide an email subject template. If provided, after
          signing the petition, users will have a chance to send an email to
          their officials. You can include the tags listed{" "}
          <a href="#tags" className="text-decoration-none">
            <strong>here</strong>
          </a>{" "}
          to have the associated data dynamically inserted.
        </Form.Text>
        <Form.Control
          as="input"
          placeholder="Requesting the attention of {officialTitle},"
          value={props.data.emailSubject || ""}
          onChange={(event) => {
            props.setData({ ...props.data, emailSubject: event.target.value });
          }}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="fk_topicId">
        <Form.Label className="mb-0">Email Body Template</Form.Label>
        <Form.Text>
          Optionally, provide an email body template. If provided, after signing
          the petition, users will have a chance to send an email to their
          officials. You can include the tags listed{" "}
          <a href="#tags" className="text-decoration-none">
            <strong>here</strong>
          </a>{" "}
          to have the associated data dynamically inserted.
        </Form.Text>
        <Form.Control
          as="textarea"
          placeholder={`Dear {officialName},\r\rI am {firstName} {lastInitial} of {zip}. I have the following concerns that I would like addressed...`}
          rows="5"
          value={props.data.emailBody || ""}
          onChange={(event) => {
            props.setData({ ...props.data, emailBody: event.target.value });
          }}
        />
      </Form.Group>

      <Table id="tags" responsive striped hover>
        <thead>
          <tr>
            <th>Tag</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{"{email}"} </td>
            <td>The user-given email address.</td>
          </tr>
          <tr>
            <td>{"{firstName}"} </td>
            <td>The user-given first name.</td>
          </tr>
          <tr>
            <td>{"{lastInitial}"} </td>
            <td>The initial of the user-given last name.</td>
          </tr>
          <tr>
            <td>{"{officialName}"} </td>
            <td>The name of the selected official.</td>
          </tr>
          <tr>
            <td>{"{officialTitle}"} </td>
            <td>The position title of the selected official.</td>
          </tr>
          <tr>
            <td>{"{zip}"} </td>
            <td>The user-given zip.</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const StepAdvanced = (props) => {
  return (
    <div>
      <h5 className="mb-4">Display</h5>
      <Row>
        <Col md="3">
          <Form.Group className="mb-3" controlId="private">
            <Form.Check
              type="checkbox"
              label="Private"
              checked={toBoolean(props.data.private)}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  private: event.target.checked ? 1 : 0,
                });
              }}
            />
            <Form.Text>
              Check if you don't want this petition shown on the home page or
              the "Latest Petitions" section of the community.
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md="3">
          <Form.Group className="mb-3" controlId="showPetitions">
            <Form.Check
              type="checkbox"
              label="Show Petitions"
              checked={toBoolean(props.data.showPetitions)}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  showPetitions: event.target.checked ? 1 : 0,
                });
              }}
            />
            <Form.Text>
              Check if you want to display any of your other petitions on the
              "Thank you" page.
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md="3">
          <Form.Group className="mb-3" controlId="archivedDateTime">
            <Form.Check
              type="checkbox"
              label="Show Officials"
              checked={toBoolean(props.data.showReps)}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  showReps: event.target.checked ? 1 : 0,
                });
              }}
            />
            <Form.Text>
              Check if you want to display local, state, &amp; federal officials
              on the "Thank you" page.
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md="3">
          <Form.Group className="mb-3" controlId="archivedDateTime">
            <Form.Check
              type="checkbox"
              label="Archive"
              checked={toBoolean(props.data.archivedDateTime)}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  archivedDateTime: event.target.checked
                    ? new Date()
                        .toISOString()
                        .slice(0, 19)
                        .replace("T", " ")
                    : "",
                });
              }}
            />
            <Form.Text>
              Check if you want to archive this petition. Petitions that are
              archived will no longer garner emails or donations.{" "}
              <b>
                Petitions that are currently prioritized via a signer will not
                be archived until prioritization expires.
              </b>
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <Form.Group className="mb-3" controlId="startEndDates">
            <Form.Check
              type="checkbox"
              label="Start/End Dates"
              checked={toBoolean(props.data.startEndDates)}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  startEndDates: event.target.checked ? 1 : 0,
                  startDate: "",
                  endDate: "",
                });
              }}
            />
            <Form.Text>
              Check if you want to set start end dates for you petition. If set,
              petition will appear deactivated when viewing before the start
              date or after the end date.{" "}
              <b>
                Petitions that are currently prioritized via a signer will not
                be deactivated until prioritization expires.
              </b>
            </Form.Text>
          </Form.Group>
        </Col>
        {toBoolean(props.data.startEndDates) && (
          <Col md="3">
            <Form.Group className="mb-3" controlId="startDate">
              <ReactDatePicker
                label="Start Date"
                value={props.data.startDate}
                onChange={(value) => {
                  props.setData({
                    ...props.data,
                    startDate: formatDate(value),
                  });
                }}
              />
              <Form.Text>
                Select a start date. Petition will appear deactivated when
                viewing before this date.
              </Form.Text>
            </Form.Group>
          </Col>
        )}
        {toBoolean(props.data.startEndDates) && (
          <Col md="3">
            <Form.Group className="mb-3" controlId="endDate">
              <ReactDatePicker
                label="End Date"
                value={props.data.endDate}
                onChange={(value) => {
                  props.setData({
                    ...props.data,
                    endDate: formatDate(value),
                  });
                }}
              />
              <Form.Text>
                Select an end date. Petition will appear deactivated when
                viewing after this date.
              </Form.Text>
            </Form.Group>
          </Col>
        )}
        <Col md="3">
          <Form.Group className="mb-3" controlId="hideProgressBar">
            <Form.Check
              type="checkbox"
              label="Hide Progress Bar"
              checked={toBoolean(props.data.hideProgressBar)}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  hideProgressBar: event.target.checked ? 1 : 0,
                });
              }}
            />
            <Form.Text>
              Check if you don't want to display a progress bar. If not checked,
              the progress bar will display once this petition acquires 25
              signatures.
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md="3">
          <Form.Group className="mb-3" controlId="disableComments">
            <Form.Check
              type="checkbox"
              label="Disable Comments"
              checked={toBoolean(props.data.disableComments)}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  disableComments: event.target.checked ? 1 : 0,
                });
              }}
            />
            <Form.Text>Check if you would like to disable comments.</Form.Text>
          </Form.Group>
        </Col>
        <Col md="3">
          <Form.Group className="mb-3" controlId="inputType">
            <Form.Select
              value={props.data.inputType ?? 0}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  inputType: event.target.value,
                });
              }}
            >
              <option value="0">Name, Zip, Email</option>
              <option value="1">Email</option>
            </Form.Select>
            <Form.Text>Select the required fields.</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <h5 className="my-4">Redirect</h5>
        <Col md="6">
          <Form.Group className="mb-3" controlId="relayURL">
            <Form.Label>Relay URL</Form.Label>
            <Form.Control
              value={props.data.relayURL || ""}
              onChange={(event) => {
                props.setData({ ...props.data, relayURL: event.target.value });
              }}
            />
            <Form.Text>
              Optionally, enter a URL the user is redirected to after signing.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <h5 className="my-4">Pledges</h5>
        <Col>
          <Form.Group className="mb-3" controlId="relayURL">
            <Form.Label>Pledge Amounts</Form.Label>
            <FormPledgeAmounts
              pledgeAmounts={props.data.pledgeAmounts || ""}
              data={props.data}
              setData={props.setData}
            />
            <Form.Text>Optionally, enter the default pledge amounts.</Form.Text>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="content">
            <Form.Label className="mb-0">Pledge Content</Form.Label>
            <Form.Text className="mb-2">
              Convince your signers to financially support the fight.
            </Form.Text>
            <Editor
              tinymceScriptSrc="https://static.ourfight.online/tinymce/js/tinymce/tinymce.min.js"
              value={ReactDOMServer.renderToString(
                ReactParseHTML(props.data.contentPledge || "")
              )}
              onEditorChange={(newValue) => {
                props.setData({
                  ...props.data,
                  contentPledge: newValue,
                });
              }}
              init={{
                height: 500,
                branding: false,
                menubar: false,
                plugins: "link,lists",
                toolbar:
                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat link",
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <h5 className="my-4">3rd-Party Integrations</h5>
        <Col md="6">
          <Form.Group className="mb-3" controlId="fk_topicId">
            <Form.Label>Email Integration</Form.Label>
            <Form.Select
              value={props.data.emailIntegration || undefined}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  emailIntegration: event.target.value,
                });
              }}
            >
              <option key="key_" value="">
                Select email integration
              </option>
              {(props.integration || []).map((integration, index) =>
                integration.class === "email" ? (
                  <option key={"key" + index} value={integration.id}>
                    {integration.name}
                  </option>
                ) : null
              )}
            </Form.Select>
            <Form.Text>
              Optionally, select the 3rd-party system your garnered emails will
              be sent to.
            </Form.Text>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group className="mb-3" controlId="fk_topicId">
            <Form.Label>Merchant Integration</Form.Label>
            <Form.Select
              value={props.data.merchantIntegration || undefined}
              onChange={(event) => {
                props.setData({
                  ...props.data,
                  merchantIntegration: event.target.value,
                });
              }}
            >
              <option key="key_" value="">
                Select merchant integration
              </option>
              {(props.integration || []).map((integration, index) =>
                integration.class === "merchant" ? (
                  <option key={"key" + index} value={integration.id}>
                    {integration.name}
                  </option>
                ) : null
              )}
            </Form.Select>
            <Form.Text>
              Optionally, select the 3rd-party that will collect your payments.
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

const StepAccount = (props) => {
  return (
    <div>
      <Row className="mb-3">
        <Col xs="12" md="6" className="mb-3 mb-md-0">
          <Form.Control
            value={props.data.email || ""}
            placeholder="email"
            name="email"
            id="email"
            onChange={(e) => {
              props.setData({ ...props.data, email: e.target.value });
            }}
          />
        </Col>
        <Col xs="12" md="6">
          <Form.Control
            value={props.data.password || ""}
            placeholder="password"
            name="password"
            id="password"
            type="password"
            onChange={(e) => {
              props.setData({ ...props.data, password: e.target.value });
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs="12" md="10" className="mb-3 mb-md-0">
          <FormLegalese label="Save" />
        </Col>
      </Row>
    </div>
  );
};

function FormEdit(props) {
  const [cookies, setCookie] = useCookies(["user_id"]);
  const [dialogue, setDialogue] = useState(null);
  const [dialogueMessage, setDialogueMessage] = useState(null);
  const [dialogueSaving, setDialogueSaving] = useState(false);
  const [data, setData] = useState({});
  const [integration, setIntegration] = useState(null);

  const hideDialogue = (e) => {
    setDialogue(false);
    if (
      dialogueSaving &&
      !window.location.search.substring(1).includes("id=")
    ) {
      window.open(`/${data.id}`, "_blank");
      window.location = `/formEdit?id=${data.id}`;
    }
  };

  const showDialogue = (message, saving) => {
    setDialogue(true);
    setDialogueMessage(message);
    setDialogueSaving(saving || false);
  };

  const showSaveDialog = (id) => {
    showDialogue(
      <div>
        Save successful!
        <a
          className="mt-3 w-100 btn btn-primary"
          href={`/${id}`}
          rel="noreferrer"
          target="_blank"
        >
          View Petition
        </a>
      </div>,
      true
    );
  };

  const submit = async (data) => {
    if (!data.fk_topicId) showDialogue("Please select a a topic");
    else if (!data.title || data.title?.length < 1 || data.title?.length > 100)
      showDialogue("Please enter a title between 1 and 100 characters");
    else if (!data.image && !data.upload)
      showDialogue("Please select a header image");
    else if (!data.content) showDialogue("Please enter content");
    else if (
      data.footerURL &&
      (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(
        data.footerURL
      ) ||
        data.footerURL?.length > 255)
    )
      showDialogue(
        "Please enter a valid footer URL between 1 and 255 characters."
      );
    else if (
      data.relayURL &&
      (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(
        data.relayURL
      ) ||
        data.relayURL?.length > 255)
    )
      showDialogue(
        "Please enter a valid relay URL between 1 and 255 characters."
      );
    else if (
      !cookies.user_id &&
      (!/^[^@\s]+@[^.]+\.\w{2,}$/g.test(data.email) ||
        data.email.length < 1 ||
        data.email.length > 64)
    )
      showDialogue(
        "Please enter a valid email address between 1 and 64 characters"
      );
    else if (
      !cookies.user_id &&
      (!data.password || data.password.length < 8 || data.password.length > 32)
    )
      showDialogue("Please enter a password between 8 and 32 characters");
    else if (data.emailSubject && !data.emailBody) {
      showDialogue(
        "Because you provided an email subject template, you are required to provied an email body template"
      );
    } else if (data.emailBody && !data.emailSubject) {
      showDialogue(
        "Because you provided an email body template, you are required to provied an email subject template"
      );
    } else if (data.startEndDates && !data.startDate) {
      showDialogue(
        "Because you enabled start/end dates, you are required to proved a start date"
      );
    } else if (data.startEndDates && !data.endDate) {
      showDialogue(
        "Because you enabled start/end dates, you are required to proved an end date"
      );
    } else if (
      data.startEndDates &&
      Date.parse(data.endDate) < Date.parse(data.startDate)
    ) {
      showDialogue("End date cannot be earlier than start date");
    } else if (data.merchantIntegration && !data.pledgeAmounts)
      showDialogue(
        "Because you selected a merchant integraion you are required to enter at least one default pledge amount"
      );
    else if (data.merchantIntegration && !data.contentPledge)
      showDialogue(
        "Because you selected a payment integraion you are required to provide pledge content"
      );
    else if (data.pledgeAmounts && !data.merchantIntegration)
      showDialogue(
        "Because you specified at least one default pledge amount you are required to select a payment integraion"
      );
    else if (data.pledgeAmounts && !data.contentPledge)
      showDialogue(
        "Because you specified at least one default pledge amount you are required to provide pledge content"
      );
    else if (data.contentPledge && !data.merchantIntegration)
      showDialogue(
        "Because you provided pledge content you are required to select a payment integraion"
      );
    else if (data.contentPledge && !data.pledgeAmounts)
      showDialogue(
        "Because you provided pledge content you are required to enter at least one default pledge amount"
      );
    else if (data.pledgeAmounts && !/^[0-9. ]+$/g.test(data.pledgeAmounts))
      showDialogue(
        "Pledge amounts can only contain the numbers 0 through 9, periods, and spaces. No other characters are permitted"
      );
    else if (
      data.pledgeAmounts &&
      (data.pledgeAmounts.length < 1 || data.pledgeAmounts.length > 255)
    )
      showDialogue("Please enter pledge amounts between 1 and 255 characters");
    else if (data.relayURL && data.pledgeAmounts)
      showDialogue("You cannot enter both pledge information and a relay URL.");
    else {
      const formData = new FormData();

      showDialogue(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );

      formData.append("fk_accountId", props.userId);

      data.polls = (data.polls || "").replace(/ \n/g, "");
      data.pledgeAmounts = (data.pledgeAmounts || "").replace(
        /^0 | 0 | 0$/g,
        ""
      );
      for (var key in data) formData.append(key, data[key]);

      await axios
        .post("https://api.ourfight.online/formEdit", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.error) {
            showDialogue(response.data.error);
          } else {
            setCookie("user_id", response.data.form.fk_accountId);
            setData({
              ...response.data.form,
              archivedDateTime: response.data.form.archivedDateTime || "",
              startDate: response.data.form.startDate || "",
              endDate: response.data.form.endDate || "",
              upload: null,
            });
            showSaveDialog(response.data.form.id);
          }
        });
    }
  };

  const getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourfight.online/formAndRelated/userId/${props.userId}/id/${
          props.id
        }/template/${props.template}`
      )
      .then((response) => {
        setData({
          ...response.data.form,
          archivedDateTime: response.data.form?.archivedDateTime || "",
          upload: null,
          alias: response.data.form?.alias || "",
          emailSubject: response.data.form?.emailSubject || "",
          emailBody: response.data.form?.emailBody || "",
          startDate: response.data.form?.startDate || "",
          endDate: response.data.form?.endDate || "",
        });
        setIntegration(response.data.integration);
      });
  }, [props.userId, props.id, props.template]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data || (data.id && !data.fk_accountId)) return null;
  else if (data.id && data.fk_accountId !== cookies.user_id)
    return <Navigate to="/" />;
  else
    return (
      <div>
        <Dialog
          show={dialogue}
          onClick={hideDialogue}
          message={dialogueMessage}
          title={(data.id ? "Edit" : "Add") + " Petition"}
        />
        <Steps onSubmit={() => submit(data)} submitLabel="Save">
          <StepTopicAndTitle
            title="Topic, Title & Organization"
            setData={(p) => {
              setData(p);
            }}
            data={data}
          />
          <StepImageAndText
            title="Image & Text"
            setData={(p) => {
              setData(p);
            }}
            data={data}
          />
          <StepPolls
            title="Polls &amp; Email"
            setData={(p) => {
              setData(p);
            }}
            data={data}
          />
          <StepAdvanced
            title="Advanced"
            setData={(p) => {
              setData(p);
            }}
            integration={integration}
            data={data}
          />
          {!cookies.user_id && (
            <StepAccount
              title="Account"
              setData={(p) => {
                setData(p);
              }}
              data={data}
            />
          )}
        </Steps>
      </div>
    );
}

export default FormEdit;