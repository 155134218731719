import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Button, Card, Col, Form, Modal, Navbar, Row } from "react-bootstrap";
import FormFields from "./FormFields";
import FormFooter from "./FormFooter";
import FormImage from "./FormImage";
import FormProgress from "./FormProgress";
import FormShare from "./FormShare";
import FormText from "./FormText";
import FormTitle from "./FormTitle";
import FormThankYou from "./FormThankYou";
import FormPledge from "./FormPledge";
import FormEmailTemplate from "./FormEmailTemplate";
import { toBoolean } from "../Shared";
import FormOwnerOptions from "./FormOwnerOptions";
import textFilter from "../textFilter";
import { ArrowDownCircle, FlagFill } from "react-bootstrap-icons";
import TooltipOverlay from "../TooltipOverlay";
import { FormLoad } from "./FormLoad";

function FormView(props) {
  const [cookies] = useCookies(["user_id", "tier"]);
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);
  const [pollAnswer, setPollAnswer] = useState(null);
  const [interactionId, setInteractionId] = useState(null);
  const [otherPetitions, setOtherPetitions] = useState(null);
  const [showSignModal, setShowSignModal] = useState(false);
  const [commentStart, setCommentStart] = useState(0);
  const [promoteForm, setPromoteForm] = useState(false);
  const [signerState, setSignerState] = useState(null);
  const [formData, setFormData] = useState({
    firstName: props?.firstName,
    lastName: props?.lastName,
    zip: props?.zip,
    email: props?.email,
    pollQuestions: {},
  });

  const flagComment = (interactionId) => {
    try {
      axios
        .get(
          `https://api.ourfight.online/formCommentFlag?id=${
            props.id
          }&start=${commentStart}&interactionId=${interactionId}`
        )
        .then((response) => {
          setData({
            ...data,
            comments: data.comments
              .map((comment) => (comment.id === interactionId ? null : comment))
              .reduce((a, b) => [...a, b], [])
              .filter((a) => a),
          });
          setCommentStart(commentStart + (response.data ?? []).length);
        });
    } catch (e) {
      console.error("[JSON Error]", e);
    }
  };

  const formatDate = (value) => {
    const date = new Date(value);
    return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const getComments = () => {
    try {
      axios
        .get(
          `https://api.ourfight.online/formComments?id=${
            props.id
          }&start=${commentStart}`
        )
        .then((response) => {
          setData({
            ...data,
            comments: [...(data.comments || []), ...(response.data ?? [])],
          });
          setCommentStart(commentStart + (response.data ?? []).length);
        });
    } catch (e) {
      console.error("[JSON Error]", e);
    }
  };

  const getHeight = () => {
    let body = document.body;
    let html = document.documentElement;

    return Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
  };

  const getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourfight.online/form${
          props?.id ? `/id/${props.id}` : `/id/`
        }${props?.zip ? `/zip/${props.zip}` : ``}`
      )
      .then((response) => {
        setData(response.data);
        setCommentStart((response.data?.comments ?? []).length);
        setFormData({
          ...props.formData,
          zip: (response.data?.closestZip?.ZipCode ?? "")
            ?.toString()
            ?.padStart(5, "0"),
        });
      });
  }, [props.id, props.zip, props.formData]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return <FormLoad />;

  setInterval(function() {
    window.top.postMessage({ id: props.id, height: getHeight() }, "*");
  }, 100);

  if (
    !data.priority &&
    data.archivedDateTime &&
    data.archivedDateTime !== "0000-00-00 00:00:00"
  ) {
    return (
      <div>
        <div className="display-2 text-center mb-3">
          This petition is no longer available
        </div>
      </div>
    );
  } else if (
    !data.priority &&
    toBoolean(data.startEndDates) &&
    (Date.parse(data.startDate) > Date.parse(formatDate(new Date())) ||
      Date.parse(data.endDate) < Date.parse(formatDate(new Date())))
  ) {
    return (
      <div>
        <div className="display-2 text-center mb-3">
          This petition is not available
        </div>
      </div>
    );
  } else
    switch (step) {
      case 3:
        return (
          <div>
            <FormShare formId={data.id} title={data.title} />
            <FormTitle title={data.title} />
            <FormFooter text={data.footerText} url={data.footerURL} />
            {!toBoolean(data.hideProgressBar) && (
              <FormProgress formId={data.id} />
            )}
            <FormImage
              src={"https://static.ourfight.online/upload/" + data.image}
              reduce={false}
              expand={true}
            />
            <FormThankYou
              form={data}
              formId={data.id}
              title={data.title}
              poll={data.polls}
              pollAnswer={pollAnswer}
              otherPetitions={otherPetitions}
              showReps={data.showReps}
              emailSubject={data.emailSubject}
              emailBody={data.emailBody}
              email={formData.email}
              firstName={formData.firstName}
              lastName={formData.lastName}
              zip={formData.zip}
              promoteCaucusRoom={true}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <Row>
              <Col xs={12}>
                {/* <FormShare formId={data.id} title={data.title} /> */}
                <FormTitle title={data.title} />
                <FormFooter text={data.footerText} url={data.footerURL} />
                {!toBoolean(data.hideProgressBar) && (
                  <FormProgress formId={data.id} />
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <FormImage
                  src={"https://static.ourfight.online/upload/" + data.image}
                  reduce={false}
                  expand={true}
                />
                <FormText key="text-pledge" text={data.contentPledge} />
              </Col>
              <Col sm={4} className="d-none d-sm-block">
                <FormPledge
                  form={data}
                  formData={formData}
                  setFormData={(p) => {
                    setFormData(p);
                  }}
                  setPollAnswer={(p) => {
                    setPollAnswer(p);
                  }}
                  poll={data.polls}
                  pollAnswer={pollAnswer}
                  interactionId={interactionId}
                />
              </Col>
            </Row>
            {!showSignModal && (
              <Navbar fixed="bottom" className="w-100 d-flex d-sm-none">
                <Button
                  className="w-100"
                  onClick={() => setShowSignModal(true)}
                >
                  Click to Pledge
                </Button>
              </Navbar>
            )}
            <Modal show={showSignModal} onHide={() => setShowSignModal(false)}>
              <Modal.Header closeButton />
              <Modal.Body>
                <FormPledge
                  form={data}
                  formData={formData}
                  setFormData={(p) => {
                    setFormData(p);
                  }}
                  setPollAnswer={(p) => {
                    setPollAnswer(p);
                  }}
                  poll={data.polls}
                  pollAnswer={pollAnswer}
                  interactionId={interactionId}
                />
              </Modal.Body>
            </Modal>
          </div>
        );
      default:
        return (
          <div>
            <Row>
              <Col xs={12}>
                <FormTitle title={data.title} />
                <FormFooter text={data.footerText} url={data.footerURL} />
                {!toBoolean(data.hideProgressBar) && (
                  <FormProgress formId={data.id} />
                )}
                <FormOwnerOptions data={data} />
              </Col>
            </Row>
            {/* <FormShare formId={data.id} title={data.title} /> */}
            <Row>
              <Col sm={8}>
                <FormImage
                  src={"https://static.ourfight.online/upload/" + data.image}
                  reduce={false}
                  expand={true}
                />
                <FormText key="text-sign" text={data.content} />
                {data.emailSubject && (
                  <FormEmailTemplate
                    emailSubject={data.emailSubject}
                    emailBody={data.emailBody}
                    email={formData.email}
                    firstName={formData.firstName}
                    lastName={formData.lastName}
                    zip={formData.zip}
                  />
                )}
                {!toBoolean(data.disableComments) &&
                  !!(data.comments ?? []).length && (
                    <>
                      <div className="fs-4 mt-5">
                        Reasons People are Joining the Fight:
                      </div>
                      {data.comments.map(
                        (comment) =>
                          comment?.comment && (
                            <Card key={comment.id} className="mt-3">
                              {cookies.user_id === data.fk_accountId && (
                                <Card.Header className="text-end">
                                  {`${comment.lastName}, ${comment.firstName} `}
                                  <a href={`mailto: ${comment.email}`}>
                                    {comment.email}
                                  </a>{" "}
                                  <TooltipOverlay tip="Flag comment">
                                    <Button
                                      variant="danger"
                                      onClick={() => flagComment(comment.id)}
                                    >
                                      <FlagFill />
                                    </Button>
                                  </TooltipOverlay>
                                </Card.Header>
                              )}
                              <Card.Body>
                                <Card.Title>
                                  {`From ${comment.city ??
                                    comment.zip ??
                                    "USA"}`}{" "}
                                  on {comment.createdDateTime}
                                </Card.Title>
                                <Card.Text>
                                  {textFilter(comment.comment)}
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          )
                      )}
                    </>
                  )}
                {!toBoolean(data.disableComments) &&
                  (data.comments ?? [])?.length < data.commentTally && (
                    <div className="text-end">
                      <Button
                        variant="link"
                        className="fs-4 text-decoration-none outline-none"
                        onClick={() => getComments()}
                      >
                        <ArrowDownCircle />
                        &nbsp;Show More
                      </Button>
                    </div>
                  )}
              </Col>
              <Col sm={4} className="d-none d-sm-block">
                <Form>
                  <FormFields
                    narrow
                    stack
                    data={data}
                    formData={formData}
                    setFormData={(p) => {
                      setFormData(p);
                    }}
                    setStep={(p) => {
                      setStep(p);
                    }}
                    setPollAnswer={(p) => {
                      setPollAnswer(p);
                    }}
                    setInteractionId={(p) => {
                      setInteractionId(p);
                    }}
                    setOtherPetitions={(p) => {
                      setOtherPetitions(p);
                    }}
                    setSignerState={(p) => {
                      setSignerState(p);
                    }}
                    formId={data.id}
                    poll={data.polls}
                    form={data}
                    source={props.source}
                    fbCampaignId={props.fbCampaignId}
                    rumbleCampaignId={props.rumbleCampaignId}
                    twitterCampaignId={props.twitterCampaignId}
                    interactionId={interactionId}
                    promoteForm={promoteForm}
                    setPromoteForm={setPromoteForm}
                    signerState={signerState}
                  />
                </Form>
              </Col>
            </Row>
            {!showSignModal && (
              <Navbar fixed="bottom" className="w-100 d-flex d-sm-none">
                <Button
                  className="w-100"
                  onClick={() => setShowSignModal(true)}
                >
                  Click to Sign
                </Button>
              </Navbar>
            )}
            <Modal show={showSignModal} onHide={() => setShowSignModal(false)}>
              <Modal.Header closeButton />
              <Modal.Body>
                <Form>
                  <FormFields
                    narrow
                    stack
                    data={data}
                    formData={formData}
                    setFormData={(p) => {
                      setFormData(p);
                    }}
                    setStep={(p) => {
                      setStep(p);
                    }}
                    setPollAnswer={(p) => {
                      setPollAnswer(p);
                    }}
                    setInteractionId={(p) => {
                      setInteractionId(p);
                    }}
                    setOtherPetitions={(p) => {
                      setOtherPetitions(p);
                    }}
                    setSignerState={(p) => {
                      setSignerState(p);
                    }}
                    formId={data.id}
                    poll={data.polls}
                    form={data}
                    source={props.source}
                    fbCampaignId={props.fbCampaignId}
                    rumbleCampaignId={props.rumbleCampaignId}
                    twitterCampaignId={props.twitterCampaignId}
                    interactionId={interactionId}
                    promoteForm={promoteForm}
                    setPromoteForm={setPromoteForm}
                    signerState={signerState}
                  />
                </Form>
              </Modal.Body>
            </Modal>
          </div>
        );
    }
}

export default FormView;