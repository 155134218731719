import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  Row,
  Spinner,
} from "react-bootstrap";
import Dialog from "../Doalog";
import FormLegalese from "./FormLegalese";
import FormPoll from "./FormPoll";
import FormPollSlider from "./FormPollSlider";
import { toBoolean } from "../Shared";
import textFilter from "../textFilter";
import { FormZip } from "./FormZip";

function FormFieldsDefault(props) {
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [signDisabled, setSignDisabled] = useState(true);

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const hideFields =
    props?.hideFields &&
    props?.firstName &&
    props?.lastName &&
    props?.zip &&
    props?.email;

  const submit = useCallback(async () => {
    if (
      !props.formData.firstName ||
      props.formData.firstName.length < 1 ||
      props.formData.firstName.length > 25
    ) {
      showDialog("Please enter a first name between 1 and 25 characters");
    } else if (
      !props.formData.lastName ||
      props.formData.lastName.length < 1 ||
      props.formData.lastName.length > 25
    ) {
      showDialog("Please enter a last name between 1 and 25 characters");
    } else if (
      /^[0-9]{5}$/g.test((props.formData.zip ?? "").toString()) === false ||
      (props.formData.zip ?? "").toString().length !== 5
    ) {
      showDialog("Please enter a valid, 5 digit zip");
    } else if (
      /^[^@\s]+@[^.]+(\.\w{2,})+$/g.test(props.formData.email) === false ||
      props.formData.email.length < 5 ||
      props.formData.email.length > 64
    ) {
      showDialog(
        "Please enter a valid email address between 5 and 64 characters"
      );
    } else {
      showDialog(
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>,
        "Loading"
      );

      const { data } = await axios.get(
        "https://api.zerobounce.net/v2/validate?api_key=845bc733d485445686347e2e6640c3de&email=" +
          encodeURIComponent(props.formData.email)
      );

      if (
        data.status.toLowerCase() === "abuse" ||
        data.status.toLowerCase() === "catch-all" ||
        data.status.toLowerCase() === "do_not_mail" ||
        data.status.toLowerCase() === "valid"
      ) {
        //eslint-disable-next-line
        twq("event", "tw-oddyx-oddzg", {});

        const qs = require("qs");
        await axios
          .post(
            "https://api.ourfight.online/formSign",
            qs.stringify({
              formId: props.formId,
              fbCampaignId: props.fbCampaignId,
              rumbleCampaignId: props.rumbleCampaignId,
              twitterCampaignId: props.twitterCampaignId,
              firstName: props.formData.firstName,
              lastName: props.formData.lastName,
              zip: props.formData.zip,
              email: props.formData.email,
              emailStatus: data.status.toLowerCase(),
              polls: Object.entries(props.formData.pollQuestions ?? {})
                .map((value) => value.join("\n"))
                .join("\n\n"),
              comment: props.formData.comment,
              optIn: 1,
              referrer: document.referrer,
            }),
            { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
          )
          .then(async (response) => {
            if (props.form.emailIntegration)
              await axios.post(
                "https://api.ourfight.online/integrate",
                qs.stringify({
                  firstName: props.formData.firstName,
                  lastName: props.formData.lastName,
                  zip: props.formData.zip,
                  email: props.formData.email,
                  interactionId: response.data.id,
                  formId: props.form.id,
                  integrationId: props.form.emailIntegration,
                  referrer: document.referrer,
                }),
                {
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                }
              );

            props.setPollAnswer(response.data.answer);
            props.setInteractionId(response.data.id);
            if (props.setOtherPetitions)
              props.setOtherPetitions(response.data.otherPetitions);

            //if (props?.setPromoteForm) props?.setPromoteForm(true);
            props?.setStep(3);

            if (props?.setSignerState)
              props.setSignerState(
                response.data.state ?? props?.data?.closestZip?.State
              );
          });
      } else if (data.status.toLowerCase() === "abuse") {
        setInvalidEmail(true);
        showDialog(
          `${
            props.formData.email
          } is marked as "abuse". This email has been associated with clicking "abuse" links and/or making complaints. Please enter another email`
        );
      } else {
        setInvalidEmail(true);

        showDialog(
          `${props.formData.email} is invalid. Please provide another email.`
        );
      }
    }
  }, [props]);

  useEffect(() => {
    let newSignDisabled = false;

    if (
      !props.formData.firstName ||
      props.formData.firstName.length < 1 ||
      props.formData.firstName.length > 25
    ) {
      newSignDisabled = true;
    } else if (
      !props.formData.lastName ||
      props.formData.lastName.length < 1 ||
      props.formData.lastName.length > 25
    ) {
      newSignDisabled = true;
    } else if (
      /^[0-9]{5}$/g.test((props.formData.zip ?? "").toString()) === false ||
      (props.formData.zip ?? "").toString().length !== 5
    ) {
      newSignDisabled = true;
    } else if (
      /^[^@\s]+@[^.]+(\.\w{2,})+$/g.test(props.formData.email) === false ||
      props.formData.email.length < 5 ||
      props.formData.email.length > 64
    ) {
      newSignDisabled = true;
    }

    setSignDisabled(newSignDisabled);
  }, [props.formData]);

  return (
    <div>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      />

      {!!props.poll && (
        <Row className="mb-3">
          {props.pollSlider ? (
            <FormPollSlider
              formData={props.formData}
              setFormData={(p) => {
                props.setFormData(p);
              }}
              poll={props.poll}
            />
          ) : (
            <FormPoll
              formData={props.formData}
              setFormData={(p) => {
                props.setFormData(p);
              }}
              poll={props.poll}
            />
          )}
        </Row>
      )}

      {!hideFields && (
        <Row>
          <Col
            xs={props.stack && "12"}
            md={props.stack && "6"}
            className={!!props.stack && "mb-3"}
          >
            <FloatingLabel label="first name">
              <FormControl
                name="firstName"
                id="firstName"
                value={props.formData?.firstName || ""}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    firstName: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
          </Col>
          <Col
            xs={props.stack && "12"}
            md={props.stack && "6"}
            className={!!props.stack && "mb-3"}
          >
            <FloatingLabel label="last name">
              <FormControl
                name="lastName"
                id="lastName"
                value={props.formData?.lastName || ""}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    lastName: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
      )}

      {(!hideFields || invalidEmail) && (
        <Row className="mb-3">
          <Col className="text-center" xs={12}>
            <FloatingLabel
              label="email"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: hideFields ? "80%" : "",
              }}
            >
              <FormControl
                name="email"
                id="email"
                type="email"
                value={props.formData?.email || ""}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    email: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
      )}

      {!hideFields && (
        <Row className="mb-3">
          {!hideFields && (
            <Col xs={12}>
              <FormZip {...props} />
            </Col>
          )}
        </Row>
      )}

      {!toBoolean(props.form.disableComments) && (
        <Row className="mb-3">
          <Col xs={12}>
            <FloatingLabel
              label="reason I'm joining the fight"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: hideFields ? "80%" : "",
              }}
            >
              <Form.Control
                as="textarea"
                name="comment"
                id="comment"
                rows={5}
                maxLength={255}
                value={props.formData?.comment || ""}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    comment: textFilter(e.target.value),
                  });
                }}
              />
            </FloatingLabel>
            <small className="float-end">
              {(props.formData.comment ?? "").length}/255
            </small>
          </Col>
        </Row>
      )}

      <Row className="pb-3">
        <Col className="text-center">
          <FormLegalese
            label="Sign"
            additionalLegalese={props.additionalLegalese}
          />

          <Button
            disabled={signDisabled}
            className="btn-sign w-100 p-1 fs-3"
            onClick={() => submit()}
          >
            Sign
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default FormFieldsDefault;