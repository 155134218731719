import React from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import Login from "./Login";
import Signup from "./Signup";

function LoginSingup(props) {
  return (
    <Modal centered show={props.show} onHide={() => props.setShowLogin(false)}>
      <Modal.Header closeButton>Account</Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="login"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="login" title="Login">
            <Login {...props} />
          </Tab>
          <Tab eventKey="Signup" title="Signup">
            <Signup {...props} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default LoginSingup;