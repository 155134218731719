/* global gapi */
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";

const GoogleAnalytics = (props) => {
  const [accessToken, setAccessToken] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDatae] = useState(new Date());

  const clientId =
    "612945996448-k3oo6c3m5bsl5bgb945645mk4l5upl2j.apps.googleusercontent.com";
  const clientSecret = "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";
  const refreshToken =
    "1//0dx9YUcjyWZIxCgYIARAAGA0SNwF-L9IrmpVCb-1W7eVsf4z6t1As-Bl69Sg6Fb4uxZcxC_xVzSRKAqfwjFVd5k3c-2Cgbcpa8zU";

  const getAccessToken = useCallback(async () => {
    await axios
      .post(
        `https://www.googleapis.com/oauth2/v3/token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=refresh_token&refresh_token=${refreshToken}`
      )
      .then((response) => {
        setAccessToken(response.data.access_token);

        (function (w, d, s, g, js, fjs) {
          g = w.gapi || (w.gapi = {});
          g.analytics = {
            q: [],
            ready: function (cb) {
              this.q.push(cb);
            },
          };
          js = d.createElement(s);
          fjs = d.getElementsByTagName(s)[0];
          js.src = "https://apis.google.com/js/platform.js";
          fjs.parentNode.insertBefore(js, fjs);
          js.onload = function () {
            g.load("analytics");
          };
        })(window, document, "script");
      });
  }, []);

  const formatDate = (date) => date.toISOString().split("T")[0];

  useEffect(() => {
    getAccessToken();
  }, [getAccessToken]);

  useEffect(() => {
    if (!accessToken) return;

    gapi.analytics.ready(function () {
      gapi.analytics.auth.authorize({
        serverAuth: {
          access_token: accessToken,
        },
      });

      new gapi.analytics.googleCharts.DataChart({
        reportType: "ga",
        query: {
          ...(props.filters ? { filters: props.filters } : {}),
          ...{
            ids: "ga:229870940",
            dimensions: "ga:date",
            metrics: "ga:pageviews,ga:sessions",
            "start-date": formatDate(startDate),
            "end-date": formatDate(endDate),
          },
        },
        chart: {
          type: "LINE",
          container: "sessions",
          options: { width: "100%" },
        },
      }).execute();

      new gapi.analytics.googleCharts.DataChart({
        reportType: "ga",
        query: {
          ...(props.filters ? { filters: props.filters } : {}),
          ...{
            ids: "ga:229870940",
            dimensions: "ga:hour",
            metrics: "ga:pageviews,ga:sessions",
            "start-date": formatDate(startDate),
            "end-date": formatDate(endDate),
          },
        },
        chart: {
          type: "LINE",
          container: "hours",
          options: { width: "100%" },
        },
      }).execute();

      new gapi.analytics.googleCharts.DataChart({
        reportType: "ga",
        query: {
          ...(props.filters ? { filters: props.filters } : {}),
          ...{
            ids: "ga:229870940",
            dimensions: "ga:deviceCategory",
            metrics: "ga:pageviews,ga:sessions",
            "start-date": formatDate(startDate),
            "end-date": formatDate(endDate),
          },
        },
        chart: {
          type: "BAR",
          container: "device",
          options: { width: "100%" },
        },
      }).execute();

      new gapi.analytics.googleCharts.DataChart({
        reportType: "ga",
        query: {
          ...(props.filters ? { filters: props.filters } : {}),
          ...{
            ids: "ga:229870940",
            dimensions: "ga:fullReferrer,ga:medium",
            metrics: "ga:pageviews,ga:sessions",
            "start-date": formatDate(startDate),
            "end-date": formatDate(endDate),
            "max-results": 5,
            //fields: "columnHeaders(Pageviews)",
          },
        },
        chart: {
          type: "TABLE",
          container: "referrer",
          options: { width: "100%" },
        },
      }).execute();
    });
  }, [accessToken, startDate, endDate, props.filters]);

  return (
    <Container fluid className="py-3">
      <InputGroup className="my-3">
        <Form.Control
          type="date"
          placeholder="start date (yyyy-mm-dd)"
          value={formatDate(startDate)}
          onChange={(e) => setStartDate(new Date(e.target.value))}
        ></Form.Control>
        <Form.Control
          type="date"
          placeholder="end date (yyyy-mm-dd)"
          value={formatDate(endDate)}
          onChange={(e) => setEndDatae(new Date(e.target.value))}
        ></Form.Control>
      </InputGroup>
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Date</Card.Title>
              <div id="sessions"></div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Hour</Card.Title>
              <div id="hours"></div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Device</Card.Title>
              <div id="device"></div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Referrer</Card.Title>
              <div id="referrer"></div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GoogleAnalytics;
