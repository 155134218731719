import React, { useState } from "react";
import axios from "axios";
import { Button, Col, FormControl, Row, Spinner } from "react-bootstrap";
import Dialog from "../Doalog";

function FormPledge(props) {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [showCustompledge, setShowCustomPledge] = useState(false);
  const [customPledgeAmount, setCustomPledgeAmount] = useState(0);

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message) => {
    setDialog(true);
    setDialogMessage(message);
  };

  const submit = async (pledgeAmount) => {
    if (!pledgeAmount) showDialog("Please select/enter an amount");
    else {
      showDialog(
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>,
        "Loading"
      );

      const qs = require("qs");

      await axios.post(
        "https://api.ourfight.online/pledge",
        qs.stringify({
          interactionId: props.interactionId,
          pledgeAmount: pledgeAmount,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      if (props.newWindow && window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            message: "page",
            url:
              "https://api.ourfight.online/integrateMobile?" +
              qs.stringify({
                firstName: props.formData.firstName,
                lastName: props.formData.lastName,
                zip: props.formData.zip,
                email: props.formData.email,
                pledgeAmount: pledgeAmount,
                interactionId: props.interactionId,
                formId: props.form.id,
                integrationId: props.form.merchantIntegration,
                referrer: document.referrer,
              }),
          })
        );

        props.setStep(3);
      } else {
        await axios
          .post(
            "https://api.ourfight.online/integrate",
            qs.stringify({
              firstName: props.formData.firstName,
              lastName: props.formData.lastName,
              zip: props.formData.zip,
              email: props.formData.email,
              pledgeAmount: pledgeAmount,
              interactionId: props.interactionId,
              formId: props.form.id,
              integrationId: props.form.merchantIntegration,
              referrer: document.referrer,
            }),
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then((response) => {
            if (response.data.method === "GET")
              window.location.href = response.data.url;
            else {
              const url = URL.createObjectURL(
                new Blob([response.data.result], { type: "text/html" })
              );

              if (!props.newWindow && !window.ReactNativeWebView) {
                window.location.href = url;
              } else if (props.newWindow && !window.ReactNativeWebView) {
                const w = window.open("about:blank");
                w.document.open();
                w.document.write(response.data.result);
                w.document.close();
                props.setStep(3);
              } else {
                window.document.open();
                window.document.write(response.data.result);
                window.document.close();
              }
            }
          });
      }
    }
  };

  return (
    <div>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title="Pledge"
      />
      {props.form.pledgeAmounts.split(" ").map((amount, index) => (
        <Button
          className="btn-pledge w-100 mb-3"
          onClick={() => {
            submit(parseFloat(amount));
          }}
          key={`amount${index}`}
        >
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(parseFloat(amount))}
        </Button>
      ))}
      {!showCustompledge && (
        <Button
          className="btn-pledge w-100 mb-3"
          onClick={() => {
            setShowCustomPledge(true);
          }}
          key={`amount0`}
        >
          Other
        </Button>
      )}
      {showCustompledge && (
        <Row>
          <Col>
            <FormControl
              placeholder="amount"
              name="amount"
              value={customPledgeAmount}
              onChange={(e) => {
                setCustomPledgeAmount(parseInt(e.target.value));
              }}
              onBlur={(e) => {
                setCustomPledgeAmount(parseInt(e.target.value));
              }}
            />
          </Col>
          <Col>
            <Button
              className="w-100 mb-3"
              onClick={() => {
                submit(customPledgeAmount);
              }}
              key={`amount-1`}
            >
              Pledge
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default FormPledge;