import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Badge, Card, Col, Row } from "react-bootstrap";
import GoogleAnalytics from "./GoogleAnalytics";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const monthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function AdminAnalytics(props) {
  const [cookies] = useCookies(["user_id"]);
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourfight.online/adminAnalytics/userId/${cookies.user_id}`
      )
      .then((response) => {
        const yearEmailData = response.data.years.reverse();
        const monthEmailData = response.data.months.reverse();
        const monthAccountData = response.data.accounts.reverse();
        const monthFormData = response.data.forms.reverse();

        setData({
          ...response.data,
          chartYearEmails: {
            labels: yearEmailData.map((year) =>
              new Date(year.createdDateTimeYear + " 23:59").getFullYear()
            ),
            datasets: [
              {
                data: yearEmailData.map((year) => parseInt(year.tally)),
                backgroundColor: yearEmailData.map((year, index, array) =>
                  !index ||
                  parseInt(year.tally) >= parseInt(array[index - 1].tally)
                    ? "rgba(99, 255, 132, 0.5)"
                    : "rgba(255, 99, 132, 0.5)"
                ),
              },
            ],
          },
          chartMonthEmails: {
            labels: monthEmailData.map(
              (month) =>
                monthName[
                  new Date(month.createdDateTimeMonth + " 23:59").getMonth()
                ]
            ),
            datasets: [
              {
                data: monthEmailData.map((month) => parseInt(month.tally)),
                backgroundColor: monthEmailData.map((month, index, array) =>
                  !index ||
                  parseInt(month.tally) >= parseInt(array[index - 1].tally)
                    ? "rgba(99, 255, 132, 0.5)"
                    : "rgba(255, 99, 132, 0.5)"
                ),
              },
            ],
          },
          chartMonthlyAccounts: {
            labels: monthAccountData.map(
              (month) =>
                monthName[new Date(month.month + "-01 23:59").getMonth()]
            ),
            datasets: [
              {
                data: monthAccountData.map((month) => parseInt(month.tally)),
                backgroundColor: monthAccountData.map((month, index, array) =>
                  !index ||
                  parseInt(month.tally) >= parseInt(array[index - 1].tally)
                    ? "rgba(99, 255, 132, 0.5)"
                    : "rgba(255, 99, 132, 0.5)"
                ),
              },
            ],
          },
          chartMonthlyForms: {
            labels: monthFormData.map(
              (month) =>
                monthName[new Date(month.month + "-01 23:59").getMonth()]
            ),
            datasets: [
              {
                data: monthFormData.map((month) => parseInt(month.tally)),
                backgroundColor: monthFormData.map((month, index, array) =>
                  !index ||
                  parseInt(month.tally) >= parseInt(array[index - 1].tally)
                    ? "rgba(99, 255, 132, 0.5)"
                    : "rgba(255, 99, 132, 0.5)"
                ),
              },
            ],
          },
        });
      });
  }, [cookies.user_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const DataPoint = (props) => {
    const value = parseFloat(props.value || 0);
    const prior = parseFloat(props.prior || 0);

    return (
      <Col xs={props.xs || 12} md={props.md || 6} lg={props.lg || 4}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <div className="fs-5">
              {(+value.toFixed(2)).toLocaleString("en-us")}
              <Badge className="ml-3" bg={prior > value ? "danger" : "success"}>
                {props.hasOwnProperty("prior")
                  ? (value > prior ? "+" : "") +
                    (+(value - prior).toFixed(2)).toLocaleString("en-us")
                  : null}
              </Badge>
            </div>
            <div className="fs-6 fw-light">{props.description}</div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  if (cookies.user_id !== "72ee332c-4f84-4516-90f1-036a6805fa43")
    return <Navigate to="/" />;
  else if (!data) return null;
  else
    return (
      <div className="mt-3">
        <Card className="mb-3">
          <Card.Header className="bg-dark text-light h5">Traffic</Card.Header>
          <GoogleAnalytics />
        </Card>

        <Card>
          <Card.Header className="bg-dark text-light h5">
            Yearly &amp; Monthly Trends
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Yearly Emails</Card.Title>
                    <Bar
                      className="mb-5"
                      options={options}
                      data={data.chartYearEmails}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Monthly Emails</Card.Title>
                    <Bar
                      className="mb-5"
                      options={options}
                      data={data.chartMonthEmails}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Monthly Accounts</Card.Title>
                    <Bar
                      className="mb-5"
                      options={options}
                      data={data.chartMonthlyAccounts}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Monthly Forms</Card.Title>
                    <Bar
                      className="mb-5"
                      options={options}
                      data={data.chartMonthlyForms}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Header className="bg-dark text-light h5">
            Acquired Emails: Overall
          </Card.Header>
          <Card.Body>
            <Row>
              <DataPoint
                title="# of Days"
                description="Total number of days containing data. These days may not be continuous."
                value={data?.analytics?.days}
              />
              <DataPoint
                title="Total"
                description={`Total number of distinct emails acquired. Excluding ${(+data.unsubscribed.toFixed(
                  2
                )).toLocaleString(
                  "en-us"
                )} unsubscribed. ${(+data.urlComment.toFixed(2)).toLocaleString(
                  "en-us"
                )} acquired through comments.`}
                value={data?.analytics?.total - data?.unsubscribed}
              />
              <DataPoint
                title="Average"
                description="Average number of distinct emails acquired daily"
                value={data?.analytics?.average}
              />
            </Row>
            <Row>
              <DataPoint
                title="Projection"
                description="Projected number of distinct emails aquired this year based on the current daily average"
                value={data?.analytics?.projection365}
              />
              <DataPoint
                title="Yesterday"
                description="Total number of distinct emails acquired yesterday"
                value={data?.analytics?.totalDayPrior}
              />
              <DataPoint
                title="Today"
                description={`Total number of distinct emails acquired today. On pace for ${(
                  (24 / new Date().getHours()) *
                  data?.analytics?.totalDay
                )
                  .toFixed(2)
                  .toLocaleString("en-us")}`}
                value={data?.analytics?.totalDay}
                prior={data?.analytics?.totalDayPrior}
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Header className="bg-dark text-light h5">
            Acquired Emails: This Week
          </Card.Header>
          <Card.Body>
            <Row>
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="Prior Week"
                description="Total number of distinct emails acquired last week"
                value={data?.analytics?.totalWeekPrior}
              />
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="This Week"
                description="Total number of distinct emails acquired this week"
                value={data?.analytics?.totalWeek}
                prior={data?.analytics?.totalWeekPrior}
              />
            </Row>
            <Row>
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="Prior Week Daily Average"
                description="Average number of distinct emails acquired daily last week"
                value={data?.analytics?.avgWeekPrior}
              />
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="This Week's Daily Average"
                description="Average number of distinct emails acquired daily this week"
                value={data?.analytics?.avgWeek}
                prior={data?.analytics?.avgWeekPrior}
              />
            </Row>
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Header className="bg-dark text-light h5">
            Acquired Emails: This Month
          </Card.Header>
          <Card.Body>
            <Row>
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="Prior Month"
                description="Total number of distinct emails acquired last month"
                value={data?.analytics?.totalMonthPrior}
              />
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="This Month"
                description="Total number of distinct emails acquired this month"
                value={data?.analytics?.totalMonth}
                prior={data?.analytics?.totalMonthPrior}
              />
            </Row>
            <Row>
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="Prior Month Daily Average"
                description="Average number of distinct emails acquired daily last month"
                value={data?.analytics?.avgMonthPrior}
              />
              <DataPoint
                xs={12}
                md={6}
                lg={6}
                title="This Month's Daily Average"
                description="Average number of distinct emails acquired daily this month"
                value={data?.analytics?.avgMonth}
                prior={data?.analytics?.avgMonthPrior}
              />
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
}

export default AdminAnalytics;
