import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";

const FormPromotionReceipt = (props) => {
  const [data, setData] = useState(null);
  const [seconds, setSeconds] = useState(0);

  const getData = useCallback(() => {
    try {
      const path = window?.location?.pathname?.split("/");
      const id = path[path.length - 1];

      axios
        .get(`https://api.ourfight.online/formPromotionReceipt?id=${id}`)
        .then((response) => {
          if (response.data?.fk_interactionId === id) setData(response.data);
          else setSeconds(3);
        });
    } catch (e) {
      console.error("[JSON Error]", e);
    }
  }, []);

  useEffect(() => {
    if (!seconds) getData();
    else setTimeout(() => setSeconds(seconds - 1), 1000);
  }, [seconds, getData]);

  if (!data && !!seconds)
    return (
      <div className="fs-1 text-center">
        Checking for receipt in {seconds} seconds.
      </div>
    );
  if (!data && !seconds)
    return <div className="fs-1 text-center">Checking for receipt...</div>;
  return (
    <Row>
      <Col md={3} />
      <Col md={6}>
        <Card className="mt-5">
          <Card.Header>
            <div className="fs-5 fw-bold">Form Prioritization Receipt</div>
            <small className="d-print-none">
              Please print a copy for your records.
            </small>
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col xs={3} className="fs-5 text-end">
                ID:
              </Col>
              <Col xs={9} className="fs-5 text-start">
                {data.fk_interactionId}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3} className="fs-5 text-end">
                Petition:
              </Col>
              <Col xs={9} className="fs-5 text-start">
                {data.title}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3} className="fs-5 text-end">
                State:
              </Col>
              <Col xs={9} className="fs-5 text-start">
                {data.state}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3} className="fs-5 text-end">
                Amount:
              </Col>
              <Col xs={9} className="fs-5 text-start">
                ${parseInt(data.amount).toLocaleString("en-us")}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={3} className="fs-5 text-end">
                Server Time:
              </Col>
              <Col xs={9} className="fs-5 text-start">
                {data.createdDateTime}
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="d-print-none">
            <Row>
              <Col md={10} />
              <Col md={2}>
                <Button className="w-100" onClick={() => window.print()}>
                  Print
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
      <Col md={3} />
    </Row>
  );
};

export default FormPromotionReceipt;