import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { GeoFill } from "react-bootstrap-icons";
import { Button, Col, Modal, Navbar, Row } from "react-bootstrap";
import PetitionList from "./PetitionList";
import { useMediaQuery } from "react-responsive";

const PetitionMap = (props) => {
  const bBox = { x: 0, y: 0, w: 358.9386, h: 85.96429 };
  const scale = 350;
  const minLon = -179.15;
  const screenIsSmall = useMediaQuery({
    query: "(max-width: 575px)",
  });

  const [data, setData] = useState(null);
  const [zoom] = useState(100);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [initXY, setInitXY] = useState(null);
  const [width] = useState(bBox.w);
  const [height] = useState(bBox.h);
  const [centerX, setCenterX] = useState(0);
  const [centerY, setCenterY] = useState(0);
  const [selectedForm, setSelectedForm] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const convertLatLong = useCallback(
    (lat, long) => {
      const colContent = document.getElementById("mainDiv");
      const colContentRect = colContent.getBoundingClientRect();
      setCenterX(colContentRect.width / 2);
      setCenterY(colContentRect.height / 2);

      const focal = {
        x: -(long - minLon - width / 2) * scale,
        y: -(lat - height / 2) * scale,
      };

      return focal;
    },
    [height, minLon, width]
  );

  const convertLatLongCSS = (lat, long) => {
    const focal = {
      left: `${(long + width / 2) * scale}px`,
      top: `${(height - lat) * scale}px`,
    };

    return focal;
  };

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/petitionMap`)
      .then((response) => {
        const formsSorted = Object.values(response.data.forms)
          .map((form) => form)
          .reduce((a, b) => [...a, b], []);

        formsSorted.sort((a, b) => (a.Lat <= b.Lat ? 1 : -1));

        setData({
          ...response.data,
          formsSorted: formsSorted,
        });

        setPan(
          convertLatLong(
            parseFloat(response.data.zip.Lat),
            parseFloat(response.data.zip.Long)
          )
        );
      });
  }, [convertLatLong]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data?.forms) return null;

  return (
    <Row className="gx-0">
      <Col md={10}>
        <div
          id="mainDiv"
          style={{
            backgroundColor: "#e0f1f9",
            display: "inline-block",
            overflow: "hidden",
            height: "calc(100vh - 200px)",
            width: "100%",
          }}
          onMouseUp={(e) => setInitXY(null)}
          onBlur={(e) => setInitXY(null)}
          onMouseLeave={(e) => setInitXY(null)}
          onTouchEnd={(e) => setInitXY(null)}
          onMouseDown={(e) => {
            if (!initXY) {
              setInitXY({
                x: e.clientX,
                y: e.clientY,
              });
            }
          }}
          onTouchStart={(e) => {
            if (!initXY) {
              setInitXY({
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
              });
            }
          }}
          onMouseMove={(e) => {
            if (initXY) {
              setPan({
                x: pan.x + (e.clientX - initXY.x) / (zoom / 100),
                y: pan.y + (initXY.y - e.clientY) / (zoom / 100),
              });

              setInitXY({
                x: e.clientX,
                y: e.clientY,
              });
            }
          }}
          onTouchMove={(e) => {
            if (initXY) {
              setPan({
                x: pan.x + (e.touches[0].clientX - initXY.x) / (zoom / 100),
                y: pan.y + (initXY.y - e.touches[0].clientY) / (zoom / 100),
              });

              setInitXY({
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
              });
            }
          }}
        >
          <div
            style={{
              transformOrigin: `center center`,
              transform: `scale(${zoom / 100})`,
              width: "100%",
              height: "calc(100vh - 200px)",
            }}
          >
            <div
              style={{
                position: "relative",
                transform: `translate(${pan.x}px, ${-pan.y}px)`,
                width: width,
                height: height,
              }}
            >
              <div
                style={{
                  position: "relative",
                  transform: `translate(calc(${centerX}px - ${(width / 2) *
                    scale}px), calc(${centerY}px - ${(height / 2) * scale}px)`,
                }}
              >
                <img
                  style={{
                    userSelect: "none",
                    width: `${width * scale}px`,
                    height: `${height * scale}px`,
                  }}
                  src="https://static.ourfight.online/usa.svg"
                  alt="Zip Map"
                  draggable={false}
                />
                {Object.values(data.formsSorted).map((form, index) => (
                  <GeoFill
                    key={form.id}
                    onClick={() => setSelectedForm(form)}
                    style={{
                      ...convertLatLongCSS(form.Lat, form.Lon),
                      color:
                        form.id === selectedForm?.id ? "#00cc55" : "#0055ff",
                      cursor: "pointer",
                      display: "inline-block",
                      filter: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5))",
                      fontSize: `${scale / 2}px`,
                      position: "absolute",
                      userSelect: "none",
                      WebkitFilter:
                        "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5))",
                      zIndex: index + 1,
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col md={2} className="d-none d-sm-block">
        <PetitionList
          forms={data.forms}
          selectedForm={selectedForm}
          fbCampaignId={props.fbCampaignId}
        />
      </Col>
      <Navbar fixed="bottom" className="w-100 d-flex d-sm-none">
        <Button className="w-100" onClick={() => setShowModal(true)}>
          See Petitions
        </Button>
      </Navbar>
      {screenIsSmall && (
        <Modal
          show={!!selectedForm || showModal}
          onHide={() => {
            setSelectedForm(null);
            setShowModal(false);
          }}
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <PetitionList
              forms={data.forms}
              selectedForm={selectedForm}
              fbCampaignId={props.fbCampaignId}
              rumbleCampaignId={props.rumbleCampaignId}
            />
          </Modal.Body>
        </Modal>
      )}
    </Row>
  );
};

export default PetitionMap;