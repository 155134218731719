import { Button, Card, Form, InputGroup } from "react-bootstrap";
import {
  CaretDown,
  CaretUp,
  ChatDots,
  Plus,
  PlusCircle,
  QuestionCircle,
  XLg,
} from "react-bootstrap-icons";
import TooltipOverlay from "../TooltipOverlay";

const FormPoll = (props) => {
  const addAnswer = (question, index) => {
    question.answers.splice(index, 0, { text: "New Answer" });
    updatePoll();
  };

  const addQuestion = () => {
    questions.push({
      text: "New Question",
      answers: [],
    });
    updatePoll();
  };

  const markAnswer = (isNegative, question, index) => {
    question.answers[index].isNegative = isNegative;
    updatePoll();
  };

  const moveAnswerDown = (question, index) => {
    const buffer = question.answers[index + 1];
    question.answers[index + 1] = question.answers[index];
    question.answers[index] = buffer;
    updatePoll();
  };

  const moveAnswerUp = (question, index) => {
    const buffer = question.answers[index - 1];
    question.answers[index - 1] = question.answers[index];
    question.answers[index] = buffer;
    updatePoll();
  };

  const moveQuestionDown = (index) => {
    const buffer = questions[index + 1];
    questions[index + 1] = questions[index];
    questions[index] = buffer;
    updatePoll();
  };

  const moveQuestionUp = (index) => {
    const buffer = questions[index - 1];
    questions[index - 1] = questions[index];
    questions[index] = buffer;
    updatePoll();
  };

  const removeAnswer = (question, index) => {
    question.answers.splice(index, 1);
    updatePoll();
  };

  const removeQuestion = (index) => {
    questions.splice(index, 1);
    updatePoll();
  };

  const trim = (text) => {
    return !text
      ? ""
      : text
          .replaceAll("\r", "")
          .replace(/^\n+|\n+$/g, "")
          .replace(/\n{3,}/g, "\n\n");
  };

  const lTrim = (text) => {
    return !text ? "" : text.replaceAll(/^[\r|\n|\s]+/g, "");
  };

  const updateAnswer = (value, question, index) => {
    question.answers[index].text = lTrim(value).length > 0 ? lTrim(value) : " ";
    updatePoll();
  };

  const updateQuestion = (value, index) => {
    questions[index].text = lTrim(value).length > 0 ? lTrim(value) : " ";
    updatePoll();
  };

  const updatePoll = (poll) =>
    props.setPoll(
      questions
        .map((question) =>
          [
            question.text,
            ...question.answers.map(
              (answer) => (answer.isNegative ? "{x}" : "") + answer.text
            ),
          ].join("\n")
        )
        .join("\n\n")
    );

  const poll = trim(props.poll);

  const questions = poll?.length
    ? trim(props.poll)
        .split("\n\n")
        .map((question, index, questions) => {
          const lines = question.split("\n");
          const firstLine = lines.shift();

          return {
            text: firstLine,
            isFirst: !index,
            isLast: index === questions.length - 1,
            answers: lines.map((answer, index) => {
              return {
                text: answer.replace(/\{[^}]*\}/g, ""),
                isFirst: !index,
                isLast: index === lines.length - 1,
                isNegative: answer.match(/\{[^}]*\}/g) !== null,
              };
            }),
          };
        })
    : [];

  return (
    <div>
      {trim(props.poll).length > 0 &&
        questions.map((question, indexQuestion) => (
          <Card className="mb-3" key={`pollQuestion${indexQuestion}`}>
            <Card.Header className="bg-transparent">
              <InputGroup>
                <TooltipOverlay tip="Question">
                  <InputGroup.Text className="border-0 bg-transparent pointer">
                    <QuestionCircle />
                  </InputGroup.Text>
                </TooltipOverlay>
                <Form.Control
                  className="border-0"
                  value={question.text}
                  onChange={(e) =>
                    updateQuestion(e.target.value, indexQuestion)
                  }
                />
                <TooltipOverlay tip="Add answer">
                  <InputGroup.Text
                    className="border-0 bg-transparent pointer"
                    onClick={() => addAnswer(question, 0)}
                  >
                    <Plus />
                  </InputGroup.Text>
                </TooltipOverlay>
                {!question.isFirst && (
                  <TooltipOverlay tip="Move question up">
                    <InputGroup.Text
                      className="border-0 bg-transparent pointer"
                      onClick={() => moveQuestionUp(indexQuestion)}
                    >
                      <CaretUp />
                    </InputGroup.Text>
                  </TooltipOverlay>
                )}
                {!question.isLast && (
                  <TooltipOverlay tip="Move question down">
                    <InputGroup.Text
                      className="border-0 bg-transparent pointer"
                      onClick={() => moveQuestionDown(indexQuestion)}
                    >
                      <CaretDown />
                    </InputGroup.Text>
                  </TooltipOverlay>
                )}
                <TooltipOverlay tip="Remove question">
                  <InputGroup.Text
                    className="border-0 bg-transparent pointer"
                    onClick={() => removeQuestion(indexQuestion)}
                  >
                    <XLg />
                  </InputGroup.Text>
                </TooltipOverlay>
              </InputGroup>
            </Card.Header>
            <Card.Body>
              {question.answers.map((answer, indexAnswer) => (
                <InputGroup key={`pollAnswer${indexQuestion}-${indexAnswer}`}>
                  <TooltipOverlay tip="Answer">
                    <InputGroup.Text className="border-0 bg-transparent pointer">
                      <ChatDots />
                    </InputGroup.Text>
                  </TooltipOverlay>
                  <Form.Control
                    className="border-0 border-bottom"
                    value={answer.text}
                    onChange={(e) =>
                      updateAnswer(e.target.value, question, indexAnswer)
                    }
                  />
                  <TooltipOverlay
                    tip={
                      answer.isNegative
                        ? "Click to mark answer as positive"
                        : "Click to mark answer as negative"
                    }
                  >
                    <Form.Check
                      type="switch"
                      className="switchNegative"
                      checked={answer.isNegative}
                      onChange={(e) =>
                        markAnswer(e.target.checked, question, indexAnswer)
                      }
                    />
                  </TooltipOverlay>
                  <TooltipOverlay tip="Add answer below">
                    <InputGroup.Text
                      className="border-0 bg-transparent pointer"
                      onClick={() => addAnswer(question, indexAnswer + 1)}
                    >
                      <Plus />
                    </InputGroup.Text>
                  </TooltipOverlay>
                  {!answer.isFirst && (
                    <TooltipOverlay tip="Move answer up">
                      <InputGroup.Text
                        className="border-0 bg-transparent pointer"
                        onClick={() => moveAnswerUp(question, indexAnswer)}
                      >
                        <CaretUp />
                      </InputGroup.Text>
                    </TooltipOverlay>
                  )}
                  {!answer.isLast && (
                    <TooltipOverlay tip="Move answer down">
                      <InputGroup.Text
                        className="border-0 bg-transparent pointer"
                        onClick={() => moveAnswerDown(question, indexAnswer)}
                      >
                        <CaretDown />
                      </InputGroup.Text>
                    </TooltipOverlay>
                  )}
                  <TooltipOverlay tip="Remove answer">
                    <InputGroup.Text
                      className="border-0 bg-transparent pointer"
                      onClick={() => removeAnswer(question, indexAnswer)}
                    >
                      <XLg />
                    </InputGroup.Text>
                  </TooltipOverlay>
                </InputGroup>
              ))}
            </Card.Body>
          </Card>
        ))}
      <Button className="float-end" onClick={addQuestion}>
        <PlusCircle />
        &nbsp; Add Question
      </Button>
    </div>
  );
};

export default FormPoll;