import { Button, Col, Row } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";

const DatePickerFull = (props) => {
  const updateDate = (days) => {
    const date = new Date(props?.selected?.getTime());
    date.setDate(date.getDate() + days);
    props.onChange(date);
  };

  return (
    <Row>
      <Col xs={2}>
        <Button
          variant="link"
          className="text-decoration-none"
          onClick={() => updateDate(-1)}
        >
          <ChevronLeft />
        </Button>
      </Col>
      <Col xs={8}>
        <DatePicker selected={props?.selected} onChange={props?.onChange} />
      </Col>
      <Col xs={2}>
        <Button
          variant="link"
          className="text-decoration-none"
          onClick={() => updateDate(1)}
        >
          <ChevronRight />
        </Button>
      </Col>
    </Row>
  );
};

export default DatePickerFull;