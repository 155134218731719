import React from 'react';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import textFilter from '../../textFilter';
import { toBoolean } from '../../Shared';
import FormProgress from '../../Form/FormProgress';
import FormPollResults from '../../Form/FormPollResults';

const FeedItem = ({ index, setLoading, zip }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios
            .get(
                `https://api.ourfight.online/formFeed?zip=${zip}&index=${index}`
            )
            .then((response) => {
                setData(response.data);
                setLoading(false);
            });
    }, [index, setLoading, zip]);

    if (!data || !data?.form) return null;
    return (
        <div className="mt-5">
            <a
                className="text-dark text-decoration-none"
                href={`/${data.form.id}`}
            >
                <div className={`text-center imageContainer`}>
                    <Image
                        fluid
                        src={`https://static.ourfight.online/upload/${data.form.image}`}
                    />

                    {data.form.title && (
                        <div className={`imageLabel text-start w-100 p-3`}>
                            {data.form.title}
                        </div>
                    )}
                </div>

                {!toBoolean(data.form.hideProgressBar) && (
                    <FormProgress formId={data.form.id} className="mt-3" />
                )}

                <div>
                    {data.interactions
                        ?.filter((interaction) => !!interaction.comment)
                        ?.map((interaction) => (
                            <Card key={interaction.id} className="mt-3">
                                <Card.Body>
                                    <Card.Title className="text-start">
                                        {`From ${
                                            interaction.city ??
                                            interaction.zip ??
                                            'USA'
                                        }`}{' '}
                                        on {interaction.createdDateTime}
                                    </Card.Title>
                                    <Card.Text className="text-start">
                                        {textFilter(interaction.comment)}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        ))}
                </div>

                <FormPollResults
                    className="text-start"
                    formId={data.form.id}
                    poll={data.poll}
                    pollAnswer={data.pollAnswer}
                />
            </a>
        </div>
    );
};

export default FeedItem;
