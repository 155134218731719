import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionArray from "./Section/SectionArray";
import Toasts from "../Toasts";

const NewsShowMore = (props) => {
  const [json, setJSON] = useState(null);
  const [votes, setVotes] = useState(null);
  const [dialog, setDialog] = useState(null);

  const path = window.location.pathname.split("/");
  const section = decodeURI(path[path.length - 1]);

  const hideDialog = () => setDialog(null);

  const toast = (message) => {
    setDialog(message);
  };

  useEffect(() => {
    const getJSON = async () =>
      axios
        .get(`https://api.mobubby.com/data?file=${encodeURIComponent(section)}`)
        .then((response) => {
          setJSON(JSON.parse(response.data.content));
          setVotes(response.data.votes);
        });

    getJSON();
  }, [section]);

  if (!json) return null;
  else {
    const articles = json[0]
      .map((article) => {
        return {
          ...article,
          url: `https://ourfight.online/news/link/${article.id}`,
        };
      })
      .reduce((a, b) => [...a, b], []);

    return (
      <>
        <Toasts
          show={!!dialog}
          title="Vote"
          message={dialog}
          onClick={() => hideDialog()}
        />

        <div className="news">
          <div className="mt-5">
            <SectionArray
              {...props}
              key={section}
              section={section}
              articles={articles}
              votes={votes}
              toast={toast}
              showMore=""
              returnToNews
            />
          </div>
        </div>
      </>
    );
  }
};

export default NewsShowMore;