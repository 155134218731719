import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Toasts from './Toasts';
import NewsLatestPetitions from './News/NewsLatestPetitions';
import Feed from './Feed';
import { useCallback } from 'react';

const Home = () => {
    const [data, setData] = useState(null);
    const [dialog, setDialog] = useState(null);
    const [latLon, setLatLon] = useState(null);
    const [zip, setZip] = useState(null);

    const getData = useCallback(async () => {
        await axios
            .get('https://api.ourfight.online/latest')
            .then((response) => setData(response.data));
    }, []);

    const getLatLon = useCallback(async () => {
        await axios
            .get('https://api.ourfight.online/getLatLon')
            .then((response) => setLatLon(response.data));
    }, []);

    const getZip = useCallback(async () => {
        await axios
            .get(
                `https://api.ourfight.online/getClosestZip?lat=${latLon.lat}&lon=${latLon.lon}`
            )
            .then((response) => setZip(response.data));
    }, [latLon]);

    const hideDialog = () => setDialog(null);

    useEffect(() => {
        getData();
        getLatLon();
    }, [getData, getLatLon]);

    useEffect(() => {
        if (!!latLon) getZip();
    }, [getZip, latLon]);

    if (!data) return null;
    else
        return (
            <>
                <Toasts
                    show={!!dialog}
                    title="Vote"
                    message={dialog}
                    onClick={() => hideDialog()}
                />

                <NewsLatestPetitions data={data} />
                {!!zip && <Feed zip={zip.zip} />}
            </>
        );
};

export default Home;
