import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import ReactHtmlParser from "react-html-parser";
import NewsAdSection from "../NewsAdSection";
import LayoutItem from "./LayoutItem";

const LayoutArrayLargeLeft = (props) => {
  const [first, setFirst] = useState(null);

  useEffect(() => {
    if (props.links) setFirst(props.links.shift());
  }, [props.links]);

  if (!first) return null;
  return (
    <Container fluid className="p-0" name={props.header} id={props.header}>
      <div className="display-3 border-bottom mb-3 text-start">
        {ReactHtmlParser(props.header.replace("%20", " "))}
      </div>{" "}
      <Row>
        <Col sm={12} md={6}>
          <Row>
            <Col key={`article-${first.id}`} className="mb-3">
              <LayoutItem article={first} className="cardLarge" {...props} />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row>
            {props.links.map((article, index) => (
              <Col key={`article-${article.id}`} sm={6} className="mb-3">
                <LayoutItem
                  article={article}
                  className="cardSmall"
                  {...props}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <NewsAdSection {...props} />
        {(props.showMore || false) && (
          <div>
            <a
              href={`/news/showmore/${props.header}`}
              className="text-decoration-none h3 float-end"
            >
              Show More <ArrowRightCircleFill />
            </a>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default LayoutArrayLargeLeft;