import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Col,
  Form,
  FormControl,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Dialog from "../Doalog";
import IntegrationWizzard from "./IntegrationWizzard";

const IntegrationEdit = (props) => {
  const [cookies] = useCookies(["user_id"]);
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    class: "",
    method: "",
    url: "",
    headers: "",
    body: "",
  });

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message) => {
    setDialog(true);
    setDialogMessage(message);
  };

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/integration/id/${props.id}`)
      .then((response) => setFormData(response.data));
  }, [props.id]);

  useEffect(() => {
    if (props.id) getData();
    else {
      switch (props.template) {
        case "constantcontact":
          setFormData({
            fk_accountId: cookies.user_id,
            name: "New Constant Contact Integration",
            class: "email",
            method: "POST",
            url:
              "https://api.constantcontact.com/v2/contacts?action_by=ACTION_BY_OWNER&api_key=<api key>",
            headers:
              "Authorization: Bearer <access token>\r\nContent-Type: application/json",
            body:
              '{\r\n\t"first_name": "{firstName}",\r\n\t"last_name": "{lastName}",\r\n\t"email_addresses": [\r\n\t\t{"email_address": "{email}"}\r\n\t\t\r\n\t],\r\n\t"lists": [\r\n\t\t{\r\n\t\t\t"id": "<list id>",\r\n\t\t\t"status": "ACTIVE"\r\n\t\t\t\r\n\t\t}\r\n\t]\r\n\t\r\n}\r\n',
          });
          break;
        case "mailchimp":
          setFormData({
            fk_accountId: cookies.user_id,
            name: "New Mail Chimp Integration",
            class: "email",
            method: "POST",
            url:
              "https://<any text>:<api key>@<data center>.api.mailchimp.com/3.0/lists/<list id>/members/",
            headers: "",
            body:
              '{\r\n\t"email_address": "{email}",\r\n\t"status": "subscribed",\r\n\t"merge_fields": {\r\n\t\t"FNAME": "{firstName}",\r\n\t\t"LNAME": "{lastName}"\r\n\t}\r\n}',
          });
          break;
        case "paypal":
          setFormData({
            fk_accountId: cookies.user_id,
            name: "New PayPal Integration",
            class: "merchant",
            method: "GET",
            url:
              "https://www.paypal.com/cgi-bin/webscr?business=<account email>&cmd=_donations&notify_url={paymentURL}&return={returnURL}&invoice={id}&amount={pledgeAmount}&first_name={firstName}&last_name={lastName}&zip={zip}&email={email}",
            headers: "",
            body: "",
          });
          break;
        case "stripe":
          setFormData({
            fk_accountId: cookies.user_id,
            name: "New Stripe Integration",
            class: "merchant",
            method: "POST",
            url:
              "https://stripe.ourfight.online?pApiKey=<public api key>&title={title}&paymentURL={paymentURL}&returnURL={returnURL}&id={id}&pledgeAmount={pledgeAmount}&firstName={firstName}&lastName={lastName}&zip={zip}&email={email}",
            headers: "",
            body: '{"sAPIKey": "<secret api key>"}',
          });
          break;
        default:
          setFormData({
            fk_accountId: cookies.user_id,
            name: "New Integration",
          });
      }
    }
  }, [getData, props.id, props.template, cookies.user_id]);

  const submit = async () => {
    if (formData.name.length < 5 || formData.name.length > 64) {
      showDialog("Please enter a name");
    } else {
      showDialog(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourfight.online/integrationEdit",
          qs.stringify({ ...formData, userId: props.userId, id: props.id }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          showDialog(
            `Integration ${formData.id ? "updated" : "created"} successfully!`
          );
          setFormData(response.data);
        });
    }
  };

  if (!formData) return null;
  if (!formData || !formData.fk_accountId) return null;
  else if (formData.fk_accountId !== cookies.user_id)
    return <Navigate to="/" />;
  else
    return (
      <Form className="mt-3">
        {props.template && (
          <IntegrationWizzard
            template={props.template}
            formData={formData}
            setFormData={(p) => {
              setFormData(p);
            }}
          />
        )}
        <Dialog
          show={dialog}
          onClick={hideDialog}
          message={dialogMessage}
          title="Update Integration"
        />
        <Row className="mb-3">
          <Col xs="12" md="4" className="">
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Name</Form.Label>
              <FormControl
                placeholder="name"
                name="name"
                value={formData.name}
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                }}
              />
              <Form.Text>Enter a name for the integration.</Form.Text>
            </Form.Group>
          </Col>
          <Col xs="12" md="4" className="">
            <Form.Group className="mb-3" controlId="class">
              <Form.Label>Class</Form.Label>
              <Form.Select
                value={formData.class}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    class: e.target.value,
                  });
                }}
              >
                <option value="">Select a class...</option>
                <option value="email">Email</option>
                <option value="merchant">Merchant</option>
              </Form.Select>
              <Form.Text>Select an integration class.</Form.Text>
            </Form.Group>
          </Col>
          <Col xs="12" md="4" className="">
            <Form.Group className="mb-3" controlId="class">
              <Form.Label>Method</Form.Label>
              <Form.Select
                value={formData.method}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    method: e.target.value,
                  });
                }}
              >
                <option value="">Select a method...</option>
                <option value="GET">GET</option>
                <option value="PUT">PUT</option>
                <option value="POST">POST</option>
              </Form.Select>
              <Form.Text>Select a request method.</Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12">
            <Form.Group className="mb-3" controlId="url">
              <Form.Label>URL</Form.Label>
              <FormControl
                value={formData.url}
                placeholder="URL"
                name="url"
                type="url"
                onChange={(e) => {
                  setFormData({ ...formData, url: e.target.value });
                }}
              />
              <Form.Text>
                Enter a request URL. You can include the tags lists{" "}
                <a href="#tags" className="text-decoration-none">
                  <strong>here</strong>
                </a>{" "}
                to have the association data dynamically inserted.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12">
            <Form.Group className="mb-3" controlId="url">
              <Form.Label>Headers</Form.Label>
              <FormControl
                as="textarea"
                value={formData.headers}
                placeholder="headers"
                name="headers"
                type="headers"
                rows={10}
                onChange={(e) => {
                  setFormData({ ...formData, headers: e.target.value });
                }}
              />
              <Form.Text>
                Optionally, enter any request headers. You can include the tags
                lists{" "}
                <a href="#tags" className="text-decoration-none">
                  <strong>here</strong>
                </a>{" "}
                to have the associated data dynamically inserted.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12">
            <Form.Group className="mb-3" controlId="url">
              <Form.Label>Body</Form.Label>
              <FormControl
                as="textarea"
                value={formData.body}
                placeholder="body"
                name="body"
                type="body"
                rows={10}
                onChange={(e) => {
                  setFormData({ ...formData, body: e.target.value });
                }}
              />
              <Form.Text>
                Optionally, enter a request body. You can include the tags
                listed{" "}
                <a href="#tags" className="text-decoration-none">
                  <strong>here</strong>
                </a>{" "}
                to have the associated data dynamically inserted.
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12" md="10" className="mb-3 mb-md-0" />
          <Col xs="12" md="2">
            <Button className="w-100 p-3" onClick={() => submit()}>
              {formData.id ? "Update" : "Create"}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Table id="tags" responsive striped hover>
              <thead>
                <tr>
                  <th>Tag</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{"{email}"} </td>
                  <td>The user-entered email.</td>
                </tr>
                <tr>
                  <td>{"{firstName}"} </td>
                  <td>The user-given first name.</td>
                </tr>
                <tr>
                  <td>{"{id}"} </td>
                  <td>The internal id of the interaction.</td>
                </tr>
                <tr>
                  <td>{"{lastName}"} </td>
                  <td>The user-given last name.</td>
                </tr>
                <tr>
                  <td>{"{paymentURL}"} </td>
                  <td>
                    The URL completed payment data should be sent to. Currently,
                    this URL supports PayPal and Stripe.
                  </td>
                </tr>
                <tr>
                  <td>{"{pledgeAmount}"} </td>
                  <td>The user-entered pledge amount. If any.</td>
                </tr>
                <tr>
                  <td>{"{referrer}"} </td>
                  <td>The URL the user linked to the petition from. If any.</td>
                </tr>
                <tr>
                  <td>{"{returnURL}"} </td>
                  <td>
                    The URL the user should be redirected to after completed
                    payments.
                  </td>
                </tr>
                <tr>
                  <td>{"{title}"} </td>
                  <td>The petition title.</td>
                </tr>
                <tr>
                  <td>{"{zip}"} </td>
                  <td>The user-given zip.</td>
                </tr>
                <tr>
                  <td>{"{petitionId}"} </td>
                  <td>The internal id of the petition.</td>
                </tr>
                <tr>
                  <td>{"{petitionTitle}"} </td>
                  <td>The title of the petition.</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Form>
    );
};

export default IntegrationEdit;