import { Card, Col, Row } from "react-bootstrap";

const About = () => (
  <div className="mt-3">
    <div className="fs-1">Credit</div>
    <Row className="mt-3">
      <Col>
        <a href="https://getbootstrap.com/" target="_blank" rel="noreferrer">
          <Card>
            <Card.Body>Bootstrap</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a href="https://www.chartjs.org/" target="_blank" rel="noreferrer">
          <Card>
            <Card.Body>Chart.js</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a
          href="https://www.npmjs.com/package/react-axios"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <Card.Body>React Axios</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a
          href="https://react-bootstrap.github.io/"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <Card.Body>React Bootstrap</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a
          href="https://github.com/Hacker0x01/react-datepicker"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <Card.Body>React Date Picker</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a
          href="https://react-chartjs-2.js.org/"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <Card.Body>React Chart.js 2</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a
          href="https://www.npmjs.com/package/react-cookie"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <Card.Body>React Cookie</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a
          href="https://www.npmjs.com/package/react-datepicker"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <Card.Body>React DatePicker</Card.Body>
          </Card>
        </a>
      </Col>
      <Col>
        <a
          href="https://v5.reactrouter.com/web/guides/quick-start"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <Card.Body>React Router</Card.Body>
          </Card>
        </a>
      </Col>
    </Row>
  </div>
);

export default About;