import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import FeedItem from './FeedItem';
import Spinner from 'react-bootstrap/Spinner';

const Feed = ({ zip }) => {
    const bottomRef = useRef(null);
    const [indexes, setIndexes] = useState([]);
    const [loading, setLoading] = useState(false);

    const loadMore = useCallback(() => {
        if (bottomRef.current && !loading) {
            const rect = bottomRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const isVisible = rect.top >= 0 && rect.top <= windowHeight;
            setLoading(isVisible);
            if (isVisible) setIndexes([...indexes, indexes.length + 1]);
        }
    }, [indexes, loading]);

    useEffect(() => {
        loadMore();
        window.removeEventListener('scroll', loadMore);
        window.addEventListener('scroll', loadMore);
        return () => window.removeEventListener('scroll', loadMore);
    }, [loading, loadMore]);

    return (
        <>
            {indexes.map((index) => (
                <FeedItem index={index} setLoading={setLoading} zip={zip} />
            ))}
            <div ref={bottomRef} className="mt-3 text-dark">
                <Spinner animation="grow" />
            </div>
        </>
    );
};

export default Feed;
