import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Button, Form, FormControl, Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Dialog from "../Doalog";
import axios from "axios";

const AdminSwitchAccount = (props) => {
  const [cookies, setCookie] = useCookies(["user_id"]);
  const [formData, setFormData] = useState({
    id: null,
  });
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const submit = async () => {
    if (!formData.id.length) {
      showDialog("Please enter a form ID");
    } else {
      showDialog(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>,
        "Login"
      );

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourfight.online/adminSwitchAccount",
          qs.stringify({
            id: formData.id,
            userId: cookies.user_id,
          }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data?.error) showDialog(response.data?.error);
          else {
            setCookie("user_id", response.data.id, {
              maxAge: 90 * 24 * 60 * 60,
            });
            window.location = "/mypetitions";
          }
        });
    }
  };

  if (cookies.user_id !== "72ee332c-4f84-4516-90f1-036a6805fa43")
    return <Navigate to="/" />;
  else
    return (
      <div>
        {" "}
        <Dialog
          show={dialog}
          onClick={hideDialog}
          message={dialogMessage}
          title={dialogTitle}
        />
        <Form>
          <FormControl
            className="my-3"
            placeholder="form ID"
            name="id"
            id="id"
            onChange={(e) => {
              setFormData({ id: e.target.value });
            }}
          />

          <Button className="w-100 p-3" onClick={() => submit()}>
            Login
          </Button>
        </Form>
      </div>
    );
};

export default AdminSwitchAccount;