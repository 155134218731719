import React from 'react';

const ProgressBar = ({ className, label, percent }) => {
    if (!percent) return null;
    return (
        <div className={`bg-dark text-start w-100 ${className}`}>
            <div
                className="bg-primary ps-2 py-1 text-nowrap text-white"
                style={{ width: `${percent}%` }}
            >
                {label}
            </div>
        </div>
    );
};

export default ProgressBar;
