import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Row } from "react-bootstrap";
import SportsMatchScore from "./SportsMatchScore";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../Loading";
import { ChevronDoubleLeft, EmojiFrown } from "react-bootstrap-icons";
import DatePickerFull from "../DatePickerFull";
import SportsMatchBoxScore from "./SportsMatchBoxScore";

const SportsURL = (props) => {
  const [league] = useState(props?.league);
  const [category] = useState(props?.category || "scores");
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date().getTime());
  const [matches, setMatches] = useState(null);
  const [match, setMatch] = useState(null);
  const [emptyTag] = useState(document.createElement("div"));

  const getTag = useCallback(
    (xml, tag) => {
      tag = Array.isArray(tag) ? tag : [tag];
      const mi = tag.length - 1;

      return tag.reduce(
        (a, b, i) =>
          i === mi
            ? [...(a || emptyTag).getElementsByTagName(b)]
            : (a || emptyTag).getElementsByTagName(b)[0],
        xml
      );
    },
    [emptyTag]
  );

  const getAttr = useCallback(
    (xml, attr) => {
      xml = Array.isArray(xml) ? xml[0] : xml;
      attr = Array.isArray(attr) ? attr : [attr];

      return attr.reduce(
        (a, b) => (!!a ? a : (xml || emptyTag).getAttribute(b)),
        null
      );
    },
    [emptyTag]
  );

  const childProps = { getTag, getAttr, setMatch, ...props };

  useEffect(() => {
    const getJSON = async () => {
      axios
        .get(
          `https://api.mobubby.com/sports?league=${league}&category=${category}&date=${`${date.getFullYear()}-${date.getMonth() +
            1}-${date.getDate()}`}`
        )
        .then((response) => {
          if (response.data) {
            const xmlDoc = new DOMParser().parseFromString(
              response.data,
              "text/xml"
            );

            const newMatches = getTag(xmlDoc, ["category", "match"]);
            setMatches(newMatches);

            if (props?.mid) {
              const foundMatches = newMatches
                .map((match) =>
                  getAttr(match, ["contestID", "id"]) === props?.mid
                    ? match
                    : null
                )
                .filter((a) => a);

              if (props?.mid && foundMatches) setMatch(foundMatches[0]);
            }
          } else setMatches([]);

          setTimeout(() => setTime(new Date().getTime()), 60000);
        });
    };

    getJSON();
  }, [league, category, date, time, getTag, getAttr, props?.mid]);

  if (match) {
    return (
      <>
        <Row className="align-items-center">
          <Col>
            <Button
              variant="link"
              onClick={() => setMatch(null)}
              className="fs-1 text-decoration-none text-dark"
            >
              <ChevronDoubleLeft />
              {props?.caption}
            </Button>
          </Col>
          <Col md={3}>
            <DatePickerFull
              selected={date}
              onChange={(newDate) => {
                setMatches(null);
                setMatch(null);
                setDate(newDate);
              }}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <SportsMatchBoxScore match={match} {...childProps} />
          </Col>
        </Row>
        <Row>
          <Col> {props.displayEvents(match, getTag, getAttr)}</Col>
        </Row>
      </>
    );
  }

  return (
    <>
      {!props?.scroll && (
        <Row className="align-items-center">
          <Col>
            <div className="fs-1">{props?.caption}</div>
          </Col>
          <Col md={3}>
            <DatePickerFull
              selected={date}
              onChange={(newDate) => {
                setMatches(null);
                setMatch(null);
                setDate(newDate);
              }}
            />
          </Col>
        </Row>
      )}
      {!matches && <Loading />}
      {matches && !matches.length && (
        <div className="fs-1 mt-3 text-center">
          <EmojiFrown /> No games available. Please try another date.
        </div>
      )}
      {matches && !!matches.length && (
        <Row className={props?.scroll ? "overflowXOnly" : ""}>
          <Col>
            <Row className={props?.scroll ? "flex-nowrap g-0" : ""}>
              {matches.map((match) => (
                <Col
                  id={getAttr(match, ["contestID", "id"])}
                  key={getAttr(match, ["contestID", "id"])}
                  md={3}
                  className="mb-xs-3"
                >
                  <SportsMatchScore match={match} {...childProps} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SportsURL;