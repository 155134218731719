import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { PencilSquare, PlusCircleFill } from "react-bootstrap-icons";

const QuestionnaireList = (props) => {
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/questionnaireList/id/${props.id}`)
      .then((response) => setData(response.data));
  }, [props.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return false;

  return (
    <div>
      <a className="mt-3 btn btn-primary" href="questionnaireEdit">
        <PlusCircleFill size="1.25em" />
        &nbsp;Create New Questionnaire
      </a>

      {data.map((questionnaire, index) => (
        <Card className="mt-3" key={`questionnaire${index}`}>
          <Card.Body>
            <Row>
              <Col xs={10}>
                <Card.Title>{questionnaire.name}</Card.Title>
              </Col>
              <Col xs={2}>
                <div className="float-none float-md-end">
                  <a
                    className="text-decoration-none text-dark me-3"
                    href={"/questionnaireEdit?id=" + questionnaire.id}
                  >
                    <PencilSquare size="1.5em" />
                  </a>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default QuestionnaireList;