import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Accordion, Card, Table } from "react-bootstrap";

const FormRep = (props) => {
  const [data, setData] = useState(null);

  const formatText = (text, officialName, officialTitle) => {
    return encodeURIComponent(
      (text || "")
        .replace("{email}", props.email ? props.email : "{email}")
        .replace(
          "{firstName}",
          props.firstName ? props.firstName : "{firstName}"
        )
        .replace(
          "{lastInitial}",
          props.lastName
            ? `${props.lastName.substring(0, 1).toUpperCase()}.`
            : "{lastInitial}"
        )
        .replace(
          "{officialName}",
          officialName ? officialName : "{officialName}"
        )
        .replace(
          "{officialTitle}",
          officialTitle ? officialTitle : "{officialTitle}"
        )
        .replace("{zip}", props.zip ? props.zip : "{zip}")
    );
  };

  const getDivision = async (index) => {
    if (index !== null) {
      const key = data[index].key;

      if (!data[index]?.reps) {
        await axios
          .get(
            `https://www.googleapis.com/civicinfo/v2/representatives/${encodeURIComponent(
              key
            )}?key=AIzaSyDeQkIK5BbaDtu4rcQfkJeyiZ5iyfsistw`
          )
          .then((response) => {
            let newData = [...data];
            newData[index] = { ...newData[index], reps: response.data };
            setData(newData);
          });
      }
    }
  };

  const _getData = useCallback(async () => {
    if (props?.zip)
      await axios
        .get(
          `https://www.googleapis.com/civicinfo/v2/representatives?key=AIzaSyDeQkIK5BbaDtu4rcQfkJeyiZ5iyfsistw&address=${
            props.zip
          }`
        )
        .then((response) => {
          setData(
            Object.keys(response.data.divisions)
              .map((key) => {
                return { ...response.data.divisions[key], key };
              })
              .reduce((a, b) => [...a, b], [])
              .sort((a, b) =>
                a.officeIndices[0] <= b.officeIndices[0] ? 1 : -1
              )
          );
        });
  }, [props.zip]);

  useEffect(() => {
    _getData();
  }, [_getData]);

  if (!data) return false;

  return (
    <Card {...props}>
      <Card.Header className="rep" as="h4">
        {props.emailSubject
          ? "Click an official's email to send them the email! We must make sure our concerns are heard and met!"
          : `Your Officials for Zip ${props.zip}`}
      </Card.Header>
      <Card.Body>
        <Accordion flush onSelect={getDivision}>
          {data.map((division, index) => {
            return (
              <Accordion.Item key={division.key} eventKey={index}>
                <Accordion.Header>
                  <h5>{division.name}</h5>
                </Accordion.Header>
                <Accordion.Body>
                  {division?.reps && (
                    <Table hover responsive borderless size="sm">
                      <thead>
                        <th>Office</th>
                        <th>Name</th>
                        <th>Party</th>
                        <th>Phone</th>
                        <th>Email</th>
                      </thead>
                      <tbody>
                        {division.reps.offices
                          .sort((a, b) => (a.name >= b.name ? 1 : -1))
                          .map((rep) => (
                            <tr>
                              <td>
                                {division.reps.officials[rep.officialIndices[0]]
                                  .urls ? (
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={`${
                                      division.reps.officials[
                                        rep.officialIndices[0]
                                      ].urls[0]
                                    }`}
                                  >
                                    {rep.name}
                                  </a>
                                ) : (
                                  rep.name
                                )}
                              </td>
                              <td>
                                {
                                  division.reps.officials[
                                    rep.officialIndices[0]
                                  ].name
                                }
                              </td>
                              <td>
                                {
                                  division.reps.officials[
                                    rep.officialIndices[0]
                                  ].party
                                }
                              </td>
                              <td>
                                {division.reps.officials[rep.officialIndices[0]]
                                  .phones ? (
                                  <a
                                    href={`mailto:${
                                      division.reps.officials[
                                        rep.officialIndices[0]
                                      ].phones[0]
                                    }`}
                                  >
                                    {
                                      division.reps.officials[
                                        rep.officialIndices[0]
                                      ].phones[0]
                                    }
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>
                                {division.reps.officials[rep.officialIndices[0]]
                                  .emails ? (
                                  <a
                                    href={`mailto:${
                                      division.reps.officials[
                                        rep.officialIndices[0]
                                      ].emails[0]
                                    }?from=${
                                      props.email
                                    }${!!props.emailSubject &&
                                      `&subject=${formatText(
                                        props.emailSubject,
                                        division.reps.officials[
                                          rep.officialIndices[0]
                                        ].name,
                                        rep.name
                                      )}`}${!!props.emailBody &&
                                      `&body=${formatText(
                                        props.emailBody,
                                        division.reps.officials[
                                          rep.officialIndices[0]
                                        ].name,
                                        rep.name
                                      )}\r\n`}`}
                                  >
                                    {
                                      division.reps.officials[
                                        rep.officialIndices[0]
                                      ].emails[0]
                                    }
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default FormRep;