import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TooltipOverlay = (props) => {
  return (
    <OverlayTrigger
      key="top"
      placement="top"
      overlay={<Tooltip id={`tooltip-top`}>{props.tip}</Tooltip>}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default TooltipOverlay;