import React from "react";
import { Clipboard } from "react-bootstrap-icons";
import TooltipOverlay from "./TooltipOverlay";

const CopyText = (props) => {
  const copy = (props) => {
    if (navigator.clipboard.writeText)
      navigator.clipboard.writeText(props.text);
    else navigator.clipboard.setData("Text", props.text);
  };

  return (
    <TooltipOverlay tip={props.tip}>
      <Clipboard
        className="mx-3 pointer"
        size="1.5em"
        onClick={() => copy(props)}
      />
    </TooltipOverlay>
  );
};

export default CopyText;