import { Col, Placeholder, Row } from "react-bootstrap";

const MarketPlaceholder = () => (
  <>
    <Row className="mt-5">
      <Col xs={6}>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} className="py-5" />
        </Placeholder>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col xs={7}>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} className="py-3" />
        </Placeholder>
      </Col>
      <Col xs={5}>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} className="py-3" />
        </Placeholder>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col xs={4}>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} className="py-3" />
        </Placeholder>
      </Col>
      <Col xs={4}>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} className="py-3" />
        </Placeholder>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col xs={12}>
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} className="py-3" />
        </Placeholder>
      </Col>
    </Row>
  </>
);

export default MarketPlaceholder;