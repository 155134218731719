import { useState } from "react";
import { Button } from "react-bootstrap";
import { XCircle } from "react-bootstrap-icons";

const NewsThanks = (props) => {
  const [show, setShow] = useState(true);

  return (
    show && (
      <div className="fixed-bottom bg-dark text-light p-3 w-100">
        <Button variant="dark" className="float-end">
          <XCircle onClick={() => setShow(false)} />
        </Button>
        <div className="fs-5">Thank you for joining the fight!</div>
        <hr />
        <small className="prizeNotice">
          Stay informed and engaged. Browse articles and vote for them as{" "}
          <i>Truth</i> or <i>Trash</i>.
        </small>
      </div>
    )
  );
};

export default NewsThanks;