import { Col, Row } from "react-bootstrap";

const NewsAdSection = (props) => {
  /*const ads = {
    "Public Health": [
      {
        url: "https://659c5a0br5ic0xc7-esl468r71.hop.clickbank.net",
      },
    ],
  };*/
  return (
    <Row>
      <Col />
    </Row>
  );
};

export default NewsAdSection;