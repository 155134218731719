import React from 'react';
import { FormGroup } from 'react-bootstrap';
import ProgressBar from './ProgressBar';

function FormPollResults(props) {
    let pollQuestion = null;

    //Retrieve current poll questions
    if (props.poll && props.pollAnswer) {
        let buffer = {};

        props.poll
            .replaceAll('\r', '')
            .split('\n\n')
            .forEach((question) => {
                const lines = question.split('\n');

                lines.forEach((line, index) => {
                    if (!index)
                        buffer = {
                            ...buffer,
                            [line.trim()]: { answer: [], tally: 0 }
                        };
                    else
                        buffer[lines[0].trim()].answer = {
                            ...buffer[lines[0].trim()].answer,
                            [line.trim()]: 0
                        };
                });
            });

        //Retrieve poll answer totals
        props.pollAnswer.forEach((pollResult) => {
            const tally = pollResult.tally;

            pollResult.polls
                .replaceAll('\r', '')
                .split('\n\n')
                .forEach((poll) => {
                    const lines = poll.split('\n');

                    if (buffer.hasOwnProperty(lines[0].trim())) {
                        const question = lines.shift().trim();
                        lines.forEach((answer) => {
                            if (
                                buffer[question].answer.hasOwnProperty(
                                    answer.trim()
                                )
                            ) {
                                buffer[question].tally += parseInt(tally);
                                buffer[question].answer[answer.trim()] +=
                                    parseInt(tally);
                            }
                        });
                    }
                });
        });

        //Sort
        Object.keys(buffer)
            .filter((question) => question)
            .forEach((question) => {
                buffer[question].answer = Object.keys(buffer[question].answer)
                    .sort((a, b) => {
                        return (
                            buffer[question].answer[b] -
                            buffer[question].answer[a]
                        );
                    })
                    .map((key) => {
                        return { [key]: buffer[question].answer[key] };
                    })
                    .reduce((r, c) => {
                        return { ...r, ...c };
                    }, []);
            });

        pollQuestion = buffer;
    }

    if (!pollQuestion) return false;

    return (
        <div className={`my-3`}>
            {pollQuestion &&
                Object.entries(pollQuestion).map(
                    (question, index) =>
                        question[1].tally >= 1 && (
                            <FormGroup key={index}>
                                <div
                                    className={`${props.className}`}
                                    key={'pollAnswer' + index}
                                >
                                    {question[0]}
                                </div>
                                {Object.entries(question[1].answer).map(
                                    (answer, index) => (
                                        <ProgressBar
                                            className="mb-1"
                                            key={index}
                                            label={
                                                answer[0].replace(
                                                    /\{[^}]*\}/g,
                                                    ''
                                                ) +
                                                `: ${Math.round(
                                                    (answer[1] /
                                                        question[1].tally) *
                                                        100
                                                )}%`
                                            }
                                            percent={
                                                (answer[1] /
                                                    question[1].tally) *
                                                100
                                            }
                                        />
                                    )
                                )}
                            </FormGroup>
                        )
                )}
        </div>
    );
}

export default FormPollResults;
