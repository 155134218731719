import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Button, ButtonGroup, Card, Col, Dropdown, Row } from "react-bootstrap";
import { PencilSquare, PlusCircleFill } from "react-bootstrap-icons";

function IntegrationList(props) {
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/integrationList/id/${props.id}`)
      .then((response) => setData(response.data));
  }, [props.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return false;

  return (
    <div>
      <Dropdown as={ButtonGroup} className="mt-3">
        <Button href="/integrationEdit">
          <PlusCircleFill size="1.25em" />
          &nbsp;Create New Integration
        </Button>

        <Dropdown.Toggle split />

        <Dropdown.Menu>
          <Dropdown.Item href="/integrationEdit?template=constantcontact">
            Constant Contact
          </Dropdown.Item>
          <Dropdown.Item href="/integrationEdit?template=mailchimp">
            Mail Chimp
          </Dropdown.Item>
          <Dropdown.Item href="/integrationEdit?template=paypal">
            PayPal
          </Dropdown.Item>
          <Dropdown.Item href="/integrationEdit?template=stripe">
            Stripe
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Card className="mt-3">
        <Card.Body>
          Integrations are an advanced feature that allows your Our Fight
          petitions to relay information to 3rd party systems like Mail Chimp or
          PayPal. There are two types of integrations: Email and Merchant. Email
          integrations can be used to automatically send email addresses to an
          ESP (Email Service Provider). Whereas mechant integrations are used to
          recieve payment after a user has signed a petition.
        </Card.Body>
      </Card>

      {data.map((integration, index) => (
        <Card className="mt-3" key={`integration${index}`}>
          <Card.Body>
            <Row>
              <Col xs={10}>
                <Card.Title>{integration.name}</Card.Title>
              </Col>
              <Col xs={2}>
                <div className="float-none float-md-end">
                  <a
                    className="text-decoration-none text-dark me-3"
                    href={"/integrationEdit?id=" + integration.id}
                  >
                    <PencilSquare size="1.5em" />
                  </a>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default IntegrationList;