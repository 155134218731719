import React, { useEffect, useState } from "react";
import axios from "axios";
import { Badge, Card } from "react-bootstrap";
import MarketLineGraph from "./MarketLineGraph";
import { Textfit } from "react-textfit";
import { useRef } from "react";

const MarketCard = (props) => {
  const ref = useRef(null);
  const [time, setTime] = useState(new Date().getTime());
  const [json, setJSON] = useState(null);
  const [end, setEnd] = useState(null);
  const [diff, setDiff] = useState(null);

  const formatName = (name) => name.split(" ")[0];

  const formatNumber = (number) =>
    parseFloat(number)
      .toLocaleString()
      .replace(".00", "");

  useEffect(() => {
    if (ref?.current?.clientHeight && props.setHeight)
      props.setHeight(ref.current.clientHeight);
  }, [ref?.current?.clientHeight, props]);

  useEffect(() => {
    const getJSON = async () =>
      axios
        .get(`https://api.mobubby.com/Market?class=rt&symbol=${props.symbol}`)
        .then((response) => {
          const newJson = JSON.parse(response.data);
          const newEnd = parseFloat(newJson.values[0].close);
          const newStart = parseFloat(
            newJson.values[newJson.values.length - 1].previous_close
          );

          setJSON(newJson);
          setEnd(newEnd);
          setDiff(newEnd - newStart);

          setTimeout(() => setTime(new Date().getTime()), 60000);
        });

    getJSON();
  }, [props.symbol, time]);

  if (!json) return null;

  return (
    <a
      ref={ref}
      className="market d-block"
      href={`/market?symbol=${props.symbol}`}
    >
      <Card>
        <Card.Header>
          <strong>
            <Textfit
              style={{ display: "inline-block", height: "100%" }}
              forceSingleModeWidth={true}
              min={10}
              max={16}
            >
              {formatName(json.symbol.instrument_name)}
            </Textfit>
          </strong>
          <Textfit
            className="d-inline-block d-md-none ms-3"
            style={{ height: "100%" }}
            forceSingleModeWidth={true}
            min={10}
            max={16}
          >
            {formatNumber(end)}
          </Textfit>
          <Badge
            pill
            className="float-end"
            bg={diff >= 0 ? "success" : "danger"}
          >
            <Textfit
              style={{ height: "100%" }}
              forceSingleModeWidth={true}
              min={12}
              max={16}
            >
              {formatNumber(diff)}
            </Textfit>
          </Badge>
        </Card.Header>
        <Card.Body className="fs-3 d-none d-md-block">
          <Textfit
            style={{ height: "100%" }}
            forceSingleModeWidth={true}
            min={10}
            max={16}
          >
            {formatNumber(end)}
          </Textfit>
          <div className="chartSmall">
            <MarketLineGraph values={json.values} aspect={false} />
          </div>
        </Card.Body>
      </Card>
    </a>
  );
};

export default MarketCard;