import { Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import ReactHtmlParser from "react-html-parser";
import NewsAdSection from "../NewsAdSection";
import LayoutItem from "./LayoutItem";

const LayoutArrayLargeCenter = (props) => {
  const first2 = props.links?.slice(1, 3);
  const middle = props.links?.slice(0, 1)[0];
  const last2 = props.links?.slice(3, 5);

  return (
    <Container fluid className="p-0" name={props.header} id={props.header}>
      <div className="display-3 border-bottom mb-3 text-start">
        {ReactHtmlParser(props.header.replace("%20", " "))}
      </div>
      <Row>
        <Col sm={12} md={3}>
          <Row>
            {first2.map((article, index) => (
              <Col key={`article-${article.id}`} sm={12} className="mb-3">
                <LayoutItem
                  article={article}
                  className="cardSmall"
                  {...props}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row>
            <Col className="mb-3">
              <LayoutItem article={middle} className="cardLarge" {...props} />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={3}>
          <Row>
            {last2.map((article, index) => (
              <Col key={`article-${article.id}`} sm={12} className="mb-3">
                <LayoutItem
                  article={article}
                  className="cardSmall"
                  {...props}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <NewsAdSection {...props} />
        {props.showMore && (
          <div>
            <a
              href={props.showMore}
              className="text-decoration-none h3 float-end"
            >
              Show More <ArrowRightCircleFill />
            </a>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default LayoutArrayLargeCenter;