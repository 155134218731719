import React from "react";
import { Card } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const FormEmailTemplate = (props) => {
  const formatText = (text) => {
    return (text || "")
      .replaceAll("\r", "<br/>")
      .replace("{email}", props.email ? props.email : "{email}")
      .replace("{n1}", props.n1 ? props.n1 : "{n1}")
      .replace(
        "{lastInitial}",
        props.n2
          ? `${props.n2.substring(0, 1).toUpperCase()}.`
          : "{lastInitial}"
      )
      .replace("{official}", props.official ? props.official : "{official}")
      .replace("{zip}", props.zip ? props.zip : "{zip}")
      .replace("{email}", "<i>&lt;my email address&gt;</i>")
      .replace("{n1}", "<i>&lt;my first name&gt;</i>")
      .replace("{lastInitial}", "<i>&lt;initial of my last name&gt;</i>")
      .replace("{officialName}", "<i>&lt;official's name&gt;</i>")
      .replace("{officialTitle}", "<i>&lt;official's title&gt;</i>")
      .replace("{zip}", "<i>&lt;my zipcode&gt;</i>");
  };

  return (
    <Card>
      <Card.Body>
        {props?.title && (
          <Card.Title className="mb-5">{props.title}</Card.Title>
        )}
        <strong className="d-block mb-3">Subject</strong>
        {ReactHtmlParser(formatText(props.emailSubject))}
        <strong className="d-block mt-3 mb-3">Body</strong>
        {ReactHtmlParser(formatText(props.emailBody))}
      </Card.Body>
    </Card>
  );
};

export default FormEmailTemplate;