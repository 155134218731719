import React from "react";

function FormFooter(props) {
  const url =
    props.url && !props.url.replace(/^\s+/gi, "").match(/^https*:\/\//gi)
      ? `http://${props.url.replace(/^\s+/gi, "")}`
      : props.url;

  if (props.text && props.url)
    return (
      <div className="footer text-center pb-3 fs-6">
        <a rel="noreferrer" href={url} target="_blank">
          {`Organized by ${props.text}`}
        </a>
      </div>
    );
  else if (props.text)
    return (
      <div className="footer text-center pb-3 fs-6">
        <h5>{props.text}</h5>
      </div>
    );
  else return null;
}

export default FormFooter;
