import React from "react";

function FormLegalese(props) {
  return (
    <div className="text-center mb-3">
      <small className="text-center fw-bold">
        By clicking "{props.label || "Submit"}"  below, I affirm my agreement with this site's Terms of Use and Privacy Policy.{props.additionalLegalese}
      </small>
    </div>
  );
}

export default FormLegalese;