import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import axios from "axios";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Nav,
  Navbar,
  PageItem,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import {
  ArrowDown,
  ArrowUp,
  CloudArrowDown,
  FilePdf,
} from "react-bootstrap-icons";
import FormPDF from "./FormPDF";
import TooltipOverlay from "../TooltipOverlay";

function FormInteractions(props) {
  const [cookies] = useCookies(["user_id"]);
  const [data, setData] = useState(null);
  const [orderBy, setOrderBy] = useState("createdDateTime");
  const [sort, setSort] = useState("DESC");
  const [dedupe, setDedupe] = useState(true);
  const [search, setSearch] = useState("");
  const [weight, setWeight] = useState(0);
  const [rowCount, setRowCount] = useState(10);
  const [current, setCurrent] = useState("");
  const [showPDF, setShowPDF] = useState(false);

  const updateOrderBy = (field) => {
    if (orderBy === field) setSort(sort === "ASC" ? "DESC" : "ASC");
    else {
      setOrderBy(field);
      setSort("ASC");
    }
  };

  const getData = useCallback(async () => {
    await axios
      .get(
        "https://api.ourfight.online/formInteractions?formId=" +
          props.formId +
          "&sort=" +
          orderBy +
          "%20" +
          sort +
          "&dedupe=" +
          dedupe +
          "&search=" +
          search +
          "&weight=" +
          weight +
          "&rowCount=" +
          rowCount +
          "&current=" +
          current
      )
      .then((response) => {
        let pageItem = [
          <Pagination.First
            key="pageItemHeader"
            onClick={() => setCurrent(1)}
          />,
        ];

        let pageOption = [];

        for (var c = 1; c <= parseInt(response.data.pages); c++) {
          const page = c;

          pageItem.push(
            <PageItem
              key={`pageItem${c}`}
              onClick={() => {
                setCurrent(page);
              }}
            >
              {c}
            </PageItem>
          );

          pageOption.push(<option key={`pageItem${c}`}>{c}</option>);
        }

        pageItem.push(
          <Pagination.Last
            key="pageItemFooter"
            onClick={() => setCurrent(response.data.pages)}
          />
        );

        setData({
          ...response.data,
          pageItem: pageItem,
          pageOption: pageOption,
        });
      });
  }, [props.formId, orderBy, sort, dedupe, search, weight, rowCount, current]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data || !data.form.fk_accountId) return null;
  else if (data.form.fk_accountId !== cookies.user_id)
    return <Navigate to="/" />;
  else
    return (
      <div>
        <FormPDF
          formId={props.formId}
          orderBy={orderBy}
          sort={sort}
          dedupe={dedupe}
          search={search}
          weight={weight}
          show={showPDF}
          onClick={() => setShowPDF(false)}
        />

        <Card className="mt-3">
          <Card.Header>
            <Navbar expand="lg">
              <Container fluid>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                    <Nav.Item className="mr-3">
                      <TooltipOverlay tip="Search">
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          id="search"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </TooltipOverlay>
                    </Nav.Item>
                    <Nav.Item className="mr-3">
                      <TooltipOverlay tip="Choose minimum weight">
                        <Form.Select
                          defaultValue={weight}
                          onChange={(e) => {
                            setWeight(e.target.value);
                          }}
                        >
                          <option value={0}>0%+</option>
                          <option value={10}>10%+</option>
                          <option value={20}>20%+</option>
                          <option value={30}>30%+</option>
                          <option value={40}>40%+</option>
                          <option value={50}>50%+</option>
                          <option value={60}>60%+</option>
                          <option value={70}>70%+</option>
                          <option value={80}>80%+</option>
                          <option value={90}>90%+</option>
                          <option value={100}>100%</option>
                        </Form.Select>
                      </TooltipOverlay>
                    </Nav.Item>
                    <Nav.Item className="mr-3">
                      <TooltipOverlay tip="Choose # of rows per page">
                        <Form.Select
                          defaultValue={rowCount}
                          onChange={(e) => {
                            setRowCount(e.target.value);
                          }}
                        >
                          <option value={10}>10</option>
                          <option value={20}>25</option>
                          <option value={50}>50</option>
                        </Form.Select>
                      </TooltipOverlay>
                    </Nav.Item>
                    <Nav.Item className="mr-3">
                      <TooltipOverlay tip="Check to dedupe">
                        <Form.Check
                          className="h5 mt-2"
                          type="switch"
                          label="Dedupe"
                          id="dedupe"
                          defaultChecked={dedupe}
                          onClick={(e) => setDedupe(e.target.checked)}
                        />
                      </TooltipOverlay>
                    </Nav.Item>
                    <Nav.Link
                      className="mr-3"
                      target="_blank"
                      href={`https://api.ourfight.online/export?fk_accountId=${
                        cookies.user_id
                      }&formId=${
                        props.formId
                      }&search=${search}&weight=${weight}&sort=${orderBy} ${sort}&dedupe=${dedupe}&rowCount=${dedupe}`}
                    >
                      <TooltipOverlay tip="Generate CSV">
                        <CloudArrowDown size="1.5em" />
                      </TooltipOverlay>
                    </Nav.Link>
                    <Nav.Link className="mr-3" onClick={() => setShowPDF(true)}>
                      <TooltipOverlay tip="Generate PDF of signatures">
                        <FilePdf size="1.5em" />
                      </TooltipOverlay>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("weight")}
                  >
                    Weight&nbsp;
                    {orderBy === "weight" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("firstName")}
                  >
                    First&nbsp;
                    {orderBy === "firstName" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("lastName")}
                  >
                    Last&nbsp;
                    {orderBy === "lastName" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th className="pointer" onClick={() => updateOrderBy("zip")}>
                    Zip&nbsp;
                    {orderBy === "zip" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("email")}
                  >
                    Email&nbsp;
                    {orderBy === "email" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("pledgeAmount")}
                  >
                    Pledge&nbsp;
                    {orderBy === "pledgeAmount" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("donationAmount")}
                  >
                    Donation&nbsp;
                    {orderBy === "donationAmount" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("createdDateTime")}
                  >
                    Time&nbsp;
                    {orderBy === "createdDateTime" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("donationDateTime")}
                  >
                    Donation Time&nbsp;
                    {orderBy === "donationDateTime" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("referrer")}
                  >
                    Referrer&nbsp;
                    {orderBy === "referrer" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                  <th
                    className="pointer"
                    onClick={() => updateOrderBy("polls")}
                  >
                    Polls&nbsp;
                    {orderBy === "polls" &&
                      (sort === "ASC" ? <ArrowUp /> : <ArrowDown />)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.rows.map((row, index) => (
                  <tr key={`row${index}`}>
                    <td>{row.weight}</td>
                    <td>{row.firstName}</td>
                    <td>{row.lastName}</td>
                    <td>{row.zip}</td>
                    <td>{row.email}</td>
                    <td>{`$${Intl.NumberFormat("en-IN").format(
                      row.pledgeAmount
                    )}`}</td>
                    <td>{`$${Intl.NumberFormat("en-IN").format(
                      row.donationAmount
                    )}`}</td>
                    <td>{row.createdDateTime}</td>
                    <td>{row.donationDateTime}</td>
                    <td>{row.referrer}</td>
                    <td>{row.polls}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <Row className="align-items-center">
              <Col xs={12} md={9} lg={11} className="text-muted">
                Viewing {(data.current - 1) * data.rowCount + 1} through{" "}
                {Math.min(data.current * data.rowCount, data.total)} of{" "}
                {data.total}
              </Col>
              <Col xs={12} md={3} lg={1}>
                {data.pageItem.length < 10 && (
                  <Pagination className="float-end">{data.pageItem}</Pagination>
                )}
                {data.pageItem.length >= 10 && (
                  <FloatingLabel label="Page">
                    <Form.Select
                      placeholder="Page"
                      value={current}
                      onChange={(event) => {
                        setCurrent(event.target.value);
                      }}
                    >
                      {data.pageOption}
                    </Form.Select>
                  </FloatingLabel>
                )}
              </Col>
            </Row>
          </Card.Footer>
        </Card>
        <br />
      </div>
    );
}

export default FormInteractions;