import React, { useCallback, useState } from "react";
import axios from "axios";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  FormControl,
  Row,
  Spinner,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import Dialog from "../Doalog";
import FormLegalese from "../Form/FormLegalese";
import textFilter from "../textFilter";
import {
  Check2Circle,
  ChevronDown,
  ChevronRight,
  Trash,
} from "react-bootstrap-icons";

function NewsFormFields(props) {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [showMoreFields, setShowMoreFields] = useState(false);

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message, title = "Error") => {
    setDialog(true);
    setDialogMessage(message);
    setDialogTitle(title);
  };

  const submit = useCallback(async () => {
    if (
      /^[^@\s]+@[^.]+(\.\w{2,})+$/g.test(props.formData.email) === false ||
      props.formData.email.length < 5 ||
      props.formData.email.length > 64
    ) {
      showDialog(
        "Please enter a valid email address between 5 and 64 characters"
      );
    } else {
      showDialog(
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>,
        "Loading"
      );

      const { data } = await axios.get(
        "https://api.zerobounce.net/v2/validate?api_key=845bc733d485445686347e2e6640c3de&email=" +
          encodeURIComponent(props.formData.email)
      );

      if (
        data.status.toLowerCase() === "abuse" ||
        data.status.toLowerCase() === "catch-all" ||
        data.status.toLowerCase() === "do_not_mail" ||
        data.status.toLowerCase() === "valid"
      ) {
        const qs = require("qs");
        await axios
          .post(
            "https://api.ourfight.online/urlCommentAdd",
            qs.stringify({
              urlId: props.formData.urlId,
              commentAs: props.formData.commentAs,
              truthTrash: props.formData.truthTrash,
              comment: props.formData.comment,
              email: props.formData.email,
              emailStatus: data.status.toLowerCase(),
              optIn: 1,
              referrer: document.referrer,
            }),
            { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
          )
          .then(async (response) => {
            hideDialog();
            props.setFormData({
              ...props.formData,
              commentAs: null,
              truthTrash: null,
              comment: null,
              email: null,
            });
            props?.setComments(response.data?.comments);
            props?.setVotes(response.data?.votes);
            if (props?.setShowSignModal) props?.setShowSignModal(false);

            //eslint-disable-next-line
            twq("event", "tw-oddyx-oddzg", {});
          });
      } else {
        showDialog(
          `${props.formData.email} is invalid. Please provide another email.`
        );
      }
    }
  }, [props]);

  return (
    <div>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title={dialogTitle}
      />
      <Row className="mb-3">
        <Col xs={12}>
          <FloatingLabel label="email">
            <FormControl
              name="email"
              id="email"
              type="email"
              value={props.formData?.email || ""}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  email: e.target.value,
                });
              }}
            />
          </FloatingLabel>
        </Col>
      </Row>

      <Row className="">
        <Col xs={12}>
          <FloatingLabel label="comment">
            <Form.Control
              as="textarea"
              name="comment"
              id="comment"
              rows={5}
              maxLength={255}
              value={props.formData?.comment || ""}
              onChange={(e) => {
                props.setFormData({
                  ...props.formData,
                  comment: textFilter(e.target.value),
                });
              }}
            />
          </FloatingLabel>
          <small className="float-end">
            {(props.formData.comment ?? "").length}/255
          </small>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col xs={12}>
          <Button
            variant="link"
            className="text-dark text-decoration-none w-100 text-start shadow-none p-0"
            onClick={() => setShowMoreFields(!showMoreFields)}
          >
            {!showMoreFields && (
              <>
                <ChevronRight /> Show more fields
              </>
            )}
            {showMoreFields && (
              <>
                <ChevronDown /> Show less fields
              </>
            )}
          </Button>
        </Col>
      </Row>

      {showMoreFields && (
        <Row className="mb-3">
          <Col xs={12}>
            <FloatingLabel label="pseudonym (optional)">
              <FormControl
                name="commentAs"
                id="commentAs"
                value={textFilter(props.formData?.commentAs || "", false)}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    commentAs: e.target.value,
                  });
                }}
              />
            </FloatingLabel>
          </Col>
        </Row>
      )}

      {showMoreFields && (
        <Row className="mb-3">
          <Col xs={12}>
            <small className="text-secondary">This article is:</small>

            <ToggleButtonGroup name="truthTrash" className="w-100">
              <ToggleButton
                variant="outline-secondary"
                id="truthTrash-1"
                type="radio"
                value={1}
                checked={props.formData.truthTrash === 1}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    truthTrash: e.currentTarget.value,
                  });
                }}
              >
                <Check2Circle />
                &nbsp; Truth
              </ToggleButton>
              <ToggleButton
                variant="outline-secondary"
                id="truthTrash-0"
                type="radio"
                value={-1}
                checked={props.formData.truthTrash === -1}
                onChange={(e) => {
                  props.setFormData({
                    ...props.formData,
                    truthTrash: e.currentTarget.value,
                  });
                }}
              >
                <Trash />
                &nbsp; Trash
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
      )}

      <Row className="pb-3">
        <Col className="text-center">
          <FormLegalese
            label="Comment"
            additionalLegalese={props.additionalLegalese}
          />

          <Button className="btn-sign w-100 p-1 fs-3" onClick={() => submit()}>
            Comment
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default NewsFormFields;