import React, { useCallback, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import { useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Navbar,
  ProgressBar,
  Row,
} from "react-bootstrap";
import {
  ArrowDownCircle,
  Facebook,
  HandIndex,
  Newspaper,
  Pinterest,
  Twitter,
} from "react-bootstrap-icons";
import Loading from "../Loading";
import { Helmet } from "react-helmet";
import NewsFormFields from "./NewsFormFields";
import NewsLinkGoogleAnalytics from "./NewsLinkGoogleAnalytics";
import SectionArray from "./Section/SectionArray";
import NewsPetitionAd from "./NewsPetitionAd";

const NewsLink = (props) => {
  const [article, setArticle] = useState(null);
  const [related, setRelated] = useState(null);
  const [formData, setFormData] = useState({
    urlId: null,
    commentAs: props?.commentAs,
    truthTrash: props?.truthTrash,
    comment: props?.comment,
    email: props?.email,
  });
  const [showSignModal, setShowSignModal] = useState(false);
  const [commentTally, setCommentTally] = useState(null);
  const [comments, setComments] = useState(null);
  const [commentStart, setCommentStart] = useState(0);
  const [votes, setVotes] = useState(null);

  const percentage = (subset, tally) => (subset ? (subset / tally) * 100 : 0);

  const getComments = useCallback((uCommentStart, uComments) => {
    try {
      const path = window?.location?.pathname?.split("/");
      const urlId = path[path.length - 1];

      axios
        .get(
          `https://api.ourfight.online/urlCommentList?urlId=${urlId}&start=${uCommentStart}`
        )
        .then((response) => {
          setCommentTally(parseInt(response.data?.tally));
          setComments([
            ...(uComments || []),
            ...(response.data?.comments ?? []),
          ]);
          setCommentStart(
            uCommentStart + (response.data?.comments ?? []).length
          );
          setVotes(response.data?.votes);
        });
    } catch (e) {
      console.error("[JSON Error]", e);
    }
  }, []);

  useEffect(() => {
    const getJSON = async () => {
      try {
        const path = window?.location?.pathname?.split("/");
        const urlId = path[path.length - 1];

        axios
          .get(`https://api.mobubby.com/link?id=${urlId}`)
          .then((response) => {
            setArticle(response.data[0]);
            setRelated([
              response.data?.[1],
              response.data?.[2],
              response.data?.[3],
            ]);
            setFormData({
              urlId: urlId,
              commentAs: props?.commentAs,
              truthTrash: props?.truthTrash,
              comment: props?.comment,
              email: props?.email,
            });
          });
      } catch (e) {
        console.error("[JSON Error]", e);
      }
    };

    getJSON();
  }, [props]);

  useEffect(() => {
    if (!(comments ?? []).length) getComments(commentStart, comments);
  }, [getComments, commentStart, comments]);

  if (!article?.url) return <Loading />;

  return (
    <div className="mt-3">
      <Row>
        <Col>
          <NewsLinkGoogleAnalytics id={article.id} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={8}>
          <div className="d-none d-sm-block">
            <Row>
              <Col sm={6}>
                <ButtonGroup>
                  <Button variant="primary" className="fs-4" href={article.url}>
                    <HandIndex />
                    &nbsp; View article
                  </Button>
                  <Button variant="secondary" className="fs-4" href="/news">
                    <Newspaper />
                    &nbsp; View more
                  </Button>
                </ButtonGroup>
              </Col>
              <Col sm={6} className="fs-2">
                <a
                  className="float-end text-dark text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`http://pinterest.com/pin/create/button/?url=${encodeURI(
                    `https://ourfight.online/news/link/${article.id}`
                  )}`}
                >
                  <Pinterest />
                </a>
                <a
                  className="float-end text-dark text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`http://twitter.com/share?url=${encodeURI(
                    `https://ourfight.online/news/link/${article.id}`
                  )}`}
                >
                  <Twitter />
                </a>
                <a
                  className="float-end text-dark text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                    `https://ourfight.online/news/link/${article.id}`
                  )}`}
                >
                  <Facebook />
                </a>
              </Col>
            </Row>
          </div>
          <div className="d-block d-sm-none">
            <Row>
              <Col xs={12}>
                <Button
                  variant="primary"
                  className="fs-4 w-100 mb-3"
                  href={article.url}
                >
                  <HandIndex />
                  &nbsp; View article
                </Button>
                <Button variant="secondary" className="fs-4 w-100" href="/news">
                  <Newspaper />
                  &nbsp; View more
                </Button>
              </Col>
              <Col xs={12} className="fs-2 text-center">
                <a
                  className="text-dark text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                    `https://ourfight.online/news/link/${article.id}`
                  )}`}
                >
                  <Facebook />
                </a>
                <a
                  className="text-dark text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`http://twitter.com/share?url=${encodeURI(
                    `https://ourfight.online/news/link/${article.id}`
                  )}`}
                >
                  <Twitter />
                </a>
                <a
                  className="text-dark text-decoration-none px-1"
                  rel="noreferrer"
                  target="_blank"
                  href={`http://pinterest.com/pin/create/button/?url=${encodeURI(
                    `https://ourfight.online/news/link/${article.id}`
                  )}`}
                >
                  <Pinterest />
                </a>
              </Col>
            </Row>
          </div>
          {(!!votes?.[0]?.truth || !!votes?.[0]?.trash) && (
            <ProgressBar className="mt-3">
              <ProgressBar
                striped
                animated
                now={percentage(votes[0].truth, votes[0].tally)}
                label={`${percentage(votes[0].truth, votes[0].tally)}% Truth`}
              />
              <ProgressBar
                striped
                animated
                variant="danger"
                now={percentage(votes[0].trash, votes[0].tally)}
                label={`${percentage(votes[0].trash, votes[0].tally)}% Trash`}
              />
            </ProgressBar>
          )}
          <Row className="mt-3">
            <Col xs={12}>
              <a className="fs-1 text-decoration-none" href={article.url}>
                <Image fluid src={article.imageURL} />
              </a>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <a
                className="fs-3 text-decoration-none text-dark"
                href={article.url}
              >
                {`(${article?.domain?.replace(
                  /^[^:]+:\/\/(www\.)*/,
                  ""
                )}) ${ReactHtmlParser(article.title.replace("%20", " "))}`}
              </a>
            </Col>
          </Row>
          {!!(comments ?? [])?.length && (
            <Row className="mt-3">
              <Col>
                <div className="fs-4">Latest Comments</div>
                {(comments ?? []).map((comment) => (
                  <Card key={comment.id} className="mb-3">
                    <Card.Body>
                      <Card.Title>
                        {comment.commentAs ?? "Anonymous"} on&nbsp;
                        {comment.createdDateTime}
                      </Card.Title>
                      <Card.Text>
                        {ReactHtmlParser(
                          comment.comment.replace(/\n/gi, "<br />")
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          )}
          {(comments ?? [])?.length < commentTally && (
            <Row className="mt-3">
              <Col className="text-end">
                <Button
                  variant="link"
                  className="fs-4 text-decoration-none"
                  onClick={() => getComments(commentStart, comments)}
                >
                  <ArrowDownCircle />
                  &nbsp;Show More
                </Button>
              </Col>
            </Row>
          )}
          {!(comments ?? [])?.length && (
            <Row className="mt-3">
              <Col>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Text>
                      Start the conversation! Add a comment explaining why you
                      agree or disagree with this article.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              <NewsPetitionAd />
            </Col>
          </Row>
          {related && (
            <Row>
              <Col>
                <div className="news">
                  <SectionArray
                    header="Read more"
                    articles={related}
                    votes={[]}
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Helmet>
                {/*prettier-ignore*/}
                <script type="text/javascript" src="//classic.avantlink.com/ppb.php?ppbid=7861&amp;ctc=&amp;open="></script>
              </Helmet>
            </Col>
          </Row>
        </Col>
        <Col sm={4} className="d-none d-sm-block">
          <Form>
            <NewsFormFields
              narrow
              stack
              article={article}
              formData={formData}
              setFormData={setFormData}
              source={props.source}
              setComments={setComments}
              setVotes={setVotes}
            />
          </Form>
        </Col>
      </Row>
      {!showSignModal && (
        <Navbar fixed="bottom" className="w-100 d-flex d-sm-none">
          <Button className="w-100" onClick={() => setShowSignModal(true)}>
            Click to Comment
          </Button>
        </Navbar>
      )}
      <Modal show={showSignModal} onHide={() => setShowSignModal(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Form>
            <NewsFormFields
              narrow
              stack
              article={article}
              formData={formData}
              setFormData={setFormData}
              source={props.source}
              setComments={setComments}
              setVotes={setVotes}
              setShowSignModal={setShowSignModal}
            />
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewsLink;