import React from "react";
import { useCookies } from "react-cookie";
import { Button, Container, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import {
  BoxArrowInLeft,
  BoxArrowRight,
  Diagram2,
  Facebook,
  FileRichtext,
  GraphUpArrow,
  Newspaper,
  PersonFill,
  PersonVideo2,
  QuestionCircleFill,
  //UiRadios,
} from "react-bootstrap-icons";

const Menu = (props) => {
  const [cookies, , removeCookie] = useCookies(["user_id", "tier"]);

  const logout = () => {
    removeCookie("user_id");
    window.location = "/";
  };

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Image src="/ourfight.png" width="100" alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {cookies.user_id === "72ee332c-4f84-4516-90f1-036a6805fa43" && (
                <NavDropdown title="Admin" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/adminAnalytics">
                    Analytics
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/adminGeography">
                    Geography
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/adminSwitchAccount">
                    Siwtch Account
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/sandbox">Sandbox</NavDropdown.Item>
                </NavDropdown>
              )}

              {cookies.user_id && (
                <Nav.Link
                  href="mypetitions"
                  className={
                    [
                      "/embed",
                      "/formEdit",
                      "/interactions",
                      "/mypetitions",
                      "/stats",
                    ].includes(window.location.pathname)
                      ? "text-white"
                      : ""
                  }
                >
                  <FileRichtext size="1.5em" />
                  &nbsp;Petitions
                </Nav.Link>
              )}
              {/*cookies.user_id && (
                <Nav.Link
                  href="myquestionnaires"
                  className={
                    ["/questionnaireEdit", "/myquestionnaires"].includes(
                      window.location.pathname
                    )
                      ? "text-white"
                      : ""
                  }
                >
                  <UiRadios size="1.5em" />
                  &nbsp;Questionnaires
                </Nav.Link>
                )*/}
              {cookies.user_id && (
                <Nav.Link
                  href="myintegrations"
                  className={
                    ["/integrationEdit", "/myintegrations"].includes(
                      window.location.pathname
                    )
                      ? "text-white"
                      : ""
                  }
                >
                  <Diagram2 size="1.5em" />
                  &nbsp;Integrations
                </Nav.Link>
              )}
              {cookies.user_id && (
                <Nav.Link
                  href="analytics"
                  className={
                    window.location.pathname === "/analytics"
                      ? "text-white"
                      : ""
                  }
                >
                  <GraphUpArrow size="1.5em" />
                  &nbsp;Analytics
                </Nav.Link>
              )}
              {cookies.user_id && (
                <Nav.Link
                  href="profile"
                  className={
                    window.location.pathname === "/profile" ? "text-white" : ""
                  }
                >
                  <PersonFill size="1.5em" />
                  &nbsp;Profile
                </Nav.Link>
              )}
              {!cookies.user_id && (
                <Nav.Link onClick={() => props.setShowLogin(true)}>
                  <BoxArrowInLeft size="1.5em" /> &nbsp;Login
                </Nav.Link>
              )}
              {cookies.user_id && (
                <Nav.Link
                  href=""
                  onClick={() => {
                    logout();
                  }}
                >
                  <BoxArrowRight size="1.5em" /> &nbsp;Logout
                </Nav.Link>
              )}
            </Nav>
            <Nav className="ms-auto">
              <Nav.Link
              as={Button}
                href="/formEdit"
                className="float-end text-light px-3 btn btn-danger fs-5 mr-2"
              >
                Create a Petition!
              </Nav.Link>
              <Nav.Item>
                <Nav.Link href="/help" className="float-start px-0 me-2">
                  <QuestionCircleFill color="white" size="2em" />
                </Nav.Link>
                <Nav.Link
                  href="/news"
                  className="float-start px-0 me-2"
                  target="_self"
                >
                  <Newspaper color="white" size="2em" />
                </Nav.Link>
                <Nav.Link
                  href="https://rumble.com/c/c-1658165"
                  className="float-start px-0 me-2"
                  target="_blank"
                >
                  <PersonVideo2 color="white" size="2em" />
                </Nav.Link>
                <Nav.Link
                  href="https://www.facebook.com/ourfightonline"
                  className="float-start px-0 me-2"
                  target="_blank"
                >
                  <Facebook color="white" size="2em" />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Menu;