import React from "react";
import {Col, Row } from "react-bootstrap";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import FormImage from "./FormImage";
import FormPollResults from "./FormPollResults";
import { toBoolean } from "../Shared";
import FormRep from "./FormRep";

function FormThankYou(props) {
  const pair = (array) => {
    const set = [];

    while (array.length) {
      set.push(array.slice(0, 2));
      array = array.slice(2);
    }

    return set;
  };

  const relayURL = props.relayURL
    ? new RegExp("^.+://", "i").test(props.relayURL)
      ? props.relayURL
      : `https://${props.relayURL}`
    : null;

  return (
    <div className="py-3">
      {!props.promoteCaucusRoom && (
        <div>
          <div className="mb-4 p-3 border h4 fw-normal text-center">
            Thank you for joining the fight!
            {relayURL !== null && (
              <div className="p-3">
                For more information about this petition click{" "}
                <a rel="noreferrer" target="_blank" href={relayURL}>
                  here
                </a>
                &nbsp;
                <a rel="noreferrer" target="_blank" href={relayURL}>
                  <BoxArrowUpRight />
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      {(!!props.showReps || props.emailSubject) && (
        <FormRep
          className="mt-3 mb-5"
          emailSubject={props?.emailSubject}
          emailBody={props?.emailBody}
          email={props?.email}
          firstName={props?.firstName}
          lastName={props?.lastName}
          zip={props?.zip}
        />
      )}
      <FormPollResults
        formId={props.formId}
        poll={props.poll}
        pollAnswer={props.pollAnswer}
      />
        <>
          <div>
            <FormRep />

            <div className="mb-4 p-3 border h4 fw-normal text-center">
              Thank you for joining the fight!
              {relayURL !== null && (
                <div className="p-3">
                  For more information about this petition click{" "}
                  <a rel="noreferrer" target="_blank" href={relayURL}>
                    here
                  </a>
                  &nbsp;
                  <a rel="noreferrer" target="_blank" href={relayURL}>
                    <BoxArrowUpRight />
                  </a>
                </div>
              )}
            </div>
          </div>
        </>
      
      {toBoolean(props.form?.showPetitions) && props.otherPetitions && (
        <div>
          {pair(props.otherPetitions).map((set, index) => (
            <Row key={"latestItem" + index}>
              <Col lg="6" className="my-3">
                <a href={"/" + set[0].id}>
                  <FormImage
                    src={
                      "https://static.ourfight.online/upload/" + set[0].image
                    }
                    label={set[0].title}
                    reduce={true}
                    small={true}
                  />
                </a>
              </Col>
              <Col lg="6" className="my-3">
                {set[1] && (
                  <a href={"/" + set[1].id}>
                    <FormImage
                      src={
                        "https://static.ourfight.online/upload/" + set[1].image
                      }
                      label={set[1].title}
                      reduce={true}
                      small={true}
                    />
                  </a>
                )}
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
}

export default FormThankYou;