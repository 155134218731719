import React, { useState } from "react";
import { Button, Image, Modal, ModalBody } from "react-bootstrap";

function FormImage(props) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <div>
      <Modal
        centered
        size="xl"
        show={showDialog}
        onClick={() => setShowDialog(false)}
      >
        <Modal.Header className="bg-dark text-primary">
          <Image src="/ourfight.png" width="75" alt="" />
          <Button className="btn-close btn-close-white"> </Button>
        </Modal.Header>
        <ModalBody className="text-center">
          <Image fluid src={props.src} />
        </ModalBody>
      </Modal>

      <div
        className={`text-center ${props.reduce ? "imageContainer" : ""} ${
          props.small ? "small" : ""
        }`}
      >
        <Image
          fluid
          src={props.src}
          onClick={() => props.expand && setShowDialog(true)}
        />

        {props.label && (
          <div
            className={`imageLabel text-start w-100 p-3 ${
              props.labelClassName
            }`}
          >
            {props.label}
          </div>
        )}
      </div>
    </div>
  );
}

export default FormImage;