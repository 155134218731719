import { Table } from "react-bootstrap";
import SportsURL from "./SportsURL";

const SportsMLB = (props) => {
  const displayEvents = (match, getTag, getAttr) => {
    const teamAway = getAttr(getTag(match, ["awayteam"]), "name");
    const teamHome = getAttr(getTag(match, ["hometeam"]), "name");

    return (
      <Table striped hover bordered responsive>
        <thead>
          <tr>
            <th>Inning</th>
            <th>Team</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {getTag(match, ["events", "event"]).map((event) => (
            <tr>
              <td>{getAttr(event, "inn")}</td>
              <td>
                {getAttr(event, "team") === "awayteam" ? teamAway : teamHome}
              </td>
              <td>{getAttr(event, "desc")}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  const displayScores = (match, team, getTag, getAttr) => {
    const scoreAway = parseInt(getAttr(getTag(match, team), "totalscore"), 10);
    let teamAway = getTag(match, [team, "innings", "inning"]);
    if (!teamAway.length)
      teamAway = "0"
        .repeat(9)
        .split("")
        .map((digit) => null);

    return (
      <>
        {teamAway.map((inning, index) => {
          return <td key={`${inning}-${index}`}>{getAttr(inning, "score")}</td>;
        })}

        <td>{scoreAway}</td>
      </>
    );
  };

  const getPeriods = (match, getTag, getAttr) => {
    const periodCount = Math.max(
      9,
      getTag(match, ["awayteam", "innings", "inning"]).length
    );

    const periods = [];
    for (let pi = 0; pi <= periodCount; pi++) periods.push(pi + 1);

    return periods;
  };

  return (
    <SportsURL
      league="MLB"
      caption="MLB"
      displayEvents={displayEvents}
      displayScores={displayScores}
      getPeriods={getPeriods}
      {...props}
    />
  );
};

export default SportsMLB;