import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

const SVGMap = (props) => {
  const [zoom, setZoom] = useState(50);
  const [pan, setPan] = useState({ x: 0, y: 0 });
  const [initXY, setInitXY] = useState(null);
  const [image, setImage] = useState(null);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);
  const [zip, setZip] = useState(null);
  const state = [
    "AA",
    "AE",
    "AK",
    "AL",
    "AP",
    "AR",
    "AS",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "FM",
    "GA",
    "GU",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MH",
    "MI",
    "MN",
    "MO",
    "MP",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "PW",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VI",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY",
  ];

  useEffect(() => {
    if (!props.zip) return;

    const scale = props.scale || 100;
    const cx1 = parseFloat(props.minLon) * scale;
    const cy1 = parseFloat(props.minLat) * scale;
    const cx2 = parseFloat(props.maxLon) * scale;
    const cy2 = parseFloat(props.maxLat) * scale;

    const canvas = document.createElement("canvas");
    canvas.width = cx2 - cx1;
    canvas.height = cy2 - cy1;
    const ctx = canvas.getContext("2d");

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.translate(-cx1, 0);
    ctx.lineWidth = 0.1;
    ctx.fillStyle = `#ffffff`;
    ctx.shadowBlur = 5;
    ctx.shadowColor = "black";
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;

    props.polygon.forEach((polygon, index) => {
      ctx.beginPath();

      JSON.parse(polygon).forEach((point, index) => {
        point[0] *= scale;
        point[1] = cy2 - point[1] * scale;

        if (!index) ctx.moveTo(point[0], point[1]);
        else ctx.lineTo(point[0], point[1]);
      });

      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    });

    let focalZip = null;

    ctx.shadowBlur = 2;

    props.zip.forEach((zip) => {
      const x = parseFloat(zip[2]) * scale;
      const y = cy2 - parseFloat(zip[1]) * scale;
      const ratio = parseInt(zip[5]) / parseInt(props.maxTally);

      if (!focalZip || parseInt(zip[5]) > parseInt(focalZip[5])) focalZip = zip;

      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.fillStyle = `rgba(255, 0, 0, ${ratio})`;
      ctx.strokeStyle = "red";
      ctx.arc(x, y, 5, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    });

    setZoom(50);

    const colContent = document.getElementById("colContent");
    const colContentRect = colContent.getBoundingClientRect();
    const centerX = colContentRect.width / 2;
    const centerY =
      (Math.max(document.documentElement.clientWidth, window.innerWidth) * 25) /
      100 /
      2;

    const focal = {
      x: -(parseFloat(focalZip[2]) * scale - cx1 - centerX - canvas.width / 2),
      y: cy2 - parseFloat(focalZip[1]) * scale - centerY - canvas.height / 2,
    };

    setImage(canvas.toDataURL("image/png"));
    setWidth(canvas.width);
    setHeight(canvas.height);
    if (focalZip) setPan(focal);
  }, [
    props.scale,
    props.minLon,
    props.minLat,
    props.maxLon,
    props.maxLat,
    props.polygon,
    props.zip,
    props.maxTally,
  ]);

  return (
    <Card className="mb-3">
      <Card.Header className="bg-dark text-light h5">
        Zipcode Map
        <input
          type="range"
          className="form-range w-25 float-end"
          min={1}
          max={100}
          defaultValue={zoom}
          onChange={(e) => setZoom(e.target.value)}
        />
      </Card.Header>
      {!image && <h3 className="p-3">Loading...</h3>}
      {image !== null && (
        <div
          id="mainDiv"
          style={{
            backgroundColor: "#e0f1f9",
            display: "inline-block",
            overflow: "hidden",
            height: "25vw",
            width: "100%",
          }}
          onMouseUp={(e) => setInitXY(null)}
          onBlur={(e) => setInitXY(null)}
          onMouseLeave={(e) => setInitXY(null)}
          onMouseDown={(e) => {
            if (!initXY) {
              setInitXY({
                x: e.clientX,
                y: e.clientY,
              });
            }
          }}
          onMouseMove={(e) => {
            if (initXY) {
              setPan({
                x: pan.x + (e.clientX - initXY.x) / (zoom / 100),
                y: pan.y + (initXY.y - e.clientY) / (zoom / 100),
              });

              setInitXY({
                x: e.clientX,
                y: e.clientY,
              });
            }
          }}
        >
          <div
            style={{
              transformOrigin: `center center`,
              transform: `scale(${zoom / 100})`,
              width: "100%",
              height: "25vw",
            }}
          >
            <div
              style={{
                transform: `translate(${pan.x}px, ${-pan.y}px)`,
                width: width,
                height: height,
              }}
            >
              <img
                style={{
                  width: width,
                  height: height,
                  position: "absolute",
                  left: `${-width / 2}px`,
                  top: `${-height / 2}px`,
                }}
                src={image}
                alt="Zip Map"
                draggable={false}
                title={
                  zip &&
                  `${zip[3]} ${state[parseInt(zip[4])]} ${String(
                    zip[0]
                  ).padStart(5, "0")} (${zip[5]})`
                }
                onMouseMove={(e) => {
                  const scale = props.scale || 100;
                  const cx1 = parseFloat(props.minLon) * scale;
                  const cy2 = parseFloat(props.maxLat) * scale;
                  let closestZip = null;
                  let closestDistance = null;

                  const rect = e.target.getBoundingClientRect();
                  const x = (e.clientX - rect.left) / (zoom / 100);
                  const y = (e.clientY - rect.top) / (zoom / 100);

                  props.zip.forEach((zip) => {
                    const xy = {
                      x: parseFloat(zip[2]) * scale - cx1,
                      y: cy2 - parseFloat(zip[1]) * scale,
                    };

                    const dist = Math.sqrt(
                      Math.pow(x - xy.x, 2) + Math.pow(y - xy.y, 2)
                    );

                    if (closestDistance === null || dist < closestDistance) {
                      closestDistance = dist;
                      closestZip = zip;
                    }
                  });

                  setZip(closestZip);

                  //console.log(x, y, closestDistance, closestZip);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default SVGMap;
