import React from "react";
import {
  EnvelopeFill,
  Facebook,
  Pinterest,
  Twitter,
} from "react-bootstrap-icons";

function FormShare(props) {
  const url = "https://ourfight.online/" + props.formId;
  return (
    <div
      className={`mx-auto text-center mt-3 fullWidth-${props.width || "50"} ${
        props.className
      }`}
    >
      {!props.hideHeader && (
        <div className="mb-3">
          {props.header || "Share this petition on your favorite social media"}
        </div>
      )}

      <a
        title="Share on Parler"
        className="mx-2"
        href={`https://parler.com/new-post?message=${encodeURIComponent(
          props.title
        )}&url=${encodeURIComponent(url)}`}
        rel="noreferrer"
        target="_blank"
      >
        <svg
          width="2rem"
          height="2rem"
          fill="currentColor"
          viewBox="0 0 500 500"
        >
          <g>
            <path
              className="st4"
              d="M200,300v-50.3h100.1c27.5,0,49.9-22.3,49.9-49.9v0c0-27.5-22.3-49.9-49.9-49.9H0v0C0,67.2,67.2,0,150,0l150,0
		c110.5,0,200,89.5,200,200v0c0,110.5-89.5,200-200,200h0C244.8,400,200,355.2,200,300z M150,350V200h0C67.2,200,0,267.2,0,350v150
		h0C82.8,500,150,432.8,150,350z"
            />
          </g>
        </svg>
      </a>

      <a
        title="Share on Facebook"
        className="mx-2"
        href={"https://www.facebook.com/sharer/sharer.php?u=" + url}
        rel="noreferrer"
        target="_blank"
      >
        <Facebook size="2em" />
      </a>
      <a
        title="Share on Twitter"
        className="mx-2"
        href={"http://twitter.com/share?url=" + url}
        rel="noreferrer"
        target="_blank"
      >
        <Twitter size="2em" />
      </a>
      <a
        title="Share on Pinterest"
        className="mx-2"
        href={"http://pinterest.com/pin/create/button/?url=" + url}
        rel="noreferrer"
        target="_blank"
      >
        <Pinterest size="2em" />
      </a>

      {/*<a
          className="mx-2"
          href={`https://gettr.com/share?&text=${encodeURIComponent(
            props.title
          )}&url=${encodeURIComponent(url)}`}
          rel="noreferrer"
          target="_blank"
        >
          GETTR
        </a>*/}

      <a
        title="Share via Email"
        className="mx-2"
        href={"mailto:?subject=" + props.title + "&body=" + url}
      >
        <EnvelopeFill size="2em" />
      </a>
    </div>
  );
}

export default FormShare;