import React from "react";
import Latest from "./Latest";

function PaymentThanks(props) {
  return (
    <div className="text-center">
      <div className="display-1 my-3">Thank you for your contibution!</div>
      <Latest></Latest>
    </div>
  );
}

export default PaymentThanks;