import React, { useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Button, Col, Form, FormControl, Row, Spinner } from "react-bootstrap";
import FormLegalese from "../Form/FormLegalese";

function Signup(props) {
  const [, setCookie] = useCookies(["user_id", "tier"]);
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    email: null,
    password: null,
  });

  const submit = async () => {
    if (
      /^[^@\s]+@[^.]+(\.\w{2,})+$/g.test(formData.email) === false ||
      formData.email.length < 5 ||
      formData.email.length > 64
    ) {
      setError(
        "Please enter a valid email address between 5 and 64 characters"
      );
    } else if (
      formData.password === null ||
      formData.password.length < 8 ||
      formData.password.length > 32
    ) {
      setError("Please enter a valid password between 8 and 32 characters");
    } else {
      setError(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>,
        "Signup"
      );

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourfight.online/signup",
          qs.stringify({
            email: formData.email,
            password: formData.password,
          }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          if (response.data?.error) setError(response.data?.error);
          else {
            setCookie("user_id", response.data.user_id, {
              maxAge: 90 * 24 * 60 * 60,
            });
            setError(null);
            props.setShowLogin(false);
          }
        });
    }
  };

  return (
    <Form>
      <Row className="mb-3">
        <Col xs="12" md="6" className="mb-3 mb-md-0">
          <FormControl
            placeholder="email"
            name="email"
            id="email"
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
        </Col>
        <Col xs="12" md="6">
          <FormControl
            placeholder="password"
            name="password"
            id="password"
            type="password"
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
        </Col>
      </Row>
      {!!error && (
        <Row className="mb-3 text-danger">
          <Col>{error}</Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col xs="12" className="mb-3 mb-md-0">
          <FormLegalese label="Signup" />
        </Col>
        <Col xs="12">
          <Button className="w-100 p-3" onClick={() => submit()}>
            Signup
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default Signup;