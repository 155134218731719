import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  CaretDownFill,
  CaretUpFill,
  PlusCircle,
  PlusCircleFill,
  XCircleFill,
} from "react-bootstrap-icons";
import Dialog from "../Doalog";
import InputDate from "../Input/InputDate";
import InputTime from "../Input/InputTime";
import TooltipOverlay from "../TooltipOverlay";

const QuestionnaireEdit = (props) => {
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [formData, setFormData] = useState({ name: "", fields: {} });

  const addField = (sortOrder) => {
    const newKey = String(Date.now());

    const newData = {
      ...formData.fields,
      [newKey]: {
        instructions: "",
        key: newKey,
        label: "",
        min1: null,
        max1: null,
        min2: null,
        max2: null,
        name: "",
        readOnly: false,
        required: false,
        type: "textSmall",
      },
    };

    const newDataKeys = Object.keys(formData.fields);
    newDataKeys.splice(sortOrder + 1, 0, newKey);

    updateFormFields(newData, newDataKeys);
  };

  const fieldByType = (key, property) => {
    switch (formData.fields[key].type) {
      case "date":
        return (
          <InputDate
            label={property === "min" ? "Min Date" : "Max Date"}
            date={formData.fields[key][property]}
            onChange={(value) => {
              formData.fields[key][property] = value;
              updateFormFields(formData.fields, Object.keys(formData.fields));
            }}
          />
        );

      case "time":
        return (
          <InputTime
            label={property === "min" ? "Min Time" : "Max Time"}
            time={formData.fields[key][property]}
            onChange={(value) => {
              formData.fields[key][property] = value;
              updateFormFields(formData.fields, Object.keys(formData.fields));
            }}
          />
        );

      default:
        <></>;
    }
  };

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/questionnaire/id/${props.id}`)
      .then((response) =>
        setFormData({
          ...response.data,
          fields: JSON.parse(response.data.fields),
        })
      );
  }, [props.id]);

  const hideDialog = () => {
    setDialog(false);
  };

  const moveFieldDown = (key) => {
    const dataKeys = Object.keys(formData.fields);
    const index = dataKeys.indexOf(key);

    const buffer = dataKeys[index + 1];
    dataKeys[index + 1] = dataKeys[index];
    dataKeys[index] = buffer;

    updateFormFields(formData.fields, dataKeys);
  };

  const moveFieldUp = (key) => {
    const dataKeys = Object.keys(formData.fields);
    const index = dataKeys.indexOf(key);

    const buffer = dataKeys[index - 1];
    dataKeys[index - 1] = dataKeys[index];
    dataKeys[index] = buffer;

    updateFormFields(formData.fields, dataKeys);
  };

  const removeField = (key) => {
    delete formData.fields[key];
    setFormData({ ...formData.fields });
  };

  const showDialog = (message) => {
    setDialog(true);
    setDialogMessage(message);
  };

  const submit = async () => {
    if (
      (formData.name || "").length < 5 ||
      (formData.name || "").length > 100
    ) {
      showDialog("Please enter a name");
    } else {
      showDialog(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );

      const updatedFormData = updateFieldKeys();

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourfight.online/questionnaireEdit",
          qs.stringify({
            userId: props.userId,
            id: props.id || updatedFormData.id,
            name: updatedFormData.name,
            fields: JSON.stringify(updatedFormData.fields),
          }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          showDialog(
            `Questionnaire ${
              updatedFormData.id ? "updated" : "created"
            } successfully!`
          );
          setFormData({
            ...response.data,
            fields: JSON.parse(response.data.fields),
          });
        });
    }
  };
  const toBoolean = (value) => {
    if (value === "0") return false;
    else if (value === "0000-00-00 00:00:00") return false;
    else if (value === "1") return true;
    else if (value === "") return false;
    else return value;
  };

  const updateFieldKeys = () => {
    const keys = Object.keys(formData.fields);

    const newFormData = {
      ...formData,
      fields: keys
        .map((key, index) => {
          return {
            [formData.fields[key].key]: {
              ...formData.fields[key],
              isFirst: !index,
              isLast: index === keys.length - 1,
            },
          };
        })
        .reduce((current, result) => {
          return { ...current, ...result };
        }),
    };

    setFormData(newFormData);

    return newFormData;
  };

  const updateFormFields = (newData, newDataKeys) => {
    setFormData({
      ...formData,
      fields: newDataKeys
        .map((key, index) => {
          return {
            [key]: {
              ...newData[key],
              isFirst: !index,
              isLast: index === newDataKeys.length - 1,
            },
          };
        })
        .reduce((current, result) => {
          return { ...current, ...result };
        }),
    });
  };

  useEffect(() => {
    if (props.id) getData();
  }, [props.id, getData]);

  return (
    <>
      <Dialog
        show={dialog}
        onClick={hideDialog}
        message={dialogMessage}
        title="Update Questionnaire"
      />
      <Form className="mt-3">
        <Row className="mb-3">
          <Col xs="12" md="4">
            <InputDate
              className="mb-3"
              label="Date"
              instructions="Select date"
            />
            <InputTime
              className="mb-3"
              label="Time"
              instructions="Select time"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12" md="4">
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={formData.name}
                onChange={(event) =>
                  setFormData({ ...formData, name: event.target.value })
                }
              />
            </Form.Group>
          </Col>
          {!Object.keys(formData.fields || {}).length && (
            <Col>
              <Button
                variant="link"
                className="mt-3 float-end text-decoration-none"
                onClick={() =>
                  addField(Object.keys(formData.fields)?.length || 0)
                }
              >
                <PlusCircle />
                &nbsp; Add Field
              </Button>
            </Col>
          )}
        </Row>

        <Row className="mb-3">
          <Col>
            {!!Object.keys(formData.fields || {}).length && (
              <Form.Label>Fields</Form.Label>
            )}
            {Object.keys(formData.fields).map((key, index) => (
              <div
                className="mt-3 p-3 border position-relative"
                key={`field-${key}-${formData.fields[key].type}`}
              >
                <div
                  className="d-flex position-absolute"
                  style={{ right: "0", top: "-0.75rem" }}
                >
                  <TooltipOverlay tip="Add field below">
                    <InputGroup.Text
                      className="border-0 p-1 bg-transparent pointer"
                      onClick={() => addField(index)}
                    >
                      <PlusCircleFill />
                    </InputGroup.Text>
                  </TooltipOverlay>
                  {!formData.fields[key]?.isFirst && (
                    <TooltipOverlay tip="Move field up">
                      <InputGroup.Text
                        className="border-0 p-1 bg-transparent pointer"
                        onClick={() => moveFieldUp(key)}
                      >
                        <CaretUpFill />
                      </InputGroup.Text>
                    </TooltipOverlay>
                  )}
                  {!formData.fields[key]?.isLast && (
                    <TooltipOverlay tip="Move field down">
                      <InputGroup.Text
                        className="border-0 p-1 bg-transparent pointer"
                        onClick={() => moveFieldDown(key)}
                      >
                        <CaretDownFill />
                      </InputGroup.Text>
                    </TooltipOverlay>
                  )}
                  <TooltipOverlay tip="Remove field">
                    <InputGroup.Text
                      className="border-0 p-1 bg-transparent pointer"
                      onClick={() => removeField(key)}
                    >
                      <XCircleFill />
                    </InputGroup.Text>
                  </TooltipOverlay>
                </div>

                <Row>
                  <Col md={2} className="mb-3 mb-md-0">
                    <FloatingLabel label="Field Key">
                      <Form.Control
                        placeholder="Field Key"
                        value={formData.fields[key].key}
                        onChange={(event) => {
                          formData.fields[key].key = event.target.value;
                          setFormData({ ...formData });
                        }}
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={2} className="mb-3 mb-md-0">
                    <FloatingLabel label="Field Type">
                      <Form.Select
                        placeholder="Field Type"
                        value={formData.fields[key]?.type}
                        onChange={(event) => {
                          formData.fields[key].type = event.target.value;
                          setFormData({ ...formData });
                        }}
                      >
                        <option value="date">Date</option>
                        <option value="dropdown">Dropdown</option>
                        <option value="number">Number</option>
                        <option value="numberInteger">Number (Integer)</option>
                        <option value="phoneNumber">Phone Number</option>
                        <option value="textSmall">Text</option>
                        <option value="textLarge">Text (Large)</option>
                        <option value="time">Time</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>

                  <Col md={2} className="mb-3 mb-md-0">
                    <FloatingLabel label="Field Label">
                      <Form.Control
                        placeholder="Field Label"
                        value={formData.fields[key]?.label}
                        onChange={(event) => {
                          formData.fields[key].label = event.target.value;
                          setFormData({ ...formData });
                        }}
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={4} className="mb-3 mb-md-0">
                    <FloatingLabel label="Field Instructions">
                      <Form.Control
                        placeholder="Field Instructions"
                        value={formData.fields[key]?.instructions}
                        onChange={(event) => {
                          formData.fields[key].instructions =
                            event.target.value;
                          setFormData({ ...formData });
                        }}
                      />
                    </FloatingLabel>
                  </Col>

                  <Col md={2} className="mb-3 mb-md-0">
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Read Only"
                          checked={toBoolean(formData.fields[key]?.readOnly)}
                          onChange={(event) => {
                            formData.fields[key].readOnly = event.target.checked
                              ? 1
                              : 0;
                            setFormData({ ...formData });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Required"
                          checked={toBoolean(formData.fields[key]?.required)}
                          onChange={(event) => {
                            formData.fields[key].required = event.target.checked
                              ? 1
                              : 0;
                            setFormData({ ...formData });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md={4} className="mb-3 mb-md-0">
                    {fieldByType(key, "min")}
                  </Col>
                  <Col md={4} className="mb-3 mb-md-0">
                    {fieldByType(key, "max")}
                  </Col>
                </Row>
              </div>
            ))}
            {!!Object.keys(formData.fields || {}).length && (
              <Button
                variant="link"
                className="mt-3 float-end text-decoration-none"
                onClick={() =>
                  addField(Object.keys(formData.fields)?.length || 0)
                }
              >
                <PlusCircle />
                &nbsp; Add Field
              </Button>
            )}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs="12" md="10" className="mb-3 mb-md-0" />
          <Col xs="12" md="2">
            <Button className="w-100 p-3" onClick={() => submit()}>
              {formData.id ? "Update" : "Create"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default QuestionnaireEdit;