import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Col, Image, Row } from "react-bootstrap";
import { CookiesProvider } from "react-cookie";
import AdminAnalytics from "./Admin/AdminAnalytics";
import Analytics from "./Admin/Analytics";
import Help from "./Help";
import Footer from "./Footer";
import FormEdit from "./Admin/FormEdit";
import FormEmbed from "./Admin/FormEmbed";
import FormEmbdedCompact from "./Form/Embed/FormEmbedCompact";
import FormEmbedFull from "./Form/Embed/FormEmbedFull";
import FormInteractions from "./Admin/FormInteractions";
import FormList from "./Admin/FormList";
import FormStats from "./Admin/FormStats";
import FormView from "./Form/FormView";
import FormCaucusRoom from "./Form/FormCaucusRoom/FormCaucusRoom";
import IntegrationEdit from "./Admin/IntegrationEdit";
import IntegrationList from "./Admin/IntegrationList";
import Menu from "./Menu";
import PaymentThanks from "./PaymentThanks";
import PrivacyPolicy from "./PrivacyPolicy";
import Profile from "./Account/Profile";
import UserAgreement from "./UserAgreement";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AdminSwitchAccount from "./Admin/AdminSwitchAccount";
import Sandbox from "./Sandbox";
import QuestionnaireEdit from "./Admin/QuestionnairEdit";
import QuestionnaireList from "./Admin/QuestionnaireList";
import Trending from "./Trending";
import NewsHome from "./News/NewsHome";
import NewsLink from "./News/NewsLink";
import NewsShowMore from "./News/NewsShowMore";
import LatestPage from "./LatestPage";
import Home from "./Home";
import LoginSingup from "./Account/LoginSignup";
import { useState } from "react";
import Market from "./Market/Market";
import About from "./About";
import Sports from "./Sports/Sports";
import AdminGeography from "./Admin/AdminGeography";
import NewsPrizePage from "./News/NewsPrize/NewsPrizePage";
import NewsLatestComments from "./News/NewsLatestComments";
import FormPromotionReceipt from "./Form/FormPromotionReceipt";
import PetitionMap from "./PetitionMap";
import { VoxelEditor } from "./Voxel/VoxelEditor";
function App() {
  const [cookies] = useCookies(["user_id", "tier"]);
  const [showLogin, setShowLogin] = useState(null);
  const search = window.location.search
    .replace(/\?/, "")
    .split("&")
    .map((param) => {
      const fields = param.split("=");
      return { [fields[0]]: fields[1] };
    })
    .reduce((current, result) => ({ ...result, ...current }));
  const pathFull = window.location.pathname.split("/");
  const path = [...pathFull].splice(2);
  let param = search;
  let priorValue = null;
  path.forEach((value, index) => {
    if (index % 2) param = { ...param, [priorValue]: value };
    else priorValue = value;
  });
  const wrapper = (content, padding = true) => (
    <CookiesProvider>
      <Row>
        <LoginSingup show={showLogin} setShowLogin={setShowLogin} />
        <Menu setShowLogin={setShowLogin} />
        <Col lg="2" />
        <Col lg="8" className={padding && "px-5 px-sm-0"} id="colContent">
          {content}
        </Col>
        <Col lg="2" />
        <Footer />
      </Row>
    </CookiesProvider>
  );
  const wrapperWide = (content, padding = true) => (
    <CookiesProvider>
      <Row>
        <LoginSingup show={showLogin} setShowLogin={setShowLogin} />
        <Menu setShowLogin={setShowLogin} />
        <Col xs="12" className={padding && "px-5 px-sm-0"} id="colContent">
          {content}
        </Col>
        <Footer />
      </Row>
    </CookiesProvider>
  );
  useEffect(() => {
    window.addEventListener("error", async (e) => {
      const qs = require("qs");
      await axios.post(
        "https://api.ourfight.online/onError",
        qs.stringify({
          url: window.location.href,
          type: e.type,
          message: JSON.stringify(e.error.stack.toString()),
        }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
    });
  }, []);
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={wrapper(
            <div className="text-center">
              <Image className="image-fluid w-50 my-3" src="ourfight.png" />
              <Home id={cookies.user_id} setShowLogin={setShowLogin} />
            </div>,
            true
          )}
        />
        <Route path="about" element={wrapper(<About />)} />
        <Route
          path="adminAnalytics"
          element={wrapper(
            <AdminAnalytics
              userId={cookies.user_id}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="adminGeography"
          element={wrapper(
            <AdminGeography
              userId={cookies.user_id}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="adminSwitchAccount"
          element={wrapper(
            <AdminSwitchAccount
              userId={cookies.user_id}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="analytics"
          element={wrapper(
            <Analytics userId={cookies.user_id} setShowLogin={setShowLogin} />
          )}
        />
        <Route path="help" element={wrapper(<Help />)} />
        <Route
          path="embed"
          element={wrapper(
            <FormEmbed
              formId={new URLSearchParams(window.location.search).get("formId")}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="embedCompact"
          element={
            <FormEmbdedCompact
              id={new URLSearchParams(window.location.search).get("id")}
              source={param?.source}
              narrow={param?.narrow}
            />
          }
        />
        <Route
          path="embedFull"
          element={
            <FormEmbedFull
              id={new URLSearchParams(window.location.search).get("id")}
            />
          }
        />
        <Route
          path="formEdit"
          element={wrapper(
            <FormEdit
              userId={cookies.user_id}
              id={new URLSearchParams(window.location.search).get("id")}
              template={new URLSearchParams(window.location.search).get(
                "template"
              )}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="formPromotionReceipt/*"
          element={wrapper(<FormPromotionReceipt id={pathFull[1]} />)}
        />
        <Route
          path="integrationEdit"
          element={wrapper(
            <IntegrationEdit
              userId={cookies.user_id}
              id={new URLSearchParams(window.location.search).get("id")}
              template={new URLSearchParams(window.location.search).get(
                "template"
              )}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="interactions"
          element={wrapper(
            <FormInteractions
              formId={new URLSearchParams(window.location.search).get("formId")}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="latest"
          element={wrapper(<LatestPage id={cookies.user_id} />)}
        />
        <Route
          path="market"
          element={wrapper(
            <Market id={cookies.user_id} symbol={param?.symbol} />
          )}
        />
        <Route
          path="paymentThanks"
          element={wrapper(<PaymentThanks setShowLogin={setShowLogin} />)}
        />
        <Route
          path="sports"
          element={wrapper(<Sports id={cookies.user_id} mid={param?.mid} />)}
        />
        <Route
          path="stats"
          element={wrapper(
            <FormStats
              formId={new URLSearchParams(window.location.search).get("formId")}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="myintegrations"
          element={wrapper(
            <IntegrationList id={cookies.user_id} setShowLogin={setShowLogin} />
          )}
        />
        <Route
          path="mypetitions"
          element={wrapper(
            <FormList id={cookies.user_id} setShowLogin={setShowLogin} />
          )}
        />
        <Route
          path="myquestionnaires"
          element={wrapper(
            <QuestionnaireList
              id={cookies.user_id}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="news"
          element={wrapper(
            <NewsHome
              id={cookies.user_id}
              setShowLogin={setShowLogin}
              thanks={new URLSearchParams(window.location.search).get("thanks")}
            />
          )}
        />
        <Route
          path="news/latestComments"
          element={wrapper(<NewsLatestComments />)}
        />
        <Route
          path="news/link/*"
          element={wrapper(
            <NewsLink
              id={cookies.user_id}
              setShowLogin={setShowLogin}
              source={param?.source}
              narrow={param?.narrow}
            />
          )}
        />
        <Route
          path="news/prize"
          element={wrapper(
            <NewsPrizePage id={cookies.user_id} setShowLogin={setShowLogin} />
          )}
        />
        <Route
          path="news/showmore/*"
          element={wrapper(
            <NewsShowMore id={cookies.user_id} setShowLogin={setShowLogin} />
          )}
        />
        <Route
          path="petitions"
          element={wrapper(
            <FormList id={cookies.user_id} setShowLogin={setShowLogin} />
          )}
        />
        <Route
          path="petitionMap"
          element={wrapperWide(
            <PetitionMap
              setShowLogin={setShowLogin}
              source={param?.source}
              fbCampaignId={param?.utm_source}
              rumbleCampaignId={param?.rumbleCampaign}
            />
          )}
        />
        <Route
          path="priorityPetition*"
          element={wrapper(
            <FormView
              setShowLogin={setShowLogin}
              source={param?.source}
              fbCampaignId={param?.utm_source}
              rumbleCampaignId={param?.rumbleCampaign}
              twitterCampaignId={param?.twitterCampaignId}
              zip={param?.zip}
            />
          )}
        />
        <Route path="privacyPolicy" element={wrapper(<PrivacyPolicy />)} />
        <Route path="profile" element={wrapper(<Profile />)} />
        <Route
          path="questionnaireEdit"
          element={wrapper(
            <QuestionnaireEdit
              userId={cookies.user_id}
              id={new URLSearchParams(window.location.search).get("id")}
              setShowLogin={setShowLogin}
            />
          )}
        />
        <Route
          path="sandbox"
          element={wrapper(<Sandbox setShowLogin={setShowLogin} />)}
        />
        <Route path="trending" element={<Trending />} />
        <Route
          path="userAgreement"
          element={wrapper(<UserAgreement setShowLogin={setShowLogin} />)}
        />
        <Route
          path="voxelEditor"
          element={wrapper(<VoxelEditor setShowLogin={setShowLogin} />)}
        />
        <Route
          path="caucusroom/*"
          element={
            <FormCaucusRoom
              postId={param?.postId}
              firstName={param?.firstName}
              lastName={param?.lastName}
              zip={param?.zip}
              email={param?.email}
              id={param?.id || pathFull[2]}
            />
          }
        />
        <Route
          path="*"
          element={wrapper(
            <FormView
              id={pathFull[1]}
              setShowLogin={setShowLogin}
              source={param?.source}
              fbCampaignId={param?.utm_source}
              rumbleCampaignId={param?.rumbleCampaign}
              zip={param?.zip}
            />
          )}
        />
      </Routes>
    </Router>
  );
}
export default App;