import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  CodeSquare,
  Eye,
  BarChartLine,
  ListUl,
  PencilSquare,
  PlusCircleFill,
  EyeSlash,
  Check2Circle,
} from "react-bootstrap-icons";
import CopyText from "../CopyText";
import TooltipOverlay from "../TooltipOverlay";
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import SubscriptionModal from "./SubscriptionModal";

function FormList(props) {
  const [data, setData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [message, setMessage] = useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/formList/id/${props.id}`)
      .then((response) => {
        setData(response.data.forms);
        setSubscription(response.data.subscription);
      });
  }, [props.id]);

  const subscribe = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/subscriptionSession/id/${props.id}`)
      .then((response) => (window.location = response.data.url));
  }, [props.id]);

  const updateDailyBudget = (e) => {
    setSubscription({
      ...subscription,
      dailyBudget: e.target.value.replace(/[^0-9]/g, ""),
    });
  };

  const updateSubscription = async () => {
    const qs = require("qs");

    await axios
      .post(
        "https://api.ourfight.online/subscriptionUpdateDailyBudget",
        qs.stringify({
          id: props.id,
          dailyBudget: subscription.dailyBudget,
        }),
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then(async (response) => {
        setMessage("Daily budget updated successfully!");
      });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return false;

  return (
    <div>
      {!!message && <Alert className="mt-3">{message}</Alert>}
      {false && !!subscription?.id && (
        <Button
          variant="link"
          className="text-decoration-none p-0 w-100 text-start"
          onClick={() => setShowSubscriptionModal(true)}
        >
          <Alert className="mt-3">
            You are subscribed to Our Fight Promotions. Click here for more
            information.
          </Alert>
        </Button>
      )}
      <SubscriptionModal
        subscription={subscription}
        show={showSubscriptionModal}
        onAgree={() => subscribe()}
        onHide={() => setShowSubscriptionModal(false)}
      />
      <Row>
        <Col md={!!subscription ? 7 : 8}>
          <Dropdown as={ButtonGroup} align="start" drop="down" className="mt-3">
            <a className="btn btn-primary" href="formEdit">
              <PlusCircleFill size="1.25em" />
              &nbsp;Create New Petition
            </a>

            <Dropdown.Toggle split />

            <Dropdown.Menu>
              <Dropdown.Header>Templates</Dropdown.Header>
              <Dropdown.Item href="/formEdit?template=protectOurChildren">
                Protect Our Children
                <br />
                <small>Defend our children from sexual indoctrination</small>
                <Dropdown.Divider />
              </Dropdown.Item>
              <Dropdown.Item href="/formEdit?template=protectOurElections">
                Protect Our Elections
                <br />
                <small>Ensure our elections are fair and secure</small>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        {false && !subscription?.id && (
          <Col md={4} className="mt-3 text-end">
            <Button
              variant="success"
              onClick={() => setShowSubscriptionModal(true)}
            >
              <Check2Circle /> Enable Our Fight Promotions
            </Button>
          </Col>
        )}
        {false && !!subscription?.id && (
          <Col md={3} className="mt-3 text-end">
            <ManageSubscriptionButton />
          </Col>
        )}
        {false && !!subscription?.id && (
          <Col md={2} className="mt-3 text-end">
            <>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <TooltipOverlay tip="Daily Budget">
                  <FormControl
                    type="number"
                    placeholder="Daily Budget"
                    value={subscription?.dailyBudget}
                    onChange={updateDailyBudget}
                  />
                </TooltipOverlay>
                <Button onClick={() => updateSubscription()}>Update</Button>
              </InputGroup>
            </>
          </Col>
        )}
      </Row>

      {data.map((form, index) => (
        <Card className="mt-3" key={`form${index}`}>
          <Card.Body>
            <Card.Title className={form.archivedDateTime && "text-secondary"}>
              {form.title}{" "}
              {form.archivedDateTime && (
                <TooltipOverlay tip="archived">
                  <EyeSlash />
                </TooltipOverlay>
              )}
            </Card.Title>
            <Row>
              <Col xs={12} lg={10}>
                <a
                  className="text-decoration-none"
                  href={"/" + (form.alias ? form.alias : form.id)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {window.location.origin +
                    "/" +
                    (form.alias ? form.alias : form.id)}
                </a>
                <CopyText
                  tip="Copy URL"
                  text={
                    window.location.origin +
                    "/" +
                    (form.alias ? form.alias : form.id)
                  }
                />
              </Col>
              <Col>
                <div className="float-none float-md-end mt-3 mt-md-0">
                  <TooltipOverlay tip="Edit petition">
                    <a
                      className="text-decoration-none text-dark me-3"
                      href={"/formEdit?id=" + form.id}
                    >
                      <PencilSquare size="1.5em" />
                    </a>
                  </TooltipOverlay>

                  <TooltipOverlay tip="View petition stats">
                    <a
                      className="text-decoration-none text-dark me-3"
                      href={"/stats?formId=" + form.id}
                    >
                      <BarChartLine size="1.5em" />
                    </a>
                  </TooltipOverlay>

                  <TooltipOverlay tip="View petition's raw data &amp; generate CSV/PDF">
                    <a
                      className="text-decoration-none text-dark me-3"
                      href={"/interactions?formId=" + form.id}
                    >
                      <ListUl size="1.5em" />
                    </a>
                  </TooltipOverlay>

                  <TooltipOverlay tip="Get code to embed petition on HTML page">
                    <a
                      className="text-decoration-none text-dark me-3"
                      href={"/embed?formId=" + form.id}
                    >
                      <CodeSquare size="1.5em" />
                    </a>
                  </TooltipOverlay>

                  <TooltipOverlay tip="View petition">
                    <a
                      className="text-decoration-none text-dark"
                      href={"/" + form.id}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Eye size="1.5em" />
                    </a>
                  </TooltipOverlay>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

export default FormList;