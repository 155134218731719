/* global gapi */
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const NewsLinkGoogleAnalytics = (props) => {
  const [accessToken, setAccessToken] = useState(null);
  const [startDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate] = useState(new Date());
  const [views, setViews] = useState(null);

  const clientId =
    "612945996448-k3oo6c3m5bsl5bgb945645mk4l5upl2j.apps.googleusercontent.com";
  const clientSecret = "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";
  const refreshToken =
    "1//0dx9YUcjyWZIxCgYIARAAGA0SNwF-L9IrmpVCb-1W7eVsf4z6t1As-Bl69Sg6Fb4uxZcxC_xVzSRKAqfwjFVd5k3c-2Cgbcpa8zU";

  const getAccessToken = useCallback(async () => {
    await axios
      .post(
        `https://www.googleapis.com/oauth2/v3/token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=refresh_token&refresh_token=${refreshToken}`
      )
      .then((response) => {
        setAccessToken(response.data.access_token);

        (function(w, d, s, g, js, fjs) {
          g = w.gapi || (w.gapi = {});
          g.analytics = {
            q: [],
            ready: function(cb) {
              this.q.push(cb);
            },
          };
          js = d.createElement(s);
          fjs = d.getElementsByTagName(s)[0];
          js.src = "https://apis.google.com/js/platform.js";
          fjs.parentNode.insertBefore(js, fjs);
          js.onload = function() {
            g.load("analytics");
          };
        })(window, document, "script");
      });
  }, []);

  const formatDate = (date) => date.toISOString().split("T")[0];

  useEffect(() => {
    getAccessToken();
  }, [getAccessToken]);

  useEffect(() => {
    if (!accessToken) return;

    gapi.analytics.ready(function() {
      gapi.analytics.auth.authorize({
        serverAuth: {
          access_token: accessToken,
        },
      });

      new gapi.client.analytics.data.ga.get({
        ids: "ga:229870940",
        filters: `ga:pagePath==/news/link/${props.id}`,
        metrics: "ga:pageviews",
        "start-date": formatDate(startDate),
        "end-date": formatDate(endDate),
      }).then((response) => {
        setViews(parseInt(response?.result?.rows?.[0]?.[0] ?? "0"));
      });
    });
  }, [accessToken, startDate, endDate, props.id]);

  if (!views) return null;

  return (
    <div className="fs-6 text-secondary">{`${views} page views since ${startDate.toDateString()}`}</div>
  );
};

export default NewsLinkGoogleAnalytics;
