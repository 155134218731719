import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const MarketLineGraph = (props) => {
  const [chart, setChart] = useState(null);
  const [isSmall, setSmall] = useState(null);
  const [isMedium, setIsMedium] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSmall(window.innerWidth <= 768);
      setIsMedium(window.innerWidth <= 992);
    });
  }, []);

  const options = {
    pointRadius: isMedium ? 1 : props.radius || 0,
    responsive: true,
    maintainAspectRatio: props.aspect,
    scales: {
      xAxis: {
        display: isMedium ? false : props?.xAxis || false,
        ticks: {
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: chart?.labels?.length / 60,
        },
      },
      yAxis: {
        display: isSmall ? false : props?.yAxis || false,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const formatTime = (time) => {
    const date = new Date(time);

    return `${(date.getHours() - (date.getHours() > 12 ? 12 : 0))
      .toString()
      .padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${date.getHours() > 12 ? "PM" : "AM"}`;
  };

  useEffect(() => {
    if (props.values) {
      const lastValue = props.values[props.values.length - 1];
      const dayPrior = new Date(lastValue.datetime);
      dayPrior.setDate(dayPrior.getDate() - 1);

      const valuesReverse = [...props.values].reverse();

      setChart({
        labels: valuesReverse
          .map((value) => formatTime(value.datetime))
          .reduce((a, b) => [...a, b], []),
        datasets: [
          {
            label: "",
            data: valuesReverse
              .map((value) => parseFloat(value.close))
              .reduce((a, b) => [...a, b], []),
          },
        ],
      });
    }
  }, [props]);

  if (!chart) return null;

  return <Line className="mb-5" options={options} data={chart} />;
};

export default MarketLineGraph;