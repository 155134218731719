import React, { useState } from "react";
import { Button, FloatingLabel, FormControl } from "react-bootstrap";

export const FormZip = (props) => {
  const [editZip, setEditZip] = useState(false);

  return !!props.formData?.zip && !editZip ? (
    <Button
      variant="link"
      className="w-100 p-0 text-end text-decoration-none"
      onClick={() => setEditZip(true)}
    >
      <div>{`${(props?.data?.closestZip?.ZipCode ?? "")
        ?.toString()
        ?.padStart(5, "0")} ${props?.data?.closestZip?.Name}`}</div>
      <small>Click to update zip</small>
    </Button>
  ) : (
    <FloatingLabel label="zip">
      <FormControl
        name="zip"
        id="zip"
        value={props.formData?.zip || ""}
        onChange={(e) => {
          props.setFormData({
            ...props.formData,
            zip: e.target.value,
          });
        }}
      />
    </FloatingLabel>
  );
};