import { Col } from "react-bootstrap";

const VideoList = () => {
  const list = [
    {
      title: "They play dirty because they are scared",
      url: "https://rumble.com/embed/v1dhshb/?pub=107jef",
    },
    {
      title: "The war on children, family, and women",
      url: "https://rumble.com/embed/v1batuv/?pub=107jef",
    },
    {
      title: "Follow the Science?",
      url: "https://rumble.com/embed/v1a4wvj/?pub=107jef",
    },
    {
      title: "Abortionists' Blame Game",
      url: "https://rumble.com/embed/v18tujn/?pub=107jef",
    },
    {
      title: "Impact of Supreme Court victories",
      url: "https://rumble.com/embed/v17tnsr/?pub=107jef",
    },
    {
      title: "Diversity for thee but not for me!",
      url: "https://rumble.com/embed/v16x6gd/?pub=107jef",
    },
    {
      title: "Disney's Lighyear & Conservatives",
      url: "https://rumble.com/embed/v16odrg/?pub=107jef",
    },
    {
      title: "Abortion is based on selfishness!",
      url: "https://rumble.com/embed/v166cdo/?pub=107jef",
    },
    {
      title: "Jan 06 Is Simply a Distraction!",
      url: "https://rumble.com/embed/v161qg5/?pub=107jef",
    },
    {
      title: "Fight the sexual indoctrination!",
      url: "https://rumble.com/embed/v15x69l/?pub=107jef",
    },
    {
      title: "Biden's Bear Market",
      url: "https://rumble.com/embed/v15sza1/?pub=107jef",
    },
    {
      title: "$5 a gallon of gas",
      url: "https://rumble.com/embed/v15ow71/?pub=107jef",
    },
    {
      title: "Laws are being used to force transgenderism",
      url: "https://rumble.com/embed/v15dns5/?pub=107jef",
    },
    {
      title: "Feminism vs Transgenderism",
      url: "https://rumble.com/embed/v1592tq/?pub=107jef",
    },
    {
      title: "Armed man arrested near Supreme Court Justice Kavanaugh's home",
      url: "https://rumble.com/embed/v155lak/?pub=107jef",
    },
    {
      title: "Ohio HP 454: Helena Kerschner",
      url: "https://rumble.com/embed/v154z73/?pub=107jef",
    },
  ];

  return [
    list
      .slice(0, 4)
      .map((item) => (
        <Col xs={12} md={6} lg={3} className="mb-3">
          <span
            key={`video-${item}`}
            className="row ratio ratio-16x9 w-100 m-0"
          >
            <iframe
              className="p-0"
              title={item.title}
              src={item.url}
              frameBorder="0"
              allowFullScreen
            />
          </span>
          <div className="bg-dark text-light text-center">{item.title}</div>
        </Col>
      ))
      .reduce((previous, current) => [...previous, current], []),
    <div className="text-end">
      <a
        className="text-decoration-none"
        rel="noreferrer"
        target="_blank"
        href="https://rumble.com/c/c-1658165"
      >
        More videos...
      </a>
    </div>,
  ];
};

export default VideoList;