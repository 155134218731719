import LayoutArray from "../Layout/LayoutArray";

const SectionArray = (props) => {
  return (
    <LayoutArray
      header={props.section}
      showMore={props?.section ? `/news/showmore/${props.section}` : null}
      links={props.articles}
      {...props}
    />
  );
};

export default SectionArray;
