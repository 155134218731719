import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";

function FormText(props) {
  let div = document.createElement("div");
  div.innerHTML = props.text;
  let rawText = div.textContent || div.innerText || "";
  let words = (rawText.match(/\s/gi) || []).length;

  const [split] = useState(
    (props.text ?? "")
      .split(/(<a.*?<\/a>)|(http[s]*:\/\/[a-z0-9-._~:/?#[\]@!$&'()*+,;=]+)/gi)
      .filter((item) => item)
  );

  const [maxHeight, setMaxHeight] = useState(
    props.truncate && (words >= 100 || split.length > 1) ? "100px" : null
  );

  const showMore = () => {
    setMaxHeight(null);
  };

  const text = split.map((line, index) => {
    if (line.match(/^https:\/\/rumble.com\/embed/gi))
      return `<a target="_blank" href="${line}">${line}</a><span class="row ratio ratio-16x9"><iframe src = "${line}"></iframe></span>`;
    else if (line.match(/^https:\/\/www.youtube.com\//gi)) {
      const fields = line.split(/.*=/gi);
      return `<a target="_blank" href="${line}">${line}</a><span class="row ratio ratio-16x9"><iframe src = "https://youtube.com/embed/${
        fields[1]
      }"></iframe></span>`;
    } else if (line.match(/^https:\/\/youtu.be\//gi)) {
      const fields = line.split(/^https:\/\/youtu.be\//gi);
      return `<a target="_blank" href="${line}">${line}</a><span class="row ratio ratio-16x9"><iframe src = "https://youtube.com/embed/${
        fields[1]
      }"></iframe></span>`;
    } else if (line.match(/^http[s]*/gi))
      return `<a target="_blank" href="${line}">${line}</a>`;
    else return line;
  });

  return (
    <div className="my-3">
      <div
        className="text"
        style={{
          overflow: "hidden",
          maxHeight: maxHeight,
        }}
      >
        {ReactHtmlParser(text.join(""))}
      </div>
      {maxHeight && (
        <div className="readMoreContainer">
          <div className="readMorePadding" />
          <div
            className="text-secondary text-end border-top pointer"
            onClick={() => showMore()}
          >
            Read More
          </div>
        </div>
      )}
    </div>
  );
}

export default FormText;