import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  Row,
  Spinner,
} from "react-bootstrap";
import Dialog from "../Doalog";

function Profile(props) {
  const [cookies] = useCookies(["user_id", "tier"]);
  const [dialog, setDialog] = useState(null);
  const [dialogMessage, setDialogMessage] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    passwd: "",
    passwd2: "",
  });

  const hideDialog = () => {
    setDialog(false);
  };

  const showDialog = (message) => {
    setDialog(true);
    setDialogMessage(message);
  };

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/profile/userId/${cookies.user_id}`)
      .then((response) =>
        setFormData({
          email: response.data.email,
          token: response.data.token,
          passwd: "",
          passwd2: "",
        })
      );
  }, [cookies.user_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!formData) return null;

  const submit = async () => {
    if (
      /^[^@\s]+@[^.]+(\.\w{2,})+$/g.test(formData.email) === false ||
      formData.email.length < 5 ||
      formData.email.length > 64
    ) {
      showDialog(
        "Please enter a valid email address between 5 and 64 characters"
      );
    } else if (
      formData.passwd &&
      (formData.passwd.length < 8 || formData.passwd.length > 32)
    ) {
      showDialog("Please enter a valid password between 8 and 32 characters");
    } else if (formData.passwd && formData.passwd2 !== formData.passwd) {
      showDialog("Passwords do not match");
    } else {
      showDialog(
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );

      const qs = require("qs");
      await axios
        .post(
          "https://api.ourfight.online/profileEdit",
          qs.stringify({ ...formData, userId: cookies.user_id }),
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {
          showDialog(response.data);
        });
    }
  };

  return (
    <Card className="mt-3">
      <Card.Body>
        <Form>
          <Dialog
            show={dialog}
            onClick={hideDialog}
            message={dialogMessage}
            title="Update Profile"
          />
          <Row className="mb-3">
            <Col xs="12" md="6" className="">
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <FormControl
                  placeholder="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                  }}
                />
                <Form.Text>
                  Enter new account email.{" "}
                  <strong>This email is also your username.</strong>
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs="12" md="6" className="">
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Account Token</Form.Label>
                <FormControl
                readOnly
                  name="token"
                  value={formData.token}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="12" md="6" className="mb-3 mb-md-0">
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>New Password</Form.Label>
                <FormControl
                  placeholder="new password"
                  name="password"
                  type="password"
                  onChange={(e) => {
                    setFormData({ ...formData, passwd: e.target.value });
                  }}
                />
                <Form.Text>
                  Enter new account paassword. Leave blank to keep your current
                  password.
                </Form.Text>
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group className="mb-3" controlId="password2">
                <Form.Label>New Password Again</Form.Label>
                <FormControl
                  placeholder="new password again"
                  name="password2"
                  type="password"
                  onChange={(e) => {
                    setFormData({ ...formData, passwd2: e.target.value });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs="12" md="10" className="mb-3 mb-md-0" />
            <Col xs="12" md="2">
              <Button className="w-100 p-3" onClick={() => submit()}>
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default Profile;