import { Card, Col, Row, Table } from "react-bootstrap";

const SportsMatchBoxScore = (props) => {
  const scoreAway = parseInt(
    props.getAttr(props.getTag(props.match, "awayteam"), "totalscore"),
    10
  );
  const scoreHome = parseInt(
    props.getAttr(props.getTag(props.match, "hometeam"), "totalscore"),
    10
  );

  const dateTimeFields = props.getAttr(props.match, "datetime_utc").split(" ");
  const dateFields = dateTimeFields[0].split(".");
  const timeFields = dateTimeFields[1].split(":");
  const date = new Date(
    parseInt(dateFields[2], 10),
    parseInt(dateFields[1], 10) - 1,
    parseInt(dateFields[0], 10),
    parseInt(timeFields[0], 10),
    parseInt(timeFields[1], 10)
  );

  const offset = date.getTimezoneOffset() / 60;
  const hours = (date.getHours() ? date.getHours() : 12) - offset;
  const minutes = date.getMinutes();
  const time = `${(hours > 12 ? hours - 12 : hours)
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${
    !date.getHours() || hours >= 12 ? "PM" : "AM"
  }`;

  const periods = props.getPeriods(props.match, props.getTag, props.getAttr);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <small>{time}</small>
          </Col>
        </Row>

        <Table responsive>
          <tbody>
            <tr>
              <td />
              {periods.map((period, index) => (
                <td key={period}>
                  {index === periods.length - 1 ? null : period}
                </td>
              ))}
            </tr>
            <tr className={scoreAway > scoreHome ? "fw-bold" : ""}>
              <td>
                {props.getAttr(props.getTag(props.match, "awayteam"), "name")}
              </td>
              {props?.displayScores(
                props.match,
                "awayteam",
                props.getTag,
                props.getAttr
              )}
            </tr>
            <tr className={scoreHome > scoreAway ? "fw-bold" : ""}>
              <td>
                {props.getAttr(props.getTag(props.match, "hometeam"), "name")}
              </td>
              {props?.displayScores(
                props.match,
                "hometeam",
                props.getTag,
                props.getAttr
              )}
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default SportsMatchBoxScore;