import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Badge, Card, Col, Row } from "react-bootstrap";
import GoogleAnalytics from "./GoogleAnalytics";
import SVGMap from "../SVGMap";

function Analytics(props) {
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get(`https://api.ourfight.online/analytics/userId/${props.userId}`)
      .then((response) => {
        setData(response.data);
      });
  }, [props.userId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const DataPoint = (props) => {
    const value = parseFloat(props.value || 0);
    const prior = parseFloat(props.prior || 0);

    return (
      <Col xs={props.xs || 12} md={props.md || 6} lg={props.lg || 4}>
        <Card className="border-0">
          <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <div className="fs-5">
              {(+value.toFixed(2)).toLocaleString("en-us")}
              <Badge className="ml-3" bg={prior > value ? "danger" : "success"}>
                {props.hasOwnProperty("prior")
                  ? (value > prior ? "+" : "") +
                    (+(value - prior).toFixed(2)).toLocaleString("en-us")
                  : null}
              </Badge>
            </div>
            <div className="fs-6 fw-light">{props.description}</div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  return (
    <div className="mt-3">
      <SVGMap {...data?.county} zip={data?.zip} />

      <Card className="mb-3">
        <Card.Header className="bg-dark text-light h5">Traffic</Card.Header>
        <GoogleAnalytics filters={data?.path} />
      </Card>

      <Card>
        <Card.Header className="bg-dark text-light h5">
          Acquired Emails: Overall
        </Card.Header>
        <Card.Body>
          <Row>
            <DataPoint
              title="# of Days"
              description="Total number of days this year containing data. These days may not be sequential"
              value={data?.analytics.days}
            />
            <DataPoint
              title="Total"
              description="Total number of distinct emails acquired this year"
              value={data?.analytics.total}
            />
            <DataPoint
              title="Average"
              description="Average number of distinct emails acquired daily this year"
              value={data?.analytics.average}
            />
          </Row>
          <Row>
            <DataPoint
              title="Projection"
              description="Projected number of distinct emails aquired this year based on the current daily average"
              value={data?.analytics.projection365}
            />
            <DataPoint
              title="Yesterday"
              description="Total number of distinct emails acquired yesterday"
              value={data?.analytics.totalDayPrior}
            />
            <DataPoint
              title="Today"
              description="Total number of distinct emails acquired today"
              value={data?.analytics.totalDay}
              prior={data?.analytics.totalDayPrior}
            />
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header className="bg-dark text-light h5">
          Acquired Emails: This Week
        </Card.Header>
        <Card.Body>
          <Row>
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="Prior Week"
              description="Total number of distinct emails acquired last week"
              value={data?.analytics.totalWeekPrior}
            />
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="This Week"
              description="Total number of distinct emails acquired this week"
              value={data?.analytics.totalWeek}
              prior={data?.analytics.totalWeekPrior}
            />
          </Row>
          <Row>
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="Prior Week Daily Average"
              description="Average number of distinct emails acquired daily last week"
              value={data?.analytics.avgWeekPrior}
            />
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="This Week's Daily Average"
              description="Average number of distinct emails acquired daily this week"
              value={data?.analytics.avgWeek}
              prior={data?.analytics.avgWeekPrior}
            />
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-3">
        <Card.Header className="bg-dark text-light h5">
          Acquired Emails: This Month
        </Card.Header>
        <Card.Body>
          <Row>
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="Prior Month"
              description="Total number of distinct emails acquired last month"
              value={data?.analytics.totalMonthPrior}
            />
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="This Month"
              description="Total number of distinct emails acquired this month"
              value={data?.analytics.totalMonth}
              prior={data?.analytics.totalMonthPrior}
            />
          </Row>
          <Row>
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="Prior Month Daily Average"
              description="Average number of distinct emails acquired daily last month"
              value={data?.analytics.avgMonthPrior}
            />
            <DataPoint
              xs={12}
              md={6}
              lg={6}
              title="This Month's Daily Average"
              description="Average number of distinct emails acquired daily this month"
              value={data?.analytics.avgMonth}
              prior={data?.analytics.avgMonthPrior}
            />
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Analytics;
