import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import FormEmbdedCompact from "./Form/Embed/FormEmbedCompact";

const Trending = () => {
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get("https://api.ourfight.online/trending")
      .then((response) => setData(response.data));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!data) return false;

  return data.map((form) => (
    <div
      key={form.id}
      style={{
        borderBottom: "1px solid #eeeeee",
        padding: "5rem 0",
        marginBottom: "5rem",
      }}
    >
      <FormEmbdedCompact id={form.id} />
    </div>
  ));
};

export default Trending;