import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircleFill } from "react-bootstrap-icons";

const NewsVideosSingle = (props) => (
  <Card bg="dark" text="light" className={props.className}>
    <Card.Body>
      <span className="row ratio ratio-16x9 w-100 m-0">
        <iframe
          className="p-0"
          title={props.video.title}
          src={props.video.url}
          frameBorder="0"
          allowFullScreen
        />
      </span>
      <div className="bg-dark text-light text-center">{props.video.title}</div>
    </Card.Body>
  </Card>
);

function NewsVideos(props) {
  const [first2, setFirst2] = useState(null);
  const [middle, setMiddle] = useState(null);
  const [last2, setLast2] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get(
        `https://api.mobubby.com/json/_videos.json?time=${new Date().getTime()}`
      )
      .then((response) => {
        const first5 = response.data.splice(0, 5);
        setFirst2(first5.slice(1, 3));
        setMiddle(first5.slice(0, 1)[0]);
        setLast2(first5.slice(3, 5));
      });
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  if (!first2 || !middle || !last2) return false;

  return (
    <Container fluid className="p-0" id={props.header}>
      <div className="display-3 border-bottom mb-3 text-start">
        {ReactHtmlParser(props.header.replace("%20", " "))}
      </div>{" "}
      <Row>
        <Col sm={12} md={3}>
          <Row>
            {first2.map((video, index) => (
              <Col
                key={`video-${JSON.stringify(video)}`}
                sm={12}
                className="mb-3"
              >
                <NewsVideosSingle
                  video={video}
                  className="cardSmall"
                  {...props}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row>
            <Col className="mb-3">
              <NewsVideosSingle
                video={middle}
                className="cardLarge"
                {...props}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={3}>
          <Row>
            {last2.map((video, index) => (
              <Col
                key={`video-${JSON.stringify(video)}`}
                sm={12}
                className="mb-3"
              >
                <NewsVideosSingle
                  video={video}
                  className="cardSmall"
                  {...props}
                />
              </Col>
            ))}
          </Row>
        </Col>
        <div>
          <a
            target="_blank"
            href={"https://rumble.com/c/c-1658165"}
            className="text-decoration-none h3 float-end"
            rel="noreferrer"
          >
            Show More <ArrowRightCircleFill />
          </a>
        </div>
      </Row>
    </Container>
  );
}
export default NewsVideos;