import { useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { ChevronCompactRight, XCircle } from "react-bootstrap-icons";
import ReactHtmlParser from "react-html-parser";

const NewsIndex = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {!show && (
        <div
          className="bg-dark text-light indexShow"
          onClick={() => setShow(true)}
        >
          <ChevronCompactRight />
        </div>
      )}
      {show && (
        <div className="bg-dark text-light index" responsive="xs">
          <div
            className="header"
            closeButton
            closeVariant="white"
            onHide={() => setShow(false)}
          >
            Sections
            <Button variant="dark" className="float-end">
              <XCircle onClick={() => setShow(false)} />
            </Button>
          </div>
          <div className="body">
            <Nav className="flex-column">
              {props.jsonKeys?.map((section) => (
                <Nav.Link
                  className="text-light"
                  onClick={() => setShow(false)}
                  href={`#${section}`}
                >
                  {ReactHtmlParser(section.replace("%20", " "))}
                </Nav.Link>
              ))}
            </Nav>
          </div>
        </div>
      )}
    </>
  );
};

export default NewsIndex;