import React from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";

function Dialog(props) {
  return (
    <Modal centered size="md" show={props.show} onClick={() => props.onClick()}>
      <Modal.Header className="bg-dark text-light h5">
        <ExclamationCircleFill />
        &nbsp;
        {props.title || "Error"}
        <Button className="btn-close btn-close-white"> </Button>
      </Modal.Header>
      <ModalBody className="text-left">
        <div className="lead">{props.message}</div>
      </ModalBody>
    </Modal>
  );
}

export default Dialog;