import React, { useState } from "react";
import { Badge, Card, Container, Nav, Navbar } from "react-bootstrap";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  CheckCircleFill,
} from "react-bootstrap-icons";

function Steps(props) {
  const [stepIndex, setStepIndex] = useState(0);
  const trueStepIndex = Math.min(
    stepIndex,
    props.children.filter((x) => x).length - 1
  );

  return (
    <Card className="mt-3">
      <Card.Header className="p-0 p-lg-3">
        <Navbar expand="lg" className="p-0">
          <Container fluid className="p-0">
            <Nav
              className="w-xs-100 h5"
              fill
              variant="pills"
              activeKey={trueStepIndex}
              onSelect={(selectedKey) => setStepIndex(parseInt(selectedKey))}
            >
              {props.children.map(
                (child, index) =>
                  child && (
                    <Nav.Item key={"nav" + index} className="text-start">
                      <Nav.Link
                        eventKey={index}
                        className={
                          "p-3 " + (trueStepIndex === index && " text-light")
                        }
                      >
                        <Badge className="me-2" pill bg="light" text="dark">
                          {index + 1}
                        </Badge>
                        {child.props?.title}
                      </Nav.Link>
                    </Nav.Item>
                  )
              )}
            </Nav>

            <Nav className="ms-3 ms-md-auto">
              <Nav.Link
                className="d-none d-md-flex"
                onClick={() => {
                  if (trueStepIndex === 0)
                    setStepIndex(props.children.length - 1);
                  else setStepIndex(trueStepIndex - 1);
                }}
              >
                <ArrowLeftCircle size="2em" />
              </Nav.Link>
              <Nav.Link
                className="d-none d-md-flex"
                onClick={() => {
                  if (trueStepIndex === props.children.length - 1)
                    setStepIndex(0);
                  else setStepIndex(trueStepIndex + 1);
                }}
              >
                <ArrowRightCircle size="2em" />
              </Nav.Link>
              {props.onSubmit && (
                <Nav.Link onClick={props.onSubmit}>
                  <CheckCircleFill size="2em" color="green" />
                  &nbsp; {props.submitLabel || "Submit"}
                </Nav.Link>
              )}
            </Nav>
          </Container>
        </Navbar>
      </Card.Header>
      <Card.Body>{props.children[trueStepIndex]}</Card.Body>
    </Card>
  );
}

export default Steps;