import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import CopyText from "../CopyText";
import FormEmbdedCompact from "../Form/Embed/FormEmbedCompact";
import FormEmbedFull from "../Form/Embed/FormEmbedFull";

function FormEmbed(props) {
  const codeCompact = `<script src = 'https://ourfight.online/embed.js'></script> <iframe id = '${
    props.formId
  }' src = 'https://ourfight.online/embedCompact?id=${
    props.formId
  }' frameborder = 0 scrolling = 'no' width = '100%' height = '400px'></iframe>`;
  const codeFull = `<script src = 'https://ourfight.online/embed.js'></script> <iframe id = '${
    props.formId
  }' src = 'https://ourfight.online/embedFull?id=${
    props.formId
  }' frameborder = 0 scrolling = 'no' width = '100%' height = '400px'></iframe>`;

  return (
    <div>
      <Card className="my-3 fs-5">
        <Card.Body>
          You don't have to redirect your visitors to Our Fight. You can embed
          your petition on any HTML page including your website. Now your
          visitors can sign your petition and remain on your site. You can also
          embed multiple petitions on a single HTML page. Choose between compact
          and full-page styles.
        </Card.Body>
      </Card>

      <Card className="mb-5 fs-5">
        <Card.Header>Compact</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} lg={6} className="mb-3">
              Copy the below HTML and paste it into any HTML page to display
              your petition in a modern, sleek, compact slider.
              <div className="mt-3 p-3 bg-dark text-light">
                {codeCompact}
                <CopyText tip="Copy Code" text={codeCompact} />
              </div>
            </Col>
            <Col xs={12} lg={6} className="mb-3">
              <FormEmbdedCompact id={props.formId} />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="fs-5">
        <Card.Header>Full Page</Card.Header>
        <Card.Body>
          <div className="mb-3">
            Copy the below HTML and paste it into any HTML page to display your
            petition in a clear, full-page manner. The &lt;IFrame&gt; should
            adjust to the size of the petition. If by chance your petition
            doesn't display in full, feel free to adjust the height attribute of
            the &lt;IFrame&gt; tag accordingly.
            <div className="mt-3 p-3 bg-dark text-light">
              {codeFull} <CopyText tip="Copy Code" text={codeFull} />
            </div>
          </div>
          <FormEmbedFull id={props.formId} />
        </Card.Body>
      </Card>
    </div>
  );
}

export default FormEmbed;