import React, { useState } from "react";
import { Button, FloatingLabel, Form, Modal, ModalBody } from "react-bootstrap";
import { Diagram2, QuestionCircle } from "react-bootstrap-icons";

const IntegrationWizzard = (props) => {
  const [show, setShow] = useState(true);
  const [values, setValues] = useState(null);

  const template = {
    constantcontact: {
      label: "Constant Contact",
      fields: [
        {
          label: "API Key",
          property: "apiKey",
          key: "<api key>",
          description: "Enter you API key",
          helpURL:
            "https://community.constantcontact.com/t5/Community-Blog/How-to-generate-an-API-Key-and-Access-Token/ba-p/293856",
        },
        {
          label: "Access Token",
          property: "accessToken",
          key: "<access token>",
          description: "Enter your access token",
          helpURL:
            "https://community.constantcontact.com/t5/Community-Blog/How-to-generate-an-API-Key-and-Access-Token/ba-p/293856",
        },
        {
          label: "List ID",
          property: "listID",
          key: "<list id>",
          description: "Enter your list's ID",
          helpURL:
            "https://community.constantcontact.com/t5/Getting-Started-with-API-s/How-to-Find-a-list-s-listID/m-p/228300",
        },
      ],
    },
    mailchimp: {
      label: "Mail Chimp",
      fields: [
        {
          label: "Any Text",
          property: "anyText",
          key: "<any text>",
          description: "Enter any text",
          helpURL:
            "https://mailchimp.com/developer/marketing/docs/fundamentals/#connecting-to-the-api",
        },
        {
          label: "API Key",
          property: "apiKey",
          key: "<api key>",
          description: "Enter API key",
          helpURL: "https://mailchimp.com/help/about-api-keys/",
        },
        {
          label: "Data Center",
          property: "dataCenter",
          key: "<data center>",
          description: "Enter your data center",
          helpURL:
            "https://mailchimp.com/developer/marketing/docs/fundamentals/#api-structure",
        },
        {
          label: "List ID",
          property: "listID",
          key: "<list id>",
          description: "Enter your list's ID",
          helpURL: "https://mailchimp.com/help/find-audience-id/",
        },
      ],
    },
    paypal: {
      label: "PayPal",
      fields: [
        {
          label: "Account Email",
          property: "accountEmail",
          key: "<account email>",
          description: "Enter any text",
          helpURL:
            "https://www.paypal.com/us/smarthelp/article/to-which-email-address-should-my-customers-send-payments-faq1479",
        },
      ],
    },
    stripe: {
      label: "Stripe",
      fields: [
        {
          label: "Public API Key",
          property: "publicAPIKey",
          key: "<public api key>",
          description: "Enter your pulic API key",
          helpURL: "https://stripe.com/docs/keys",
        },
        {
          label: "Secret API Key",
          property: "secretAPIKey",
          key: "<secret api key>",
          description: "Enter your secret API key",
          helpURL: "https://stripe.com/docs/keys",
        },
      ],
    },
  };

  return (
    <Modal centered size="md" show={show}>
      <Modal.Header className="bg-dark text-light h5">
        <Diagram2 />
        &nbsp;
        {template[props.template].label}
        <Button
          className="btn-close btn-close-white"
          onClick={() => setShow(false)}
        />
      </Modal.Header>
      <ModalBody className="text-left">
        {template[props.template].fields.map((field, index) => (
          <FloatingLabel
            className="mb-3"
            label={field.label}
            key={`field${index}`}
            controlId={`field${index}`}
          >
            <Form.Control
              onChange={(e) =>
                setValues({
                  ...values,
                  [field.property]: e.target.value,
                })
              }
            />
            <Form.Text muted>
              {field.description}{" "}
              <a href={field.helpURL} rel="noreferrer" target="_blank">
                <QuestionCircle />
              </a>{" "}
            </Form.Text>
          </FloatingLabel>
        ))}
        <Button
          className="w-100"
          onClick={() => {
            props.setFormData({
              ...props.formData,
              url: template[props.template].fields.reduce(
                (result, current) =>
                  result.replaceAll(current.key, values[current.property]),
                props.formData.url
              ),
              headers: template[props.template].fields.reduce(
                (result, current) =>
                  result.replaceAll(current.key, values[current.property]),
                props.formData.headers
              ),
              body: template[props.template].fields.reduce(
                (result, current) =>
                  result.replaceAll(current.key, values[current.property]),
                props.formData.body
              ),
            });
            setShow(false);
          }}
        >
          Submit
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default IntegrationWizzard;