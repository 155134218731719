import { Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import ReactHtmlParser from "react-html-parser";
import LayoutItem from "./LayoutItem";

const LayoutArray = (props) => {
  return (
    <Container fluid className="p-0">
      <div className="display-3 border-bottom mb-3">
        {ReactHtmlParser(props.header.replace("%20", " "))}
      </div>
      <Row>
        <Col>
          <Row>
            {props.links.map((link, index) => (
              <Col key={`article-${link.id}`} sm={4} className="mb-3">
                <LayoutItem article={link} className="cardSmall" {...props} />
              </Col>
            ))}
          </Row>
        </Col>
        {props.showMore && (
          <div>
            <a
              href={props.showMore}
              className="text-decoration-none h3 float-end"
            >
              Show More <ArrowRightCircleFill />
            </a>
          </div>
        )}
        {props.returnToNews && (
          <div>
            <a href="/news" className="text-decoration-none h3 float-end">
              More News <ArrowRightCircleFill />
            </a>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default LayoutArray;