import React, { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { Navigate } from "react-router-dom";
import SVGMap from "../SVGMap";

function AdminGeography(props) {
  const [cookies] = useCookies(["user_id"]);
  const [data, setData] = useState(null);

  const getData = useCallback(async () => {
    await axios
      .get(
        `https://api.ourfight.online/adminAnalytics/userId/${
          cookies.user_id
        }/geography/1`
      )
      .then((response) => {
        setData({
          ...response.data,
        });
      });
  }, [cookies.user_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (cookies.user_id !== "72ee332c-4f84-4516-90f1-036a6805fa43")
    return <Navigate to="/" />;
  else if (!data) return null;
  else
    return (
      <div className="mt-3">
        <SVGMap {...data?.county} zip={data?.zip} />
      </div>
    );
}

export default AdminGeography;