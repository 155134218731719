import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Loading from "../Loading";
import LayoutItem from "./Layout/LayoutItem";
import ReactHtmlParser from "react-html-parser";

const NewsLatestComments = () => {
  const [urlWithComments, setURLWithComments] = useState(null);
  const [comments, setComments] = useState(null);

  const getComments = useCallback(() => {
    const urlIds = urlWithComments.map((url) => url?.id).join(",");

    try {
      axios
        .get(`https://api.ourfight.online/urlListComments?urlIds=${urlIds}`)
        .then((response) => {
          setComments(response.data.comments);
        });
    } catch (e) {
      console.error("[JSON Error]", e);
    }
  }, [urlWithComments]);

  const getURLWithComments = useCallback(() => {
    try {
      axios
        .get(`https://api.mobubby.com/data?file=Join%20The%20Conversation`)
        .then((response) => {
          setURLWithComments(
            JSON.parse(response.data.content)[0].map((url) => {
              return {
                ...url,
                url: `https://ourfight.online/news/link/${url.id}`,
              };
            })
          );
        });
    } catch (e) {
      console.error("[JSON Error]", e);
    }
  }, []);

  useEffect(() => {
    getURLWithComments();
  }, [getURLWithComments]);

  useEffect(() => {
    if (!!urlWithComments) getComments();
  }, [getComments, urlWithComments]);

  if (!comments) return <Loading />;

  return (
    <Row className="news">
      {comments.map((comment) => {
        const url = urlWithComments
          .map((url) =>
            parseInt(url.id) === parseInt(comment.fk_urlId) ? url : null
          )
          .filter((a) => a)[0];

        return (
          <Col className="mt-5" md={6} key={comment.id}>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <LayoutItem className="cardSmall" article={url} />
                  </Col>
                  <Col>
                    <div>
                      {ReactHtmlParser(
                        comment.comment.replace(/\n/gi, "<br />")
                      )}
                    </div>
                    <small className="mt-3 text-secondary float-end">
                      {comment.commentAs ?? "Anonymous"} on&nbsp;
                      {comment.createdDateTime}
                    </small>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default NewsLatestComments;